import { useState } from "react";

const useIdeaData = () => {
	const [nideasSt, setnideasSt] = useState(0);
    const [deleteIdeaBl, setdeleteIdeaBl] = useState(false);
	const [delIdeaid, setdelIdeaid] = useState(-1);
	const [deleteIdeaBlName, setdeleteIdeaBlName] = useState("");

    return {
        nideasSt, setnideasSt,
        deleteIdeaBl, setdeleteIdeaBl,
        delIdeaid, setdelIdeaid,
        deleteIdeaBlName, setdeleteIdeaBlName
    }
}

export default useIdeaData;