import React from "react";
import withStyles from "@mui/styles/withStyles";

import Modal from "react-bootstrap/Modal";

//Modal specific assets
import { ourDarkGreen, } from 'assets/jss/incept-sustainability-variables.jsx';

//Styles
import clsx from 'clsx';

import { IconButton } from "@mui/material";
import icon7 from "assets/img/icon7.png";

const styles = {
	'root' : {
		'& .modal-content' : {
			padding:'40px',
			borderRadius:'20px',
		},
		'& h1' : {
			fontSize:'23px',
			color:'#000'
		},
		'& h2' : {
			fontSize:'20px',
			color:ourDarkGreen
		}
	},
	modalCancelButton : {
		padding:'0',
		float:'right'
	}
};

class ModalRecap extends React.Component {

	handleCloseClick(){
		this.props.cleartimeout();

		if (this.props.onHide){
			this.props.onHide();
		}		
	}

	render(){
		const {children, classes, className, cleartimeout,backdrop, ...other} = this.props;

		return (
            <Modal
				centered
				size="lg"
				onHide={()=>this.handleCloseClick()} 
				backdrop={backdrop ? "static":true}
				className={clsx(classes.root, className)}
				{...other}>
				<div>
					<IconButton
                        onClick={()=>this.handleCloseClick()}
                        className={classes.modalCancelButton}
                        size="large">
						<img
							src={icon7}
							alt="cancel icon"
							style={{ width: "40px", height: "40px" }}
						/>
					</IconButton>
				</div>
				{children}
			</Modal>
        );
	}
}

export default withStyles(styles)(ModalRecap);