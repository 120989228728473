import React from 'react';

const SwedbankTermsAndConditionsEN = () => {
    const styles = {
        container: {
          maxHeight: "100%",
            maxWidth: '800px',
            margin: '0 auto',
            padding: '32px',
            fontFamily: 'sans-serif',
            lineHeight: '1.75'
        },
        heading2: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            marginTop: '32px'
        },
        heading3: {
            fontSize: '1.125rem',
            fontWeight: 'bold',
            marginTop: '16px'
        },
        paragraph: {
            marginTop: '16px'
        },

        innerParagraph:{
            marginTop: '16px',
            marginLeft: '24px'
        },

        link: {
            color: '#3b82f6',
            textDecoration: 'underline'
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading2}>
                Information about the processing of your personal data
            </h2>
            <h3 style={styles.heading3}>
                We are the data controller - how do you contact us?      
            </h3>
            <p style={styles.paragraph}>
                Ramboll Management Consulting owns and operates the Incept Sustainability Platform (herein-after referred to as the "Platform").  
            </p>
            <p style={styles.paragraph}>
                The Platform is made available to Swedbank, which can make the Platform available to organi-sations with an active connection to the real estate industry in Denmark,
                so that these organi-sations can also use the Platform. 
            </p>

            <p style={styles.paragraph}>
                In order to make the Platform available to Swedbank, your personal data is processed in the Platform in connection with user registration.
                This text describes how your personal data is processed in connection with your use of the Platform.
            </p>

            <p style={styles.paragraph}>
                Ramboll Management Consulting is the data controller for this processing, and you are wel-come to contact us if you have any questions about the processing of your personal data.
                You can find contact information below.
            </p>

            <p style={styles.paragraph}>
                Rambøll Management Consulting
                <br />
                Hannemanns Allé 53
                <br />
                2300 København 
                <br />
                CVR-nr: 60997918
                <br />
                Telefon: +45 5161 2506
                <br />
                Mail:{' '}
                <a href='mailto:PRIVACY@ramboll.com' style={styles.link}>
                    PRIVACY@ramboll.com
                </a>
            </p>

            <h3 style={styles.heading3}>
                2. The purposes and legal basis for the processing of your personal data
            </h3>
            <p style={styles.paragraph}>
                Ramboll Management Consulting is the data controller for the personal data (name, title, or-ganisation, email address)
                you provide and register when you create a user profile in the Platform.
            </p>

            <p style={styles.paragraph}>
                Ramboll Management Consulting processes your personal data for the purpose of administer-ing access to the Platform and providing support on the Platform.  
            </p>
            <p style={styles.paragraph}>
                The legal basis for processing is Ramboll Management Consulting's legitimate interest 
                in provid-ing a service to Swedbank and your organisation that is provided with the platform (Article 6(1)(f) of the General Data Protection Regulation).
            </p>

            <h3 style={styles.heading3}>
                3. Recipients or categories of recipients
            </h3>
            <p style={styles.paragraph}>
                Our suppliers may have technical access to your personal data in connection with the provision of their services as a data processor to us. 
                This includes, for example, hosting and support of the Platform. Your personal data is not shared with others.
            </p>


            <h3 style={styles.heading3}>
                 4. Transfer to beneficiaries in third countries, including international or-ganisations
            </h3>
            <p style={styles.paragraph}>
                As a general rule, your personal data is stored and processed within the EU/EEA.  
            </p>


            <h3 style={styles.heading3}>5. Storage of your personal data</h3>
            <p style={styles.paragraph}>
                Ramboll Management Consulting will store your personal data until your user profile is deleted in the Platform or until the agreement with Swedbank expires. 
            </p>
            <p style={styles.paragraph}>
                 If your user profile is deleted, your responses will be retained in anonymised form in the Plat-form.
            </p>

            <h3 style={styles.heading3}>
                6. Automated decision-making, including profiling
            </h3>
            <p style={styles.paragraph}>
                 Automated decision-making, including profiling, is not used to process your personal data. 
            </p>

            <h3 style={styles.heading3}>7. Your rights</h3>
            <p style={styles.paragraph}>
                According to the General Data Protection Regulation, you have a number of rights in relation to the processing of your personal data. 
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Right to see information (right of access)</strong>
                <br />
                You have the right to access the information we process about you, as well as a num-ber of additional information.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Right to rectification (rectification)</strong>
                <br />
                You have the right to have incorrect information about yourself corrected.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Ret to erasure</strong>
                <br />
                In special cases, you have the right to have information about you deleted before the time of our general deletion occurs.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Right to restriction of processing</strong>
                <br />
                In certain cases, you have the right to have the processing of your personal data re-stricted. 
                If you have the right to have the processing restricted, we may in future only process the data - except for storage - with your consent, 
                or for the purpose of es-tablishing, exercising or defending legal claims, or for the protection of a person or important public interests.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Right to object</strong>
                <br />
                In certain cases, you have the right to object to our or lawful processing of your per-sonal data. 
                You can also object to the processing of your data for direct marketing purposes.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Right to transmit information (data portability)</strong>
                <br />
                In certain cases, you have the right to receive your personal data in a structured, 
                commonly used and machine-readable format and to have this personal data trans-ferred from one data controller to another without hindrance.
            </p>
            <p style={styles.paragraph}>
                You can read more about your rights in the Danish Data Protection Agency's guidance on the rights of data subjects, which you can find on {' '}
                <a href='https://www.datatilsynet.dk' style={styles.link}>
                    www.datatilsynet.dk
                </a>
                .
            </p>
            <p style={styles.paragraph}>
                If you want to exercise your rights, please contact us (see contact details under section 1).
            </p>

            <h3 style={styles.heading3}>
                8. Complaints to the Data Protection Authority  
            </h3>
            <p style={styles.paragraph}>
                You have the right to file a complaint with the Danish Data Protection Agency if you are dissat-isfied with the way your personal data is processed. 
                You can find the Danish Data Protection Agency's contact information on  {' '}
                <a href='https://www.datatilsynet.dk' style={styles.link}>
                    www.datatilsynet.dk
                </a>
                .
            </p>
        </div>
    );
};

export default SwedbankTermsAndConditionsEN;
