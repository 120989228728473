import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";

const style = {
  grid: {
    // margin: "0 -15px !important",
    width: "unset"
  },
  analyticsspace: {
    paddingBottom: "30px"
  }
};

function GridContainer(props) {
  const { classes, children, space,nonunset,customClasses, ...rest } = props;
  const ungrid = (nonunset) ? "":classes.grid;
  const analyticsspace = (space) ? classes.analyticsspace:"";
  return (
    <Grid container {...rest} className={ungrid+" "+analyticsspace+" "+customClasses}>
      {children}
    </Grid>
  );
}

GridContainer.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node
};

export default withStyles(style)(GridContainer);
