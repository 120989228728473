/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

import analyticsStyle from "assets/jss/views/analyticsStyle.jsx"; //style for all analytics
import { ourBlueLight, ourBlue, ourOrange } from "assets/jss/incept-sustainability-variables.jsx";

import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import FakeButton from "components/CustomButtons/FakeButton";
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@mui/material/MenuItem';

import ActiveEmployees_tooltip from "components/Tooltip/ActiveEmployees_tooltip";
import { withTranslation, Trans,useTranslation } from 'react-i18next';

import { useMediaQuery } from 'react-responsive'; //for mobile responsiveness

const useStyles = (theme) => ({
    fakeButtonTd: {
        width: "80px"
    }
});

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

function AnalyticsCompany_Header(props) {
	const {classes, content} = props;
    const { t, i18n } = useTranslation();

	return (
            <CardAnalytics>
                <CardAnalyticsHeader title={t("General Overview")}>
                </CardAnalyticsHeader>
                <CardAnalyticsBody>
                <GridContainer space={false}>
                <GridItem xs={12} sm={12} md={12} space={0} spaceNoPadding={true} style={props.isMobile ? {marginTop:"0"}:{}}>
                    <GridContainer alignItems="center">
                        <GridItem xs={12} sm={12} md={12} lg={4} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                            <TableContainer className={classes.standardTableNoPaddingL}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                        key={"AverageSubmission"}
                                        >
                                            <TableCell component="th" scope="row" style={{maxWidth:"100px"}}>
                                                {t("Average feedback activity submission ")+"% ("} <ActiveEmployees_tooltip tooltipid={"someid"} />{t("in the module")+"):"}
                                            </TableCell>
                                            <TableCell className={classes.fakeButtonTd} align={props.isMobile ? "right":"left"} style={{paddingLeft:"0"}}>
                                                <FakeButton 
                                                color={ourBlueLight}
                                                textColor={ourBlue}
                                                style={{width:"80px"}}
                                                content={props.overview.avg_submission+"%"} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                            <TableContainer className={classes.standardTableNoPaddingL}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                        key={"AverageSubmission"}
                                        >
                                            <TableCell component="th" scope="row" style={{maxWidth:"100px"}}>
                                                {t("Average empty submission")+" % "}
                                                <i>{t("multiple choice activities")+": "}</i>
                                            </TableCell>
                                            <TableCell className={classes.fakeButtonTd} align={props.isMobile ? "right":"left"} style={{paddingLeft:"0"}}>
                                                <FakeButton 
                                                color={ourBlueLight}
                                                textColor={ourBlue}
                                                style={{width:"80px"}}
                                                content={props.overview.pempty_mult+"%"} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                            <TableContainer className={classes.standardTableNoPaddingL}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                        key={"AverageSubmission"}
                                        >
                                            <TableCell component="th" scope="row" style={{maxWidth:"100px"}}>
                                                {t("Average empty submission")+" % "}
                                                <i>{t("drag and drop activities")+": "}</i>
                                            </TableCell>
                                            <TableCell className={classes.fakeButtonTd} align={props.isMobile ? "right":"left"} style={{paddingLeft:"0"}}>
                                                <FakeButton 
                                                color={ourBlueLight}
                                                textColor={ourBlue}
                                                style={{width:"80px"}}
                                                content={props.overview.pempty_dd+"%"} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                        {/*<GridItem xs={12} sm={12} md={12} lg={3} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                            <TableContainer className={classes.standardTableNoPaddingL}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                        key={"AverageSubmission"}
                                        >
                                            <TableCell component="th" scope="row" style={{maxWidth:"100px"}}>
                                                <i>{"Employees who has submitted empty on all feedback activities "}</i>
                                            </TableCell>
                                            <TableCell className={classes.fakeButtonTd} align={props.isMobile ? "right":"left"} style={{paddingLeft:"0"}}>
                                                <FakeButton 
                                                color={ourBlueLight}
                                                textColor={ourBlue}
                                                style={{width:"80px"}}
                                                content={props.overview.pempty_all} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
    </GridItem>*/}
                        <GridItem xs={12} sm={12} md={12} lg={4} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                            <TableContainer className={classes.standardTableNoPaddingL}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                        key={"emptysubmission"}
                                        >
                                            <TableCell component="th" scope="row" style={{maxWidth:"100px"}}>
                                                {t("Average empty submission")+" % "}
                                                <i>{t("all feedback activity types")+": "}</i>
                                            </TableCell>
                                            <TableCell className={classes.fakeButtonTd} align={props.isMobile ? "right":"left"} style={{paddingLeft:"0"}}>
                                                <FakeButton 
                                                color={ourBlueLight}
                                                textColor={ourBlue}
                                                style={{width:"80px"}}
                                                content={props.overview.pempty+"%"} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                            <TableContainer className={classes.standardTableNoPaddingL}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                        key={"emptysubmission"}
                                        >
                                            <TableCell component="th" scope="row" style={{maxWidth:"100px"}}>
                                                {t("Average empty submission")+" % "}
                                                <i>{t("free text activities")+": "}</i>
                                            </TableCell>
                                            <TableCell className={classes.fakeButtonTd} align={props.isMobile ? "right":"left"} style={{paddingLeft:"0"}}>
                                                <FakeButton 
                                                color={ourBlueLight}
                                                textColor={ourBlue}
                                                style={{width:"80px"}}
                                                content={props.overview.pempty_free+"%"} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={4} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                            <TableContainer className={classes.standardTableNoPaddingL}>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow
                                        key={"emptysubmission"}
                                        >
                                            <TableCell component="th" scope="row" style={{maxWidth:"100px"}}>
                                                {t("Average empty submission")+" % "}
                                                <i>{t("ranking activities")+": "}</i>
                                            </TableCell>
                                            <TableCell className={classes.fakeButtonTd} align={props.isMobile ? "right":"left"} style={{paddingLeft:"0"}}>
                                                <FakeButton 
                                                color={ourBlueLight}
                                                textColor={ourBlue}
                                                style={{width:"80px"}}
                                                content={props.overview.pempty_rank+"%"} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </GridItem>
                        {/*<GridItem xs={12} sm={12} md={12} lg={4} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"16px"}:{}}>
                            <div style={{minWidth:"200px"}} align={props.isMobile ? "left":"right"}>
                                <DropDown
                                    value={0}
                                    analytics={true}
                                    paddingShort={true}
                                    fullWidth={false}
                                >
                                    <MenuItem key={0} value={0}>{"All"}</MenuItem>
                                </DropDown>
                            </div>
                        </GridItem>*/}
                    </GridContainer>
                </GridItem>
                </GridContainer>
                </CardAnalyticsBody>
            </CardAnalytics>
	);
}
export default
withMyHook(withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(AnalyticsCompany_Header));
