import React from 'react';
import PropTypes from 'prop-types';

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

const PageWrapper = ({activeOverlay = false, children}) => {
    return (
        <LoadingOverlay
            active={activeOverlay}
            spinner={<BounceLoader />}>
        
        <div style= {{minHeight: '100dvh'}}>
            {children}
        </div>
        </LoadingOverlay>
    )
}

PageWrapper.propTypes = {
    activeOverlay: PropTypes.bool,
    children: PropTypes.node.isRequired
}

export default PageWrapper;