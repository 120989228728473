/*!
* Description: this file contains the component for
*  the type of exercise DropDownWithImage, ex. guide 4, step 3, 
*  					" Exercise: Match the Use Case to the Right Bag"
* Components: DropDownWithImage
*/
import React from "react";
import ReactHtmlParser from 'react-html-parser';

// Styles
import withStyles from "@mui/styles/withStyles";
import { ourGreen } from 'assets/jss/incept-sustainability-variables.jsx';

// Style components
import Grid from '@mui/material/Grid';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

//Forms
import FormControl from '@mui/material/FormControl';
import DropDownImage from "components/CustomInput/DropDownImage.js";

import Container from '@mui/material/Container';
// Effects
import Collapse from '@mui/material/Collapse';

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import checked from 'assets/img/checkedWhiteBg.png';
//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {paddingTop: "20px"},
	card: {
		margin: '0',
		height: '100%',
		backgroundColor: 'transparent',
		color: '#FFFFFF',
		fontWeight: '500',
		boxShadow: "none",
	},
	buttonContainer: {
		textAlign: 'right',
		marginTop: "20px",
		paddingRight: "0"
	},
	buttonContainerAnswers: {
		textAlign: "right",
		paddingRight: "0",
		marginBottom: "60px"
	},
	buttonAnswer: {
		color: "#fff",
		backgroundColor: ourGreen,
		'&:hover, &:active, &:focus': {
			filter: 'brightness(85%)',
			backgroundColor: ourGreen,
		}
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "0",
		marginTop: "20px"
	},
};

/*
* Description: component for exercise with dropdown
*/
class DropDownWithImage extends React.Component {	
	constructor(props) {
		super(props);
		this.state = {
			selectedAnswers: Array(this.props.exercise.statements.length).fill(-1),
			submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
		}
		if (typeof this.props.datasav2 !== "undefined") {
			this.state.selectedAnswers = this.props.datasav2["content"];
		}
	}

	handleChange(e, statementIndex) {
		const imageIndex = parseInt(e.target.value);
		this.setState(state => {
			const sa = state.selectedAnswers;
			sa[statementIndex] = imageIndex;
			//save exercise
			//this.props.save2("DropDownWithImage", sa, this.props.inkey, this.props.exercise_id);
			return { selectedAnswers: sa, submittedanswer: false };
		});
	}

	handleShowAnswer(e) {
		e.preventDefault();
		if (this.state.showAnswer)
			this.setState({ showAnswer: false });
		else
			this.setState({ showAnswer: true });
	}

	handleSubmit(e) {
		e.preventDefault();
		//Commented to allow empty answers
		/*let noneSelected = this.state.selectedAnswers.every(function (e) {
			return e===-1;
		});
		if (!noneSelected) {*/
			this.setState({ submittedanswer: true });
			this.props.save2("DropDownWithImage", this.state.selectedAnswers, this.props.inkey, this.props.exercise_id);
		//}
	}

	render() {
		const { classes, exercise, t } = this.props;
		const { statements, images, answerimg } = exercise;

		const gridItems = statements.map(
			(statement, index) => (
				<Grid item xs={12} md={6} key={index}>
					<div className={classes.gridContentWrapper}>
						<Grid container spacing={2} alignItems="stretch">
							<Grid item md>
								<Card className={classes.card}>
									<CardBody isexercise={true}>
										{statement}
									</CardBody>
								</Card>
							</Grid>
							<Grid item>
								<FormControl>
									<DropDownImage currentValue={this.state.selectedAnswers[index]} images={images} onChange={(e) => this.handleChange(e, index)} />
								</FormControl>
							</Grid>
						</Grid>
					</div>
				</Grid>
			)
		);

		if (answerimg !== "") {
			return (
				<div className={classes.root}>
					<Grid container spacing={2}>
						{gridItems}
					</Grid>
					<div className={classes.statusContainer}>
						{this.state.submittedanswer && <p>{t("Great! Your answer has been saved.")}</p>}
					</div>
					<Container className={classes.buttonContainer}>
						<ActionButton
							size="auto"
							type="submit"
							nomargin={true}
							centered={true}
							onClick={(e) => this.handleSubmit(e)}
						>
							{t('Submit')}
						</ActionButton>
					</Container>
					<Container className={classes.buttonContainerAnswers}>
						<ActionButton 
							onClick={(e) => this.handleShowAnswer(e)} 
							type="submit" 
							size="auto"
							>
						{t('See suggested answer')}
						</ActionButton>
					</Container>
					<Container className={classes.answercontainer}>
						<Collapse in={this.state.showAnswer}>{ReactHtmlParser(ReactHtmlParser(answerimg))}</Collapse>
					</Container>
				</div>
			);
		}
		else {
			return (
				<div className={classes.root}>
					<Grid container spacing={2}>
						{gridItems}
					</Grid>
				</div>
			);
		}
	}
}

export default withTranslation()(withStyles(styles)(DropDownWithImage));