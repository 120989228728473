import React, { createContext, ReactNode, useContext } from 'react';
import useIdeasFilter  from "../hooks/useIdeasFilter";

const IdeasFilterContext = createContext<ReturnType<typeof useIdeasFilter> | null>(null);

const IdeasFilterProvider = ({ children }: { children: ReactNode }) => {
    const ideasFilterData = useIdeasFilter();

    return (
        <IdeasFilterContext.Provider value={ideasFilterData}>
            {children}
        </IdeasFilterContext.Provider>
    );
}

const useIdeasFilterContext = () => {
    const context = useContext(IdeasFilterContext);
    if (context === null) {
        throw new Error('useIdeasFilterContext must be used within an IdeasFilterProvider');
    }
    return context;
}

export {useIdeasFilterContext, IdeasFilterProvider, IdeasFilterContext};