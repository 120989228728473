import React from "react";
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
//Styles
import withStyles from "@mui/styles/withStyles";
import about from "assets/img/about.svg";

import { withTranslation } from 'react-i18next';

const TextOnlyTooltip = withStyles({
	tooltip: {
		fontSize: "14px"
	}
})(Tooltip);

class ModuleLenght_tooltip extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            tooltipOpenID : ''
        }
	}

	handleTooltipClick(_e,tooltipID){
		this.setState({tooltipOpenID:tooltipID});
	}

	handleTooltipClose(_e,tooltipID){
		if (this.state.tooltipOpenID===tooltipID)
			this.setState({tooltipOpenID:""});
	};

	render(){
	const { tooltipid, lengthM, t } = this.props;
	const tooltipToShow = t('This is an average time estimation') + ': ' + t('depending on your pre-existing experience and training preference, the module can be skimmed faster, or a thorough read through might take longer.');
	return (
		<ClickAwayListener onClickAway={(e) => this.handleTooltipClose(e,tooltipid)}>
			<TextOnlyTooltip 
			key={this.state.tooltipOpenID}
            placement="top" 
			disableFocusListener
			disableHoverListener
			disableTouchListener
			title={tooltipToShow}
			open={tooltipid === this.state.tooltipOpenID}
			onClick={(e) => this.handleTooltipClick(e, tooltipid)}
			>
				<span style={{cursor:"pointer",fontWeight:"500"}}>
				{lengthM}
				<img
				src={about}
				style={{width:"15px",margin:"0 7px 0 3px"}}
				alt="What is this?"
				/>
				</span>
			</TextOnlyTooltip>
		</ClickAwayListener>
    );
    }
};

export default withTranslation()(ModuleLenght_tooltip);