/*!
* Description: this file contains the component for
*  the section "example"
* Components: GuideExample
*/
import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@mui/material/Container';
import withStyles from "@mui/styles/withStyles";
import arrowdown from 'assets/img/down-arrow.svg';
import checkmark from 'assets/img/checkmark-green.svg';
import greyCheckmark from 'assets/img/checkmark-grey.svg';
import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import Collapse from "@mui/material/Collapse";
//Translations
import { withTranslation } from 'react-i18next';
import exampleicon from 'assets/img/exampleicon.png';
import { useMediaQuery } from 'react-responsive';

const styles = {
	root: {},
	cardExample: {
		marginBottom: "10px",
		minHeight: "110px",
		marginTop: "0"
	},
	cardBodyText: {
		marginTop: "20px",
		fontSize: ".95rem",
	},
	iconNavBack: {
		fontSize: '30px',

	},
	container: {
		"@media only screen and (max-width: 960px)": {
			padding: "0",
		}
	},
	buttonContainer: {
		textAlign: "end",
		margin: "3px 20px 20px 0",
		paddingRight: "0",
		"@media only screen and (max-width: 960px)": {
			margin: "3px 20px 20px 0",
		},
		"@media only screen and (max-width: 600px)": {
			textAlign: "center",
		},
	},
	iconDownArrowDiv: {
		paddingTop: "20px",
		textAlign: "center",
	},
	iconDownArrow: {
		cursor: "pointer",
	},
	titleMargin: {
		marginLeft: "20px"
	},
	checkmark: {
		"@media only screen and (max-width: 960px)": {
			float: "right",
		},
	  },
};

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}
class GuideExample extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			isdefaultView: (this.props.videoContent!=="") ? true:false,
			defaultView: (this.props.defaultView==="Video content") ? false:true,
			loading: true
		}
	}

	componentWillReceiveProps(nextProps) {
        //console.log('componentWillReceiveProps', nextProps);
		if (this.state.loading) {
			console.log(nextProps.videoContent);
			console.log(nextProps.videoContent.length);
			this.setState({isdefaultView:(nextProps.videoContent.length!==0) ? true:false,
				defaultView: (nextProps.defaultView==="Video content") ? false:true, loading: false});
		}
    }

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ defaultView: !this.state.defaultView });
	}

	render() {
		const { classes, title,textContent,videoContent, t,saveresloc,
			isLastSection,showcard,openSection,datasav,smoothtrans } = this.props;
			let stylePaddingBody = (showcard) ? {padding: "0 30px 30px 30px"}:{padding: "0 30px"};
		return (
			<div className={classes.root}>
				<Container className={classes.container}>
					<Card className={classes.cardExample}>
					<CardBody 
						style={ (this.props.isMobile) ? {padding: "0 0 0 30px"}:stylePaddingBody}		>
							
							<GridContainer justifyContent="center" 
								style={
								{
									alignItems: "center",
									justifyContent: "space-between",
									minHeight: "110px",
									flexWrap: (this.props.isMobile) ? "wrap" : "nowrap",
									flexDirection: (this.props.isMobile) ? "column" : "row",
									gap: (this.props.isMobile) ? "1rem" : undefined,
									margin: (this.props.isMobile) ? "20px 0" : "auto",
								}}
								>
								<GridItem 
									style={{ width: "100%" }} spaceLeft={0}
								>
									
										<GridContainer 
											alignItems="center"
											justifyContent="space-between"
											style={this.props.isMobile ? { width: "100%", flexDirection: "row"}:{ width: "100%", flexDirection: "row", flexWrap: "nowrap" }}
										>
											<GuideCardTitle style={{marginBottom:"0"}}>
											<GridContainer
                                                style={{
                                                    alignItems:"center",
                                                    flexDirection: "row",
                                                    flexWrap: "nowrap",
                                                    gap: "1rem",
                                                    flexGrow: "1",
                                                    width: "100%"
                                                }}
                                            >
												{/* Static width is required here. */}
												<GridItem  space={0} spaceNoPadding={true}>
													<img
														src={(typeof datasav !== "undefined") ? checkmark : greyCheckmark}
														className={classes.dotimage2}
														width="80px"
														style={{paddingRight:"20px"}}
														alt='Information'
													/>
												</GridItem>
												<GridItem 
													md="auto"
													lg="auto"
													spaceLeft={0}
													style={{ flexGrow: "1", width: "100%", maxWidth: "100%" }}
												>
													<span>{t('Example')}: {title}</span>
													</GridItem>
                                            </GridContainer>
                                        </GuideCardTitle>

								<GridItem xs={12} md={3} spaceLeft={0} 
									style={this.props.isMobile ? {cursor:"pointer",
									marginTop:`${showcard ? "20px":"10px"}`}:
									{cursor:"pointer"}}>
									<div className={`${classes.iconDownArrowDiv}`}
										style={(title=="") ? 
											{marginBottom: "12.8px",paddingTop:"7px",textAlign:"right",display:"flex"} :
											{paddingTop:"0",textAlign:`${this.props.isMobile ? "center":"right"}`}}
									>
									  	{showcard && this.state.isdefaultView && 
										<ActionButton 
											onClick={(e) => this.handleSubmit(e)} 
											type="submit" 
											size="auto"
											centered={true}
											style={(this.state.isdefaultView && showcard) ? 
												{width:"auto",
												marginRight: `${this.props.isMobile ? "0":"20px"}`,
												marginLeft: `${this.props.isMobile ? "30px":"0"}`,
												marginBottom:`${this.props.isMobile ? "20px":"0"}`}:
												{visibility:"hidden"}}
										>
											{(this.state.defaultView) && t('See video instead')}
											{(!this.state.defaultView) && t('See text instead')}
										</ActionButton>	}									
										
									</div>
									</GridItem>
                                    </GridContainer>
								</GridItem>
								<img
									src={arrowdown}
									style={{
										transform: (showcard) ? "rotate(-180deg)": undefined,
										display: "block",
										margin: "0 auto",
										flexGrow: 0,
										paddingRight:`${this.props.isMobile ? "30px":"0"}`
									}}
									alt={t("Go to next section")}
									title={t("Go to next section")}
									onClick={openSection}
									className={classes.iconDownArrow}
								/>
							</GridContainer>


							<Collapse in={showcard}
							style={{margin:`${this.props.isMobile ? "10px 0 20px -20px":"auto"}`}}
							addEndListener={() => {
								if (showcard) {
									saveresloc();
									setTimeout(() => {smoothtrans.scrollIntoView({behavior: "smooth"})}, 100);
								}
							}} >
							{(this.state.defaultView) && <div className={classes.cardBodyText}>
								{textContent}</div>}
							{(!this.state.defaultView) && <div className={classes.cardBodyText}>
								{videoContent}</div>}	
						{/*!isLastSection && <div onClick={goNextSection} className={`${classes.iconDownArrowDiv} moveArrow`}>
							<img
								src={arrowdown}
								alt="Go to next section"
								title="Go to next section"
								
								className={classes.iconDownArrow}
							/>
						</div>*/}
						</Collapse>
						</CardBody>						
					</Card>
				</Container>
			</div>
		);
	}
}

GuideExample.defaultProps = {
	children: (<p>Here comes the content for this Example</p>),
	title: 'Example',
};


export default withTranslation()(withMyHook(withStyles(styles)(GuideExample)));