import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";

// Style components
import Grid from "@mui/material/Grid";

//form inputs
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@mui/material/MenuItem';

import PropTypes from "prop-types";

//Translations
import { withTranslation } from 'react-i18next';

const styles = {

};

const List = withStyles(styles)(({elements, answers, onChange, color,t}) => {
	const list = elements.map(
		({text, originalIndex, dragListIndex, dropListIndex},index) => 
		<ListElement 
		key={index} 
		text={text} 
		answers={answers} 
		optIndex={originalIndex}
		dragListIndex={dragListIndex}
		value={dropListIndex} 
		t={t}
		onChange={onChange}/>);
	return <Grid item xs={12} sm={6} md={4}>
		<div 
			style={{
				backgroundColor: color, 
				border: `2px solid #FFF`,
				borderRadius: "3rem",
				padding:'20px'
			}}
			>
			{list}
		</div>
	</Grid>;
});

const ListElement = withTranslation()(withStyles(styles)(
	({text, optIndex, dragListIndex, answers, value, onChange, t}) => {
	return (
		<React.Fragment>
			<div>{text}</div>
			<DropDown
				value={value}
				color={"white"}
				onChange={(event) => onChange(event, optIndex, dragListIndex)}
			>
				<MenuItem value={-1}>
					<em>{t('None')}</em>
				</MenuItem>
				{answers.map(
					(text,index) => <MenuItem key={index} value={index}>{text}</MenuItem>
				)}
			</DropDown>
		</React.Fragment>
	)
}));
	

class DragAndDropBecomesSelect extends React.Component {
		
	render(){

		const {lists, dragLists, answers, onChange,t} = this.props;

		const renderList = lists.map(
			(list, listIndex) => 
			<List 
				key={listIndex} 
				color={listIndex < dragLists.length ? 
					dragLists[listIndex].containerColor : ""} 
				elements={list} 
				answers={answers} 
				onChange={onChange}
				t={t}
			/>
		);

		return <Grid container spacing={2}>{renderList}</Grid>;
	}
}

DragAndDropBecomesSelect.propTypes = {
	lists: PropTypes.arrayOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				text: PropTypes.string.isRequired,
				originalIndex: PropTypes.number.isRequired,
				dragListIndex: PropTypes.number.isRequired,
				dropListIndex: PropTypes.number.isRequired
			})
		)
	),
	dragLists: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			containerColor: PropTypes.string.isRequired,
		})
	),
	answers: PropTypes.arrayOf(PropTypes.string),
	onChange: PropTypes.func
};

export default withTranslation()(withStyles(styles)(DragAndDropBecomesSelect));