/*!
* Description: this file contains the component for
*  the type of exercise SimpleChoice, ex. guide 1, step 3, 
*  					"  Exercise: Millennium Development Goals Progress"
* Components: SimpleChoice
*/
import React from "react";
import ReactHtmlParser from 'react-html-parser';

// Styles
import withStyles from "@mui/styles/withStyles";
import { ourGreen40 } from 'assets/jss/incept-sustainability-variables.jsx';
import clsx from 'clsx';

// Style components
import Grid from '@mui/material/Grid';

// Forms
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

const styles = {
	root: {
		padding: '1rem 2rem',
	},
	gcontainer: {
		paddingTop: "20px"
	},
	qaContainer: {
		/*color: ourBlue*/
		// "@media only screen and (min-width: 960px)": {
		// 	paddingLeft: "30px",
		// }
	},
	radioLabel: {
		/*color:ourBlue,*/
		fontSize: '22px',
		color: "rgba(0, 0, 0, 0.87)",
		padding: "2px 10px 2px 2px"
		/*fontWeight: 'bold'*/
	},
	radioLabelR: {
		backgroundColor: ourGreen40,
		padding: "2px 10px 2px 2px",
		color: "white",
		fontSize: '22px',
		borderRadius: "2rem",
	},
	radioLabelW: {
		backgroundColor: "rgba(255, 136, 85, 0.4)",
		padding: "2px 10px 2px 2px",
		color: "white",
		fontSize: '22px',
		borderRadius: "2rem"
	},
	wrongButton: {
		color: "#ff8855"
	},
	rightButtom: {
		color: "#125a40"
	},
	radioButton: {
	},
	questionContainer: {
		fontSize: '22px',
		fontWeight: 'bold',
	}
};

/*
* component that render exercise
*/
class SimpleChoice extends React.Component {

	constructor(props) {
		super(props);
		const { exercise } = props;
		const { statements } = exercise;
		this.state = {
			selectedAnswers: Array(statements.length).fill(-1),//index is question order and value is answer order. E.g.: [3,1,2] would mean question 0 has answer 3 selected, question 1 has answer 1 selected, etc.
			completedanswers: statements.length
		}

		if (typeof this.props.datasav2 !== "undefined") {
			this.state.selectedAnswers = this.props.datasav2["content"];
		}
	}

	handleChange(e, questionIndex) {
		const answerIndex = parseInt(e.target.value);

		this.setState(state => {
			const sa = state.selectedAnswers;
			sa[questionIndex] = answerIndex;
			this.props.save2("SimpleChoice", sa, this.props.inkey, this.props.exercise_id);
			return { selectedAnswers: sa };
		});
	}

	_renderOptions(options, questionIndex) {
		const { classes, exercise } = this.props;
		const { statements } = exercise;
		const correctOpt = parseInt(statements[questionIndex].correctOpt);
		const groupName = "questions-from-" + questionIndex;

		const selected = this.state.selectedAnswers[questionIndex];
		const radios = options.map(
			(option, index) => {
				let radioLabelFinal = classes.radioLabel;
				let radioButtonFinal = classes.radioButton;
				const radioLabelR = clsx(classes.radioLabelR);
				const radioLabelW = clsx(classes.radioLabelW);
				const radioButtonR = clsx(classes.rightButtom);
				const radioButtonW = clsx(classes.wrongButton);

				if (selected === index) {
					if (correctOpt === index) {
						radioLabelFinal = radioLabelR;
						radioButtonFinal = radioButtonR;
					} else {
						radioLabelFinal = radioLabelW;
						radioButtonFinal = radioButtonW;
					}
				}

				return (
					<FormControlLabel
						value={index.toString()}
						control={<Radio color="default" className={radioButtonFinal} />}
						label={option}
						key={index.toString()}
						classes={{
							label: classes.radioLabel,
							root: radioLabelFinal
						}}
					/>
				)
			}
		);

		return (
			<FormControl component="fieldset">
				<RadioGroup aria-label={groupName} name={groupName} value={selected.toString()} onChange={(e) => this.handleChange(e, questionIndex)}>
					{radios}
				</RadioGroup>
			</FormControl>
		);
	}

	_renderStatements(statements) {
		const { classes } = this.props;
		const st = statements.map(
			(statement, questionIndex) => {
				const { subject, question, options } = statement;

				return (
					<Grid container key={questionIndex} alignItems="center" className={classes.gcontainer} spacing={4}>
						{subject &&
							<Grid item xs={12} sm={6}>
								{ReactHtmlParser(ReactHtmlParser(subject))}
							</Grid>
						}

						<Grid item xs={12} sm={6} className={classes.qaContainer} >
							<div className={classes.questionContainer}>
								{ReactHtmlParser(ReactHtmlParser(question))}
							</div>
							<div>
								{this._renderOptions(options, questionIndex)}
							</div>
						</Grid>
					</Grid>
				)
			}
		);

		return (
			<Grid container>
				{st}
			</Grid>
		);
	}

	render() {
		const {  exercise } = this.props;
		const { statements } = exercise;
		return (
			<div className={"paddingExercise"}>
				{ this._renderStatements(statements)}
			</div>
		);
	}
}

export default withStyles(styles)(SimpleChoice);