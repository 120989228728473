/*!
 * Description: this file contains the component for
 *  the type of exercise LifeCycle, ex. guide 3, step 4,
 *  					" Exercise: Life Cycle of a Computer"
 * Components: LifeCycle
 */
import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";

// Style components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Typography from "@mui/material/Typography";

// Forms
import Checkbox from "@mui/material/Checkbox";
import { ourOrange80, ourOrange } from 'assets/jss/incept-sustainability-variables.jsx';

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {},
	sections: {
		"& > div": {
			marginBottom: "10px",
		},
	},
	blockChoiceCard: {
		width: "150px",
		height: "50px",
		textAlign: "center",
		margin: "0",
		border: `1px none white`,
		"$radioButton:hover &": {
			'@media (hover:hover)' : { //Needed for mobiles not to get stuck on deselecting things
				backgroundColor: ourOrange80,
				color: "white",
			}
		},
		alignItems: "center",
		justifyContent: "center"
	},
	setcycle: {
		margin: "20px 0",
	},
	cardbody: {
		padding: "0 10px",
		flex: "inherit"
	},
	typo: {
		fontSize: "12px",
		fontWeight: "500",
		fontFamily: '"Raleway", "Helvetica", "Arial"',
		lineHeight: "1em",
	},
	radioButton: {
		width: "150px",
		height: "50px",
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	checkedRadioButton: {
		backgroundColor: ourOrange,
		color: "white",
	},
	preBlock: {
		display: "inline-block",
		marginBottom: "15px",
		"&:not(:last-child):after": {
			content: "''",
			border: "solid white",
			borderWidth: "0 3px 3px 0",
			display: "inline-block",
			padding: "3px",
			transform: "rotate(-45deg)",
			WebkitTransform: "rotate(-45deg)",
			margin: "0 10px"
		},
		"& $blockChoiceCard": {
			display: "inline-flex",
			verticalAlign: "middle",
		},
	},
};

const BlockChoice = ({ classes, text, checked = false }) => {
	const rootClass = checked
		? clsx(classes.blockChoiceCard, classes.checkedRadioButton)
		: classes.blockChoiceCard;

	return (
		<Card className={rootClass}>
			<CardBody isexercise={true} className={classes.cardbody}>
				<Typography className={classes.typo+" word-break"}>{text}</Typography>
			</CardBody>
		</Card>
	);
};

const Choice = (props) => {
	const { classes, text, ischecked, ...rest } = props;
	const bcObject = {
		classes: classes,
		text: text,
	};

	return (
		<Checkbox
			color="default"
			disableRipple
			checked={ischecked}
			className={classes.radioButton}
			icon={BlockChoice(bcObject)}
			checkedIcon={BlockChoice({
				checked: true,
				...bcObject,
			})}
			{...rest}
		/>
	);
};

/*
 * component for exercise of type life cicle
 */
class LifeCycle extends React.Component {

	static listProdAct = [
		"Extraction of raw materials",
		"Product design",
		"Recycled",
		"Production/manufacturing",
		"Disassembling",
		"Packaging",
		"Storage",
		"Distribution",
	];
	static listPostAct = [
		"Reused",
		"Incineration and landfilling",
		"Repurposed",
		"Recycled",
	];

	static prodActImpacts = [
		//Extraction of raw materials
		"Resource use, potentially of scare resources. Futhermore, it is often resource extraction is often energy intensive which causes CO2 emissions.",
		//Product design
		false,
		//Recycled
		"The process of disassembly and recycle the components causes energy use.",
		//Production/manufacturing
		"Often energy and water intensive, meaning Greenhouse gas emissions and potential to pollute water streams.",
		//Disassembling
		false,
		//Packaging
		"Often use of plastic or cardboard both resources use.",
		//"Storage",
		"Heating for storage facilities requires energy.",
		//"Distribution",
		"Need for transport by either car, train, boat of flight meaning greenhouse gas emission."
	];

	static postActImpacts = [
			//"Reused",
			false,
			//"Incineration and landfilling",
			false,
			//"Repurposed",
			false,
			//"Recycled",
			"The process of disassembly and recycle the components causes energy use.",
	];

	constructor(props) {
		super(props);
		this.state = {
			prodAct: Array(props.exercise.productionActivities.length).fill(false),
			postAct: Array(props.exercise.postActivities.length).fill(false),
		};

		//get previously saved exercise
		if (typeof props.datasav2 !== "undefined") {
			var aux = props.datasav2["content"];
			var flist = true;
			var index2 = 0;
			for (var index = 0; index < aux.length; index++) {
				if (aux[index] === "") {
					flist = false;
					continue;
				}
				if (flist) {
			this.state.prodAct[index] = aux[index];
				} else {
					this.state.postAct[index2] = aux[index];
					index2++;
				}
			}
		}

		this.getSelectedActivitiesAsArrayStrings.bind(this);
	}

	componentDidMount(){
		this.props.onActivitiesChange(
			this.getSelectedActivitiesAsArrayStrings(),
			this.getSelectedImpactsAsArrayStrings(),
		);
	}

	arrayEquals(a, b) {
		return Array.isArray(a) &&
		  Array.isArray(b) &&
		  a.length === b.length &&
		  a.every((val, index) => val === b[index]);
	}

	componentDidUpdate(_prevProps,prevState){
		if (this.arrayEquals(prevState.prodAct,this.state.prodAct)
		&& this.arrayEquals(prevState.postAct,this.state.postAct)){
			return;
		}
		this.props.onActivitiesChange(
			this.getSelectedActivitiesAsArrayStrings(),
			this.getSelectedImpactsAsArrayStrings(),
		);
	}

	getSelectedActivitiesAsArrayStrings(){
		const prodStr = this.props.exercise.productionActivities.filter((_,index) => this.state.prodAct[index]);
		const postStr = this.props.exercise.postActivities.filter((_,index) => this.state.postAct[index]);
		return [...prodStr,...postStr]
	}

	getSelectedImpactsAsArrayStrings(){
		const prodStr = this.props.exercise.prodActImpacts.filter((_,index) => this.state.prodAct[index]);
		const postStr = this.props.exercise.postActImpacts.filter((_,index) => this.state.postAct[index]);
		return [...prodStr,...postStr]
	}

	/* Takes an boolean array and converts it to array of string of the activities
	of this exercise */
	// static fromBoolArrayToString(booleanArr){
	// 	if (typeof booleanArr === "undefined" || !Array.isArray(booleanArr) || booleanArr.length <= 0) {
	// 		return [];
	// 	}
	// 	const separatorIndex = booleanArr.findIndex(element => element === "");
	// 	const prod = separatorIndex > -1 ? booleanArr.slice(0,separatorIndex) : [];
	// 	const post = separatorIndex > -1 ? booleanArr.slice(separatorIndex + 1,booleanArr.length) : [];

	// 	const prodStr = this.props.exercise.productionActivities.filter((_,index) => prod[index]);
	// 	const postStr = this.props.exercise.postActivities.filter((_,index) => post[index]);
	// 	return [...prodStr, ...postStr];
	// }

	handleChangeProd(_e, index) {
		this.setState((prevState) => {
			const pa = prevState.prodAct.map((item, i) => {
				if (index === i) {
					item = !item;
				}
				return item;
			});
			//save exercise
			this.props.save2(
				"LifeCycle",
				[...pa, "", ...this.state.postAct],
				this.props.inkey,
				this.props.exercise_id
			);
			return {
				prodAct: [...pa],
			};
		});
	}

	handleChangePost(_e, index) {
		this.setState((prevState) => {
			const pa = prevState.postAct.map((item, i) => {
				if (index === i) {
					item = !item;
				}
				return item;
			});

			//save exercise
			this.props.save2(
				"LifeCycle",
				[...this.state.prodAct, "", ...pa],
				this.props.inkey,
				this.props.exercise_id
			);
			return {
				postAct: [...pa],
			};
		});
	}

	render() {
		const { classes, exercise, t } = this.props;
		const { product, productionActivities, postActivities } = exercise;
		const productionAct = productionActivities.map((text, index) => {
			return (
				<Choice
					ischecked={this.state.prodAct[index]}
					key={index}
					classes={classes}
					text={text}
					onChange={(e) => this.handleChangeProd(e, index)}
					style={{ margin: "10px 0", lineHeight: "1.6" }}
				/>
			);
		});
		const postAct = postActivities.map((text, index) => (
			<Choice
				ischecked={this.state.postAct[index]}
				key={index}
				classes={classes}
				text={text}
				style={{ margin: "10px 0", lineHeight: "1.6" }}
				onChange={(e) => this.handleChangePost(e, index)}
			/>
		));

		const prodActSelected = this.state.prodAct.map((val, index) => {
			if (val) {
				let text = productionActivities[index];
				return (
					<div className={classes.preBlock} key={index}>
						<BlockChoice classes={classes} text={text} />
					</div>
				);
			}
			return "";
		});

		const postActSelected = this.state.postAct.map((val, index) => {
			if (val) {
				let text = postActivities[index];
				return (
					<div className={classes.preBlock} key={index}>
						<BlockChoice classes={classes} text={text} />
					</div>
				);
			}
			return "";
		});

		return (
			<div className={classes.root}>
				<div className={classes.setcycle}>
					<p>
						{t('Today most people use a product at work, this will therefore be the example in this exercise',
						{productName: product,
						defaultValue:"Today most people use a {{productName}} at work, this will therefore be the example in this exercise."
						})}
					</p>
					<Choice classes={classes} text={product} disabled checked />
				</div>
				<div className={classes.setcycle}>
					<p>
						{t('Which activities do you think happened before you received the product?')}
					</p>
					{productionAct}
				</div>
				<div className={classes.setcycle}>
					<p>
						{t('You now have the product, please select the stages that follow')+":"}
					</p>
					{postAct}
				</div>
				<div>
					<p>
						{t('Your {{productName}} life-cycle',
							{productName: product,
							defaultValue:"Your {{productName}} life-cycle"
						})}
					</p>
					{prodActSelected}
					{postActSelected}
				</div>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(LifeCycle));
