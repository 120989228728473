import React, { ReactNode } from 'react';
import { IdeaProvider } from "./IdeasContext";
import { CountriesProvider } from "./IdeasCountriesContext";
import { IdeasDepartmentsProvider } from "./IdeasDepartmentsContext";
import { IdeasFilterProvider } from "./IdeasFilterContext";
import { LabelsProvider } from "./IdeasLabelContext";
import { ModalActionsDivProvider } from "./IdeasModalActionDivContext";
import { ModulesProvider } from "./IdeasModulesContext";

const IdeasProvider = ({ children }: { children: ReactNode }) => {

    /**
     * @desc There shouldn't be any logic here, just the providers, they are not coupled to each other
     */

    return (
        <CountriesProvider>
        <IdeasDepartmentsProvider>
            <IdeasFilterProvider>
                <LabelsProvider>
                    <ModalActionsDivProvider>
                        <ModulesProvider>
                            <IdeaProvider>
                            {children}
                            </IdeaProvider>
                        </ModulesProvider>
                    </ModalActionsDivProvider>
                </LabelsProvider>
            </IdeasFilterProvider>
        </IdeasDepartmentsProvider>
    </CountriesProvider>
    );
}

export { IdeasProvider };
