/*!
* Description: this file contains the component for
*  the type of exercise WithoutOne (this is in progress)
* Components: WithoutOne
*/
import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";
import {  grayCape20, standardRadious } from 'assets/jss/incept-sustainability-variables.jsx';
import clsx from 'clsx';

import Container from '@mui/material/Container';

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";

// Effects
import Collapse from '@mui/material/Collapse';

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {
	},
	'dimensionsContainer': {
		display: 'flex',
		flexWrap: 'wrap'
	},
	buttonContainer: {
		textAlign: 'center'
	},
	buttonAnswer: {
		backgroundColor: grayCape20,
		color: '#FFF',
		margin: '20px 0',
		'&:hover, &:active, &:focus': {
			filter: 'brightness(85%)',
			backgroundColor: grayCape20,
		}
	}
};

const dimensionGroupStyles = {
	'box': {
		border: `2px solid #FFF`,
		borderRadius: standardRadious,
		padding: '10px',
		flex: 1,
		minWidth: '200px',
		margin: '10px',
		'&:hover': {
			backgroundColor: grayCape20,
			cursor: 'pointer'
		}
	},
	selected: {
		backgroundColor: grayCape20,
		opacity: '0.2'
	},
	'title': {
		textAlign: 'center',
		fontSize: '20px',
		fontWeight: 'bold',
		paddingBottom: '30px'
	},
	statementsContainer: {
		display: 'flex',
		flexWrap: 'wrap'
	}
};

const dimensionStyles = {
	'box': {
		padding: '10px',
		margin: '10px',
		width: 'calc(50% - 20px)',
		border: `2px solid none`,
		borderRadius: '1rem',
		"@media only screen and (max-width: 960px)": {
			width: '100%',
		}
	},
	'title': {
		textAlign: 'center',
		fontWeight: 'bold',
		paddingBottom: '5px'
	},
	'statement': {
		textAlign: 'center'
	}
}

const DimensionGroup = withStyles(dimensionGroupStyles)(
	({ classes, title, elements, onClick, selected, selectSoc, selectEc, selectEnv }) => {
		var selectedprev = [];
		if (title === "Economics")
			selectedprev = selectEc;
		else if (title === "Social")
			selectedprev = selectSoc;
		else
			selectedprev = selectEnv;
		const e = elements.map(
			({ title, statement }, index) =>
				(selectedprev[index].value) ? <Dimension key={index} title={title} statement={statement} /> : ""
		);
		const boxClass = selected ? clsx(classes.box, classes.selected) : classes.box
		return (
			<div className={boxClass} onClick={onClick}>
				<div className={classes.title}>{title}</div>
				<div className={classes.statementsContainer}>{e}</div>
			</div>
		);
	}
);

const Dimension = withStyles(dimensionStyles)(
	({ classes, title, statement }) => {
		return (
			<div className={classes.box}>
				<div className={classes.title}>
					{title}
				</div>
				<div className={classes.statement}>
					{statement}
				</div>
			</div>
		);
	}
);

class WithoutOne extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			dimension1: -1,
			dimension2: -1,
			selectedAnswersSoc: [...new Array(10)].map(()=> ({'statement_id':-1,'value':false})), // each index represents the number of the 
			//answer from original options array, and it should be filled with true or false
			selectedAnswersECon: [...new Array(10)].map(()=> ({'statement_id':-1,'value':false})),
			selectedAnswersEnv: [...new Array(10)].map(()=> ({'statement_id':-1,'value':false})),
			showAnswer: false,
		}
		if (typeof this.props.alldata["c31"] !== "undefined") {
			this.state.selectedAnswersEnv = this.props.alldata["c31"]["content"];
		}
		if (typeof this.props.alldata["c32"] !== "undefined") {
			this.state.selectedAnswersSoc = this.props.alldata["c32"]["content"];
		}
		if (typeof this.props.alldata["c33"] !== "undefined") {
			this.state.selectedAnswersECon = this.props.alldata["c33"]["content"];
		}
	}

	handleClick(e, index) {
		const answerIndex = parseInt(index);
		this.setState(prevState =>
			({
				dimension1: prevState.dimension2,
				dimension2: answerIndex
			}));
	}

	handleButtonClick(e) {
		e.preventDefault();
		if (this.state.showAnswer)
			this.setState({ showAnswer: false });
		else
			this.setState({ showAnswer: true });
	}

	render() {
		const { classes, dimensions, t } = this.props;
		const d = dimensions.map(
			({ title, elements }, index) =>
				<DimensionGroup key={index} title={title} elements={elements}
					selectSoc={this.state.selectedAnswersSoc} 
					selectEc={this.state.selectedAnswersECon}
					selectEnv={this.state.selectedAnswersEnv}
					selected={[this.state.dimension2].indexOf(index) >= 0}
					onClick={(e) => this.handleClick(e, index)} />
		);
		return (
			<div className={classes.root}>
				<div className={classes.dimensionsContainer}>
					{d}
				</div>
				<div className={classes.buttonContainer}>
					<ActionButton 
						type="submit"
						size="auto"
						onClick={(e) => this.handleButtonClick(e)}>
						{t('Not possible to take one away? Click here')} 
					</ActionButton>
				</div>
				<Container className={classes.answercontainer}>
					<Collapse in={this.state.showAnswer}>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.
						Proin ultricies nisi quis arcu semper, vestibulum commodo eros
						tincidunt. Integer eget lectus mollis, sollicitudin velit vel,
								gravida justo. Vestibulum in hendrerit nibh.</p>
					</Collapse>
				</Container>
			</div>
		);

	}
}

WithoutOne.defaultProps = {
	dimensions: []
};

export default withTranslation()(withStyles(styles)(WithoutOne));