/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";

import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import { ourDarkGreen, ourBlue, ourOrange } from "assets/jss/incept-sustainability-variables.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";
import "assets/css/closeDeleteButton.css";
import classNames from "classnames";
import Typography from "@mui/material/Typography";

import Checkbox from "@mui/material/Checkbox";

import FakeButton from "components/CustomButtons/FakeButton";

const useStyles = (theme) => ({
	smallButton: ({color, textColor}) => ({
		backgroundColor: color,
		color: textColor,
		border: "0px",
		borderRadius: "20px",
		fontSize: "14px",
		padding: "5px 30px",
		marginBottom: "0.5rem",
	}),
    bottomRight: {
        position: "absolute",
        bottom: "0",
        right: "0",
		margin: "0 35px 25px 0"
    },
	bottomLeft: {
        position: "absolute",
        bottom: "0",
        left: "0",
		margin: "0 35px 25px 0"
    },
	ellipsistext: {
	  "& p": {
		whiteSpace: "nowrap",
		fontSize: "14px",
		fontWeight: "500",
		lineHeight: "1.5em",
		color: "rgba(0, 0, 0, 0.87)",
		fontFamily: "gotham-font-light",
		overflow: "hidden",
		textOverflow: "ellipsis",
		"@supports (-webkit-line-clamp: 2)": {
		  overflow: "hidden",
		  textOverflow: "ellipsis",
		  whiteSpace: "initial",
		  display: "-webkit-box",
		  WebkitLineClamp: "4",
		  WebkitBoxOrient: "vertical"
		}
	  },
	},
	description: {

	}
});

function MyActions_cards(props) {
	const {classes, content} = props;
	const [load, setLoad] = useState(false);

	//Colors of different statuses
	var statuses = [];
	statuses["To do"] = ourBlue;
	statuses["In Progress"] = ourOrange;
	statuses["Completed"] = ourDarkGreen;

    const actionCard = props.actions.map((action, index) => {
		let analytics_title = (action.ideas.length>0) ? "from "+action.ideas.length+" ideas and comments":(action.activities.length>0) ? action.activities[0].title:"";
		let respon = (action.responsible.user_id!==0) ? action.responsible.first_name+" "+action.responsible.last_name:"Unassigned";
		let departmentname = {id:0, name: "All"};
		props.departments.map((item) => {if (parseInt(item.id)===action.departmentid) departmentname = item});
		let actionfor = departmentname.name;
		let compl_date = (action.completed_by!=="") ? action.completed_by:"Unassigned";
        return (
            <GridItem xs={12} sm={12} md={6} lg={4} otherclasses={classes.columnCardsPadding} key={action.idaction}>
                <CardAnalytics style={{height: "100%"}}>
                    <CardAnalyticsHeader title={action.title} subtitle={""} 
                    isjustifyContent={true} hrNoMarginBottom={{marginBottom:"0"}}>
                        {/*<Checkbox
                            color="default"
                            disableRipple
                            checked={false}
                            style={{color:ourOrange}}
                            className={classes.radioButton}
                        />*/}
						<div className={"deletebutton"} onClick={(e) => props.deleteActionModal(e,action.idaction,action.title)}>
							<a href="#">
								<span className={"left"}>
								<span className={"circle-left"}></span>
								<span className={"circle-right"}></span>
								</span>
								<span className={"right"}>
								<span className={"circle-left"}></span>
								<span className={"circle-right"}></span>
								</span>
							</a>
						</div>
                    </CardAnalyticsHeader>
                    <CardAnalyticsBody>
                    <div style={{position:"relative",paddingBottom:"190px"}}>
                        <div>
                            <div><span style={{margin:"5px 0"}}><b>{"Analytics: "}</b>{analytics_title}</span></div>
                            <div className={classNames(classes.ellipsistext, classes.commentsContainer)}>
								<b>{"Description: "}</b>
								<Typography
									component="p"
									variant="body2"
									style={{ textAlign: "justify" }}
								>
									{action.description}
								</Typography>
							</div>
                        </div>
                    </div>
					<div className={classes.bottomLeft} style={{margin: "0 0 40px 2rem",lineHeight:"30px"}}>
							<div><b>{"Created by: "}<span style={{margin:"5px 0",color:ourBlue}}>{action.created_by.first_name+" "+action.created_by.last_name}</span></b></div>
							<div><b>{"Action for: "}<span style={{margin:"5px 0",color:ourBlue}}>{actionfor}</span></b></div>
                            <div><b>{"Responsible: "}<span style={{margin:"5px 0",color:ourBlue}}>{respon}</span></b></div>
                            <div><b>{"Complete by: "}<span style={{margin:"5px 0",color:ourBlue}}>{compl_date}</span></b></div>
                            <div><b>{"Status: "}<span style={{margin:"5px 0",color:statuses[action.status]}}>{action.status}</span></b></div>
                    </div>
					<div className={classes.bottomRight} onClick={(e) => props.openModal(action)}>
                       <span style={{color:ourOrange,cursor:"pointer",fontWeight:"700"}}>{"See details"}</span>
					   {/*To see details we can call a function here (props.seedetails), that has as parameter the actions object (and we fill out the
					   modal with the info from action object)*/}
                    </div>
                    </CardAnalyticsBody>
                </CardAnalytics>
            </GridItem>
        );
    });


	return (
            <GridContainer>
                {actionCard}
            </GridContainer>
	);
}
export default
withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(MyActions_cards);
