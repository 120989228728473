import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import ReactHtmlParser from "react-html-parser";

export default ({ data,pieColors,enableArcLinkLabels,theme,defs,halfpie=true,t /* see data tab */ }) => (
    <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        startAngle={halfpie ? 90:0}
        endAngle={halfpie ? -90:360}
        enableArcLinkLabels={enableArcLinkLabels}
        innerRadius={0.65}
        padAngle={1}
        cornerRadius={2}
        activeOuterRadiusOffset={8}
        colors={pieColors}
        borderWidth={2}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'opacity',
                    '0.2'
                ]
            ]
        }}
        arcLinkLabel={function(e){return e.value+"%"}}
        arcLinkLabelsSkipAngle={5}
        arcLinkLabelsTextOffset={18}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsDiagonalLength={20}
        arcLinkLabelsStraightLength={20}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor="black"
        enableArcLabels={false}
        arcLabelsRadiusOffset={0.55}
        arcLabelsSkipAngle={9}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'opacity',
                    2
                ]
            ]
        }}
        theme={theme}
        defs={defs}
    />
);