import {
    CircularProgress,
    LinearProgress,
    Button,
    createStyles,
    Typography
} from "@mui/material";
import {
    makeStyles,
	withStyles
} from "@mui/styles";
import { useState, useEffect } from "react";
import { ourGreen, ourGreen20 } from "assets/jss/incept-sustainability-variables.jsx";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
        },
        barColorPrimary: {
            backgroundColor: ourGreen
        },
        colorPrimary: {
            backgroundColor: ourGreen20
        },
        bar: {
            borderRadius: '2rem',
        },
        MuiLinearProgress: {
            padding: '14px',
            borderRadius: '2rem',
            width: '100%',
            marginRight: '72px'
        },
        margin: {
            margin: theme.spacing(3),
        },
        linearProgress: {
            width: theme.spacing(30),
        },
        Typography: {
            position: "absolute",
            textAlign: "center",
            left: "55px",
            top: "2px",

        }
    })
);


function ProgressButton(props) {
    const classes = useStyles();
    const [level, setLevel] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setLevel((newLevel) => (newLevel >= 100 ? 0 : newLevel + 10));
        }, 700);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div style={{
            position: "relative"
        }}>
            <LinearProgress
                className={classes.MuiLinearProgress}
                variant="determinate"
                classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary, bar: classes.bar }}
                valueBuffer={parseInt(props.val) + 10}
                value={props.val} />
            <Typography
                className={classes.Typography}
            >
                {props.val}%
            </Typography>


        </div>
    );
}

export default withStyles((theme) => ({
}))(ProgressButton);