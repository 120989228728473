/*!
 * Description: this file contains the component for
 *  the type of exercise QAWithQuote, ex. guide 2, step 2,
 *  					"  Exercise: Sustainability definition"
 * Components: QAWithQuote
 */
import React from "react";
import ReactHtmlParser from "react-html-parser";

// Styles
import withStyles from "@mui/styles/withStyles";
import { standardRadious } from "assets/jss/incept-sustainability-variables.jsx";

// Style components
import Container from "@mui/material/Container";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

// Forms
import TextField from "@mui/material/TextField";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";

// Effects
import Collapse from "@mui/material/Collapse";

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {
	},
  buttonContainer: {
    textAlign: "right",
    "@media only screen and (max-width: 960px)": {
			textAlign: "center",
      margin: "30px 0"
		}
	},
  buttonAnswer: {
    color: "#fff",
    borderRadius: "3rem",
    textTransform: "none",
    backgroundColor: "#ff6f69",
    width: "160px",
    height: "25px",
    overflow: "hidden",
    "&:hover, &:active, &:focus,": {
      filter: "brightness(100%)",
      backgroundColor: "#ff6f69",
    },
  },
	textField: {
		"& 	.MuiInput-input": {
			//color: '#FFF',
		},
	},
  cardAnswer: {
    borderRadius: standardRadious,
    "@media only screen and (max-width: 960px)": {
			borderRadius: "1rem",
		}
  },
};

/*
 * component for exercise with input field
 */
class QAWithQuote extends React.Component {
  state = {
    userAnswer: "",
    showAnswer: false,
  };

  componentWillUnmount() {
    // this.props.save2("QA",this.state.userAnswer, this.props.inkey);
  }

  componentDidMount() {
    //retrieve exercise here
    if (typeof this.props.datasav2 !== "undefined") {
      this.setState({ userAnswer: this.props.datasav2["content"] });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ showAnswer: !this.state.showAnswer });
  }

  handleChange(e) {
    this.setState({ userAnswer: e.target.value });
    this.props.save2("QA", e.target.value, this.props.inkey, this.props.exercise_id);
  }

  _renderQuote(quote) {
    const { classes } = this.props;
    return (
      <Card className={classes.cardQuote}>
        <CardBody>{ReactHtmlParser(ReactHtmlParser(quote))}</CardBody>
      </Card>
    );
  }

  _renderAnswerContainer(answer) {
    const { classes, t } = this.props;
    return (
      <Card className={classes.cardAnswer}>
        <CardBody className={classes.answerBox} isexercise={true}>
          <form
            noValidate
            autoComplete="off"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <Container>
              <TextField
                label=" "
                className={classes.textField}
                margin="normal"
                placeholder=" "
                fullWidth
                onChange={(e) => this.handleChange(e)}
                value={this.state.userAnswer}
              />
            </Container>
            <Container className={classes.buttonContainer}>
              <ActionButton
                type="submit"
                size="auto"
              >
                {t('See 3 examples')}
              </ActionButton>
            </Container>
          </form>
          <Container>
            <Collapse in={this.state.showAnswer}>
              {ReactHtmlParser(ReactHtmlParser(answer))}
            </Collapse>
          </Container>
        </CardBody>
      </Card>
    );
  }

  render() {
    const { classes, exercise } = this.props;
    const { answer } = exercise;
    /*return (
			<div className={classes.root}>
				{this._renderQuote(quote)}
				{this._renderAnswerContainer(answer)}
			</div>
		);*/
    return (
      <div className={classes.root}>{this._renderAnswerContainer(answer)}</div>
    );
  }
}

export default withTranslation()(withStyles(styles)(QAWithQuote));
