import { useState } from "react";

const useCountriesData = () => {

    const [selectedCountry, setselectedCountry] = useState([]);
    const [countries, setCountries] = useState([]);

    return {
        selectedCountry, setselectedCountry,
        countries, setCountries
    };
}

export default useCountriesData;