import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

// Layouts to be used by Routers
import Admin from "layouts/Admin.jsx";
import GuidesNav from "layouts/GuidesNav.jsx";
import Guides from "layouts/Guides.js";
import Login from "layouts/Login.jsx";
import SignUp from "layouts/SignUp.jsx";
import UserRegister from "layouts/UserRegister.jsx";
import UploadFile from "layouts/UploadFile.jsx";

//Importing css assets
//import 'bootstrap/dist/css/bootstrap.min.css';
import "assets/css/material-dashboard-react.css?v=1.7.0";

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createTheme, StyledEngineProvider, adaptV4Theme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import jwt from "jwt-decode";
import { TokenProvider } from "context/auth_context/TokenContext";

//Internationalization - This is needed for translate all components
import 'i18n';
//import ErrorBoundary from 'components/Log/ErrorBoundary.jsx';
import log from 'loglevel';
import remote from 'loglevel-plugin-remote';
import { urllog, isAuthenticated } from 'assets/jss/incept-sustainability-variables.jsx';
import * as qs from 'query-string';

//To track events
import Plausible from 'plausible-tracker'
import { IdeasProvider } from "components/AnalyticsIdeas/context/IdeasProvider";

const { enableAutoPageviews } = Plausible({
	domain: 'app.inceptsustainability.com'
})

enableAutoPageviews();

//remove lang localstorage
/*if (localStorage.getItem("i18nextLng") !== null) {
	localStorage.removeItem('i18nextLng');
	//this.checkuser(decodedtoken.data.user.id,localStorage.getItem( 'token' ));
}*/

// For logging errors

// Set up the remote logger
const customJSON = log => ({
	msg: log.message,
	level: log.level.label,
	stacktrace: log.stacktrace
});

const token = localStorage.getItem('token');
const authstr = `Bearer ${token}`;


remote.apply(log,
	{
		format: customJSON,
		headers: { Authorization: authstr },
		token: token,
		url: urllog
	});
log.enableAll();

//Handling errors
/*window.addEventListener('error', function (event) {
	if (event.error.hasBeenCaught !== undefined){
	  return false
	}
	event.error.hasBeenCaught = true;
	//log.error(error);
	// ... your useful code here
});*/
/*window.onerror = function(message, url, lineNumber) {
	// make ajax call to api to save error on server
	//log.error(message);
	return true;
};  */

//Remove console logs from production
if (process.env.NODE_ENV !== "development")
	console.log = () => { };

const outerTheme = createTheme({
	typography: {
		fontSize: 14,
		fontFamily: [
			'gotham-font-book',
		].join(','),
	},
	palette: {
		background: {
			default: "#F9F9F7",
			fontSize: 14
		},
		nocolor: {
			default: "transparent",
		}
	},
	card: {
		border: "0 solid rgba(0,0,0,.125)",
		boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.14)",
		borderRadius: "16px",
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		}
	},
	components: {
		MuiContainer: {
			styleOverrides: {
				root: {
					maxWidth: '1280px'
				},
				maxWidthLg: {
					maxWidth: '1280px!important',
				},
			},
		},
	}
});


const hist = createBrowserHistory();
var isexpired = false;

if (localStorage.getItem("token")) {
	const decodedtoken = jwt(localStorage.getItem("token"));
	try {
		if (Date.now() >= decodedtoken.exp * 1000) {
			//Logout
			localStorage.removeItem('token');
			//localStorage.removeItem('userName');
			isexpired = true;
		}
	} catch (err) {
		console.log("Error");
	}
}

const parsed = qs.parse(window.location.search);
let isSSO = false;
if (!isAuthenticated()) {
	if (window.location.search !== "") {
		if (parsed.fr === "rmbacd") {
			isSSO = true;
		}
	}
}

let renderRedirectLogin = (isSSO2) => {
	if (isSSO2) {
		const urlsso = "https://iswp.inceptsustainability.com?option=oauthredirect&app_name=RambollOAuthAD&redirect_url=https://iswp.inceptsustainability.com?option=headless_sso%26redbck=" + window.location.origin + window.location.pathname;
		console.log(urlsso);
		return <Route path='/' component={() => {
			window.location.href = urlsso;
			return null;
		}} />
	}
};

ReactDOM.render(
	<StyledEngineProvider injectFirst>
		<ThemeProvider theme={outerTheme}>
			<CssBaseline />
					<Router history={hist}>
						<TokenProvider>
							<IdeasProvider>
								<Route>
									<Switch>
										{renderRedirectLogin(isSSO)}
										<Route path="/admin" component={Admin} isexpired={isexpired} />
										<Route path="/guidesNav" component={GuidesNav} isexpired={isexpired} />
										<Route path="/guides" component={Guides} isexpired={isexpired} />
										<Route path="/Login/:invalidkey" component={Login} isexpired={isexpired} />
										<Route path="/Login" component={Login} isexpired={isexpired} />
										
										{/*
											// Signup has been moved to new app
											<Route path="/SignUp" component={SignUp} isexpired={isexpired} />
											<Route path="/UserRegister" component={UserRegister} isexpired={isexpired} />
										*/}

										<Route path="/UploadFile" component={UploadFile} isexpired={isexpired} />
										<Redirect from="/" to="/Login" />
									</Switch>
								</Route>
							</IdeasProvider>
						</TokenProvider>
					</Router>
		</ThemeProvider>
	</StyledEngineProvider>,
	document.getElementById("root")
);


