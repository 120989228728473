/*!
* Description: this file contains the component for
*  the type of exercise WithoutOne (this is in progress)
* Components: WithoutOne
*/
import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";
import { ourGreen, standardRadious } from 'assets/jss/incept-sustainability-variables.jsx';
import clsx from 'clsx';

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@mui/material/Container';

const styles = {
	'dimensionsContainer': {
		display: 'flex',
		flexWrap: 'wrap',
	},
	buttonContainer: {
		textAlign: 'center'
	},
	buttonAnswer: {
		backgroundColor: ourGreen,
		color: '#FFF',
		margin: '20px 0'
	},
	card: {
		backgroundColor: "white",
		borderRadius: "2rem",
	},
	infoText: {
		fontWeight: '500',
		fontSize: '16px',
		marginLeft: '10px',
	},
};

const dimensionGroupStyles = {
	'box': {
		border: `2px solid `+ourGreen,
		borderRadius: standardRadious,
		padding: '10px',
		flex: 1,
		minWidth: '200px',
		margin: '10px'
	},
	selected: {
		backgroundColor: 'rgba(17,102,40,0.3)',
		opacity: '0.2'
	},
	'title': {
		textAlign: 'center',
		fontSize: '20px',
		fontWeight: 'bold',
		paddingBottom: '30px'
	},
	statementsContainer: {
		display: 'flex',
		flexWrap: 'wrap',
	}
};

const dimensionStyles = {
	'box': {
		padding: '10px',
		margin: '10px',
		width: 'calc(50% - 20px)',
		"@media only screen and (max-width: 960px)": {
			width: '100%',
		}
	},
	'title': {
		textAlign: 'center',
		fontWeight: 'bold',
		paddingBottom: '5px'
	},
	'statement': {
		textAlign: 'center'
	}
}

const DimensionGroup = withStyles(dimensionGroupStyles)(
	({ classes, title, elements, onClick, selected, selectSoc, selectEc, selectEnv }) => {
		var selectedprev = [];
		if (title === "Economics")
			selectedprev = selectEc;
		else if (title === "Social")
			selectedprev = selectSoc;
		else
			selectedprev = selectEnv;
		const e = elements.map(
			({ title, statement }, index) =>
				(selectedprev[index].value) ? <Dimension key={index} title={title} statement={statement} /> : ""
		);
		const boxClass = selected ? clsx(classes.box, classes.selected) : classes.box
		return (
			<div className={boxClass} onClick={onClick}>
				<div className={classes.title}>{title}</div>
				<div className={classes.statementsContainer}>{e}</div>
			</div>
		);
	}
);

const Dimension = withStyles(dimensionStyles)(
	({ classes, title, statement }) => {
		return (
			<div className={classes.box}>
				<div className={classes.title}>
					{title}
				</div>
				<div className={classes.statement}>
					{statement}
				</div>
			</div>
		);
	}
);

class WithoutOneStatic extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			dimension1: -1,
			dimension2: -1,
			selectedAnswersSoc: [...new Array(10)].map(()=> ({'statement_id':-1,'value':false})), // each index represents the number of the 
			//answer from original options array, and it should be filled with true or false
			selectedAnswersECon: [...new Array(10)].map(()=> ({'statement_id':-1,'value':false})),
			selectedAnswersEnv: [...new Array(10)].map(()=> ({'statement_id':-1,'value':false})),
		}
		if (typeof this.props.alldata["c31"] !== "undefined") {
			this.state.selectedAnswersEnv = this.props.alldata["c31"]["content"];
		}
		if (typeof this.props.alldata["c32"] !== "undefined") {
			this.state.selectedAnswersSoc = this.props.alldata["c32"]["content"];
		}
		if (typeof this.props.alldata["c33"] !== "undefined") {
			this.state.selectedAnswersECon = this.props.alldata["c33"]["content"];
		}
	}


	render() {
		const { classes, dimensions,  children } = this.props;
		const d = dimensions.map(
			({ title, elements }, index) =>
				<DimensionGroup key={index} title={title} elements={elements}
					selectSoc={this.state.selectedAnswersSoc} selectEc={this.state.selectedAnswersECon}
					selectEnv={this.state.selectedAnswersEnv}
					selected={[this.state.dimension2].indexOf(index) >= 0} />
		);
		return (
			<Container style={{paddingLeft: "0"}}>
				<Card className={classes.card}>
					<CardBody>
						<div className={classes.infoText}>
							{children}
						</div>
						<div className={classes.dimensionsContainer}>
							{d}
						</div>
					</CardBody>
				</Card>
			</Container>

		);

	}
}

WithoutOneStatic.defaultProps = {
	dimensions: []
};

export default withStyles(styles)(WithoutOneStatic);