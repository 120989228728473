import React from 'react';
import clientConfig from 'client-config.js';

import { withTranslation } from 'react-i18next';

/**
 * Since it is extremely daunting to work in this React app I used normal CSS directly on the HTML elements
 * Also, I did not use translation at all, since this feature is ONLY for Swedbank and it will be in Swedish. I know it sucks but the whole app sucks, hence ...
 */


const LoanLandingPage = (props) => {
  const { t } = props;
    const swedbankLoanLink = clientConfig.swedbankLoanLink;
    const googleStorageSwedbankLoanPdfLink = clientConfig.googleStorageSwedbankLoanPdfLink;
    const handlePdfDownload = () => {
        window.open(googleStorageSwedbankLoanPdfLink, '_blank', 'noopener,noreferrer');
    };


  return (
    <div style={{
      padding: '30px',
      fontFamily: 'Arial, sans-serif',
    }}>
      <h1 style={{
        fontSize: '28px',
        fontWeight: 'bold',
        marginBottom: '25px',
      }}>
        {t('Loan')}
      </h1>
      <div style={{
        backgroundColor: 'white',
        padding: '30px',
        borderRadius: '16px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        marginBottom: '25px',
        minHeight: '300px',
      }}>
        <p style={{
          fontSize: '18px',
          marginBottom: '25px',
          lineHeight: '1.5',
        }}>
        {t('LoanPageDescription')}
        </p>
        <h3 style={{
          fontSize: '20px',
          fontWeight: 'bold',
          marginBottom: '20px',
        }}>
          {t('ThisIsHowItWorks')} :
        </h3>
        <ul style={{
          listStyleType: 'disc',
          paddingLeft: '25px',
          marginBottom: '20px',
          fontSize: '17px',
          lineHeight: '1.6',
        }}>
          <li>{t('AttachRelevantInformation')}</li>
          <li>{t('IncludeSummary')}</li>
          <li>{t('UponReceipt')}</li>
        </ul>
        <p style={{ fontSize: '17px' }}>
          <button
            onClick={handlePdfDownload}
            style={{
              color: '#0066CC',
              textDecoration: 'underline',
              background: 'none',
              border: 'none',
              cursor: 'pointer',
              fontSize: '17px',
            }}
          >
            {t('LoanApplicationForm')}
          </button>
        </p>
      </div>
      <button
        style={{
          backgroundColor: '#FF8855',
          color: 'white',
          padding: '12px 24px',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '18px',
          fontWeight: 'bold',
        }}
        onClick={() => {
          // Open link in a new tab
          window.open(swedbankLoanLink, '_blank', 'noopener,noreferrer');
        }}
      >
        {t('SubmitInBank')}
      </button>
    </div>
  );
}

export default withTranslation()(LoanLandingPage);