/*!
* Description: this file contains the component for
*  the type of exercise ExtraSDG, ex. guide 3, step 3, 
*  					"  Exercise: The 6th SDGs at DFDS" (only for DFDS)
* Components: ExtraSDG
*/
import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

// Styles
import withStyles from "@mui/styles/withStyles";


//Translations
import { withTranslation } from 'react-i18next';
import { ourOrange } from "assets/jss/incept-sustainability-variables";


const styles = {
    carouselPer: {
		"& .carousel": {
			margin: "0 auto"
        },
        "& .carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover,.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover": {
            background: ourOrange,
        },
		"& .carousel .thumb.selected, .carousel .thumb:hover": {
			border: "3px solid "+ourOrange,
		},
		"& .carousel .control-dots .dot": {
			boxShadow: "2.5px 2.5px 3.5px " + ourOrange,
			background: ourOrange,
		},
		"& .carousel .control-arrow::before, .carousel.carousel-slider .control-arrow::before": {
			"@media only screen and (max-width: 600px)": {
				margin: "auto"
			}
		},
		"& .carousel .thumbs": {
			"@media only screen and (max-width: 600px)": {
				paddingLeft: "0"
			}
		},
		"& .carousel .thumb": {
			"@media only screen and (max-width: 600px)": {
				display: "table-cell"
			}
		}
    },
	root: {
		textAlign: "center",
		"@media only screen and (max-width: 600px)": {
			//maxWidth: "320px",
			padding: "0 20px 0 0"
		}
	}
	
};

/*
* this component renders the exercise carousel
*/
class CarouselSection extends React.Component {
	
	constructor(props){
		super(props);
		this.state = {
            
        }
	}

	render(){
		const {classes,imagesProps, t} = this.props;
        //const { images } = imagesProps;

        const imagesList = imagesProps.map((image, index) => {
            return (
                <div key={index} className={"carrouselImage_isxf"}>
                    <img className="responsive" src={image.img} />
                    {/*<p className="legend">{image.legend}</p>*/}
                </div>
            );
        });

		return (
			<div className={classes.root}>
				<Carousel 
				showArrows={true} 
				showStatus={false}
				className={classes.carouselPer} 
				width={"1'0%"}
				>
					{imagesList}
				</Carousel>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(CarouselSection));