/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React from 'react';
import withStyles from "@mui/styles/withStyles";
import Checkbox from "@mui/material/Checkbox";
import { ourOrange } from "assets/jss/incept-sustainability-variables.jsx";

const useStyles = () => ({
	checkbox: {
		color: ourOrange, 
		alignItems: "initial", 
		paddingTop: "5px", 
		height: "1.5em",
		width: "1.5em", 
		marginRight: "-10px"
	}
});

function CustomCheckbox(props) {
	const {classes, ...rest} = props;

	return (
		<Checkbox
			color="default"
			disableRipple
			checked={props.checked}
			className={classes.checkbox}
			{...rest}
		/>
	);
}

export default withStyles(useStyles)(CustomCheckbox);