import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";

//Forms
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { ourDarkGreen } from "assets/jss/incept-sustainability-variables";

const imgWidth = '100px';

const styles = {
	select:{
		border: "1px solid #fff",
		borderRadius: '1rem',
		marginRight: "10px",
		minHeight:'150px',
		/*minWidth:'150px',*/
		marginTop: "10px",
		marginBottom: "10px",
		flex: "1 1 150px",
		maxWidth: "150px",
		'& img':{
			/*width:imgWidth*/
			margin: "0 auto",
			display: "block",
			maxWidth: "100%"
		},
		"&:before": {
			borderBottom: "1px none white"
		  },
		"&:hover:before":{
			borderBottom: "1px none white"
		},
		"&:after": {
			borderBottom: "1px none white"
		}
	},
	selfilled: {
		margin: "27px 12px 10px",
	},
	icon: {
		fill: "white"
	},
	item:{
        width:"100%"
	},
	itemDefault:{
		width:imgWidth,
                maxWidth:imgWidth,
		minHeight:'100px',
		backgroundColor:"#78909cff",
		display:'inline-block',
		verticalAlign:'middle',
		'li &':{
			height:'0px',
			display:'none'
		}
	},
	itemPlaceholder:{
		height:'0px',
		minHeight:'0px',
		backgroundColor:'transparent',
		padding:'0px'
	}
};

// This can't be a High Order Component because it conflicts with Select component by not being direct descendants
const MenuItemImage = 
	({classes, imgURL, itemValue, index}) => (
		<MenuItem className={classes.item} value={itemValue} key={index}>
			{imgURL!=="" && <img alt="" src={imgURL} width={150}/>}
			{imgURL==="" && <div style={{height:"150px",width:"150px",backgroundColor:ourDarkGreen,color:"white", padding:"20px 10px"}}>
				<span>None</span></div>}
		</MenuItem>
);

const DropDownImage = 
	(props) => {
		const {classes, onChange, images, currentValue} = props;
		const items = images.map(
			(imageObj,index) => {
				const {imageURL,show} = imageObj;
				if ((typeof show!=="undefined" && show) || currentValue===index) {
					return MenuItemImage({
						classes: classes,
						imgURL: imageURL,
						itemValue: index,
						index: index
					});
				}
				else if (typeof show!=="undefined") {
					return "";
				}
				else {
					return MenuItemImage({
						classes: classes,
						imgURL: imageURL,
						itemValue: index,
						index: index
					});
				}
			}
		);
		return (
			<Select
				classes={{icon: classes.icon,filled: classes.selfilled}}
				className={classes.select}
				value={currentValue}
				onChange={onChange}
				variant="filled"
				autoWidth={true}
			>
				<MenuItem className={classes.itemPlaceholder} value={-1} key={-1} disabled>
				</MenuItem>
				{items}
			</Select>
		);
	};

export default withStyles(styles)(DropDownImage);