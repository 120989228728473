import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ourGreen  } from 'assets/jss/incept-sustainability-variables.jsx';

const styles = {
    smallButton: {
        backgroundColor: ourGreen,
        color: "white",
        width:"100%",
        border: "0px",
        fontWeight: "200",
        borderRadius: "20px",
        //cursor: "pointer",
        fontSize: "13px",
        padding: "1px 20px",
        /* [theme.breakpoints.down("sm")]: {
          marginBottom: "1rem",
        }, */
    },
    tableLegend: {
        '& .MuiTable-root': {
          width: "auto"
        },
        '& .MuiBox-root': {
            padding: "24px 0 0 0px"
        },
        '& .MuiTableCell-root': {
            borderBottom: "0",
            paddingBottom:"0"
        },
    },
}

const LegendGraphs = withStyles(styles)(
	(props) => {
  // Declare a new state variable, which we'll call "count"
  const [count, setCount] = useState(0);
  const { classes, t, dataLegends,colors, ...other} = props;

  const tableLegends = dataLegends.map((legendM, index) => {
    return (<TableRow
        key={index+"RK"}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
        <TableCell align="right" style={{whiteSpace: "nowrap",paddingRight:"0"}}>
            <button disabled className={classes.smallButton} style={{backgroundColor:colors[index],color:"rgb(51, 51, 51)"}}>
                {legendM.value+"%"}
            </button>
        </TableCell>
        <TableCell component="th" scope="row">
        <span>{legendM.label}</span>
        </TableCell>
    </TableRow>);
  });

  return (
    <TableContainer className={classes.tableLegend} {...other}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table" style={{margin:"0 auto"}}>
            <TableBody>
                {tableLegends}
            </TableBody>
        </Table>
    </TableContainer>
  );
  });

export default LegendGraphs;