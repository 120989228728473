// core components/views for Admin layout

import Dashboard from "views/Dashboard/Dashboard";
import AssessmentsCategories from "views/Assessments";
import LoanLandingPage from "views/Loan";
import GuidesPage from "views/Guides/GuidesPage.jsx";
//import AnalyticsPage from "views/Analytics/AnalyticsPage.jsx";
import AnalyticsIdeas from "views/Analytics/AnalyticsIdeas/AnalyticsIdeas.jsx";
import AnalyticsCompany from "views/Analytics/AnalyticsCompany/AnalyticsCompany.jsx";
import AnalyticsExercise from "views/Analytics/AnalyticsCompany/AnalyticsExercise.jsx";
import UserProfile from "views/UserProfile/UserProfile.jsx";
import Analytics_CompletionLog from"views/Analytics/AnalyticsCompletion/AnalyticsCompletionLogPage.jsx";
import AnalyticsPage from "views/Analytics/AnalyticsPage.jsx";
import AnalyticsPage_manager from "views/Analytics/AnalyticsPage_manager.jsx";
import HelpPage from "views/Help/HelpPage.jsx";
import MyActionsPage from "views/MyActions/MyActionsPage.jsx";
import KnowledgeBasePage from "views/KnowledgeBase/KnowledgeBasePage.jsx";
import ConsultRamboll from "views/ConsultRamboll/index.jsx";

//custom icon images
import DashboardIcon from "assets/img/navbar/DashboardIcon.png";
import DashboardIconBlack from "assets/img/navbar/DashboardIconBlack.png";
import AnalyticsIcon from "assets/img/navbar/AnalyticsIcon.png";
import AnalyticsIconBlack from "assets/img/navbar/AnalyticsIconBlack.png";
import ElearningIcon from "assets/img/navbar/ModuleIcon.png";
import ElearningIconBlack from "assets/img/navbar/ModuleIconBlack.png";
import Knowledgebase from "assets/img/navbar/KnowledgebaseIcon.png";
import KnowledgebaseBlack from "assets/img/navbar/KnowledgebaseIconBlack.png";
import MyActivites from "assets/img/navbar/MyActivityIcon.png";
import MyActivitiesIconBlack from "assets/img/navbar/MyActivityIconBlack.png";
import MyActions from "assets/img/navbar/MyactionsIcon.png";
import MyActionsBlack from "assets/img/navbar/MyactionsIconBlack.png";
import ContactUs from "assets/img/navbar/LetterIcon.png";
import ContactUsBlack from "assets/img/navbar/LetterIconBlack.png";
import Logout from "assets/img/navbar/LogoutIcon.png";
import LogoutIconBlack from "assets/img/navbar/LogoutIconBlack.png";
import AssessmentsIcon from "assets/img/navbar/AssessmentsIcon.png";
import AssessmentsIconBlack from "assets/img/navbar/AssessmentsIconBlack.png";
import LoanIconBlack from "assets/img/navbar/LoanIconBlack.png";
import LoanIcon from "assets/img/navbar/LoanIcon.png";
import ConsultRambollBlackIcon from "assets/img/navbar/ConsultRambollBlack.png";
import ConsultRambollIcon from "assets/img/navbar/ConsultRamboll.png";


import analytics_submenu from "assets/img/analytics_submenu.png"

import i18n from "i18next";
// core components/views for RTL layout

let dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    trans: i18n.t("Dashboard"),
    icon: [DashboardIconBlack, DashboardIcon],
    component: Dashboard,
    roleD: "Both",
    submenu: false,
    layout: "/admin",
  },
  {
    path: "/guides/:id",
    name: "E-Learnings",
    trans: i18n.t("E-Learnings"),
    icon: [ElearningIconBlack, ElearningIcon],
    component: GuidesPage,
    roleD: "Both",
    submenu: false,
    layout: "/admin"
  },
  {
    path: "/assessments",
    name: "Assessments",
    trans: i18n.t("Assessments"),
    icon: [AssessmentsIconBlack, AssessmentsIcon],
    component: AssessmentsCategories,
    roleD: "Both",
    submenu: false,
    layout: "/admin"
  },
  {
    path: "/loan",
    name: "Loan",
    trans: i18n.t("Loan"),
    icon: [LoanIconBlack, LoanIcon],
    component: LoanLandingPage,
    roleD: "Both",
    submenu: false,
    layout: "/admin"
  },
  {
    path: "/KB",
    name: "Knowledge Base",
    trans: i18n.t("Knowledge Base"),
    icon: [KnowledgebaseBlack, Knowledgebase],
    component: KnowledgeBasePage,
    roleD: "Both",
    submenu: false,
    layout: "/admin"
    },
  {
    path: "/my_actions",
    name: "My Actions",
    trans: i18n.t("My Actions"),
    icon: [MyActionsBlack, MyActions],
    component: MyActionsPage,
    roleD: "Manager",
	permissionAnalytics: true,
    submenu: false,
    layout: "/admin"
  },
  // {
  //   path: "/analytics_overview",
  //   name: "Analytics",
  //   trans: i18n.t("Analytics"),
  //   icon: [AnalyticsIconBlack, AnalyticsIcon],
  //   component: AnalyticsPage,
  //   roleD: "Manager",
	// permissionAnalytics: true,
  //   submenu: false,
  //   layout: "/admin"
  // },
  {
    path: "/analytics_overview_lmanager",
    name: "Analytics",
    trans: i18n.t("Analytics"),
    icon: [AnalyticsIconBlack, AnalyticsIcon],
    component: AnalyticsPage_manager,
    roleD: "Manager",
	  permissionAnalytics: true,
    submenu: false,
    layout: "/admin"
  },
  {
    path: "/analytics_completion",
    name: "Completion log",
    trans: i18n.t("Completion Log"),
    icon: "content_paste",
    component: Analytics_CompletionLog,
    roleD: "Manager",
	  permissionAnalytics: true,
    layout: "/admin",
    submenu: true,
    invisible: true
  },
  {
    path: "/analytics_company",
    name: "Employee feedback",
    trans: i18n.t("Company Analytics"),
    icon: analytics_submenu,
    component: AnalyticsCompany,
    roleD: "Manager",
	permissionAnalytics: true,
    layout: "/admin",
    submenu: true,
    invisible: true
  },
  {
    path: "/analytics_ideas/:archived",
    name: "Archived Ideas and comments",
    trans: i18n.t("Archived Ideas and comments"),
    icon: "content_paste",
    component: AnalyticsIdeas,
    subpage: true,
    roleD: "Manager",
	permissionAnalytics: true,
    layout: "/admin",
    submenu: false,
    invisible: true
  },
  {
    path: "/analytics_ideas",
    name: "Ideas and comments",
    trans: i18n.t("Ideas and comments"),
    icon: analytics_submenu,
    component: AnalyticsIdeas,
    subpage: false,
    roleD: "Manager",
	permissionAnalytics: true,
    layout: "/admin",
    submenu: true,
    invisible: true
  },

  {
    path: "/custom_activity/:exerciseid",
    name: "Analytics",
    trans: i18n.t("Analytics"),
    icon: "content_paste",
    component: AnalyticsExercise,
    subpage: true,
    roleD: "Manager",
	permissionAnalytics: true,
    layout: "/admin",
    submenu: false,
    invisible: true
  },
  {
    path: "/consult-ramboll",
    name: "Consult Ramboll",
    component: ConsultRamboll,
    roleD: "Both",
    icon: [ConsultRambollBlackIcon, ConsultRambollIcon],
    layout: "/admin",
  },
  {
    path: "/user",
    name: "My Profile",
    trans: i18n.t("My Profile"),
    icon: [MyActivitiesIconBlack, MyActivites],
    component: UserProfile,
    roleD: "Both",
    submenu: false,
    layout: "/admin"
  },
  {
    path: "/help",
    name: "Contact Us",
    trans: i18n.t("Contact Us"),
    icon: [ContactUsBlack, ContactUs],
    component: HelpPage,
    roleD: "Both",
    submenu: false,
    layout: "/admin"
  },
  {
    path: "/logout",
    name: "Logout",
    trans: i18n.t("Logout"),
    icon: [LogoutIconBlack, Logout],
    component: HelpPage,
    roleD: "Both",
    submenu: false,
    layout: "/admin"
  }

];

//get from localstorage
if (localStorage.getItem("is_kbarray") !== null ) {
	let kbroutes = localStorage.getItem("is_kbarray");
	let kbarray = JSON.parse(kbroutes);
	if (kbarray !== undefined && kbarray.length > 0) {
		const indexKB = dashboardRoutes.findIndex(el => el.name === "Knowledge Base");
		dashboardRoutes[indexKB].id = "";
		kbarray.reverse().map((obj, index) => {
			let newrt = {
				path: "/knowledgebasesub",
				id: obj.id,
				name: obj.name,
				nameGen: "Knowledge Base Sub",
				trans: i18n.t("Knowledge Base Sub"),
				icon: analytics_submenu,
				component: KnowledgeBasePage,
				roleD: "Both",
				norder: index,
				layout: "/admin",
				submenu: true,
				submenuKB: true,
				invisible: true
			};
			dashboardRoutes = [...dashboardRoutes.slice(0, indexKB+1), newrt, ...dashboardRoutes.slice(indexKB+1)];
		});
	}
}

//get from localstorage
/*if (localStorage.getItem("is_compside") !== null ) {
	let moDroutes = localStorage.getItem("is_compside");
	let mdarray0 = JSON.parse(moDroutes);
  let mdarray = mdarray0.modCategories;
  console.log("side menu", mdarray);
	if (mdarray !== undefined && mdarray.length > 0) {
    const indexMOD = dashboardRoutes.findIndex(el => el.name === "E-Learnings");
		dashboardRoutes[indexMOD].id = "";
		mdarray.reverse().map((obj, index) => {
			let newrt = {
				path: "/guides",
				id: obj.id,
				name: obj.name,
				nameGen: "E-Learnings sub",
				trans: i18n.t("E-Learnings"),
				icon: [ElearningIconBlack, ElearningIcon],
				component: GuidesPage,
				roleD: "Both",
				layout: "/admin",
				submenu: true,
				invisible: true
			};
			dashboardRoutes = [...dashboardRoutes.slice(0, indexMOD+1), newrt, ...dashboardRoutes.slice(indexMOD+1)];
		});
	}
}*/

export default dashboardRoutes;
