import { forwardRef } from "react";
import ReactHtmlParser from "react-html-parser";

const FlipCardBack = forwardRef (({ backContent }, ref) => {
    return (
        <div ref={ref} style={{color: 'black', textAlign: 'left', padding: '20px'}}>
            <span style={{display: 'block'}}>{ReactHtmlParser(ReactHtmlParser(backContent))}</span>
        </div>
    );
});

export default FlipCardBack;


