import React from "react";
import PropTypes from "prop-types";

// Styles
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";

// Style components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";

import Typography from "@mui/material/Typography";
import { ourBlue, ourOrange } from 'assets/jss/incept-sustainability-variables.jsx';

// Effects
import Tooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {
		marginTop:'10px'
	},
	sections: {
		"& > div": {
			marginBottom: "10px",
		},
	},
	blockChoiceCard: {
		width: "150px",
		height: "50px",
		textAlign: "center",
		margin: "0",
		cursor: "pointer",
		border: `1px none white`,
		"$radioButton:hover &": {
			backgroundColor: ourBlue,
			color: "white",
		},
		alignItems: "center",
		justifyContent: "center"
	},
	setcycle: {
		margin: "20px 0",
	},
	cardbody: {
		padding: "0 10px",
		flex: "inherit"
	},
	typo: {
		fontSize: "12px",
		fontWeight: "500",
		fontFamily: '"Raleway", "Helvetica", "Arial"',
		lineHeight: "1em",
	},
	radioButton: {
		width: "150px",
		height: "50px",
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	checkedRadioButton: {
		backgroundColor: ourBlue,
		color: "white",
	},
	preBlock: {
		display: "inline-block",
		marginBottom: "15px",
		"&:not(:last-child):after": {
			content: "''",
			border: "solid white",
			borderWidth: "0 3px 3px 0",
			display: "inline-block",
			padding: "3px",
			transform: "rotate(-45deg)",
			WebkitTransform: "rotate(-45deg)",
			margin: "0 10px"
		},
		"& $blockChoiceCard": {
			display: "inline-flex",
			verticalAlign: "middle",
		},
	},
	advice:{
		backgroundColor: ourOrange,
		fontWeight:'bold',
		padding:'10px'
	}
};

const TextOnlyTooltip = withStyles({
	tooltip: {
		fontSize: "14px",
	}
})(Tooltip);


const BlockChoice = ({ classes, text, checked = false }) => {
	const rootClass = checked
		? clsx(classes.blockChoiceCard, classes.checkedRadioButton)
		: classes.blockChoiceCard;
	
	return (
		<Card className={rootClass}>
		<CardBody isexercise={true} className={classes.cardbody}>
			<Typography className={classes.typo+" word-break"}>{text}</Typography>
		</CardBody>
		</Card>
	);
	};

class LifeCycleExtension extends React.Component {
	prefixKey = "life-cycle-ext";

	state = {
		tooltipOpenID : ''
	}

	handleTooltipClick(_e,tooltipID){
		this.setState({tooltipOpenID:tooltipID});
	}

	handleTooltipClose(_e,tooltipID){
		this.setState(prevState => {
			if (prevState.tooltipOpenID === tooltipID){
				return { tooltipOpenID:'' }
			}
		});
	}

	render() {
		const { classes, activities = [], impacts = [], t } = this.props;
		let activitiesToRender = <div className={classes.advice}>{t('First select your life cycle in the previous exercise')}.</div>

		if (activities.length > 0){
			activitiesToRender = activities.map((val, index) => {
				if (val) {
					const tooltipID=this.prefixKey+"tooltip"+index;
					const firsttext = impacts[index].replace(/\\n+/g, '\n');
					const text = <div style={{whiteSpace: 'pre-line'}}>{firsttext}</div>;
					return (
						<React.Fragment key={tooltipID}>
							<ClickAwayListener onClickAway={(e) => this.handleTooltipClose(e, tooltipID)}>
								<TextOnlyTooltip 
									placement="top" 
									title={text}
									open={tooltipID === this.state.tooltipOpenID}
									onClick={(e) => this.handleTooltipClick(e, tooltipID)}
									>
									<div className={classes.preBlock}>
										<BlockChoice classes={classes} text={val} />
									</div>
								</TextOnlyTooltip>
							</ClickAwayListener>
						</React.Fragment>
					);
				}
				return "";
				}); 
		}

		return (
		<div className={classes.root}> 
			<div>
				{activitiesToRender}
			</div>
		</div>
		);
	}
}

LifeCycleExtension.propTypes = {
	activities: PropTypes.arrayOf(PropTypes.string),
	impacts: PropTypes.arrayOf(PropTypes.string)
}
	
export default withTranslation()(withStyles(styles)(LifeCycleExtension));  