import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
// @mui/icons-material
import { Link } from "react-router-dom";

// core components
import cardAnalyticsHeaderStyle from "assets/jss/material-dashboard-react/components/cardAnalyticsHeaderStyle.jsx";

function CardHeader({ ...props }) {
  const {
    classes,
    className,
    children,
    title,
    device,
    subtitle="",
    source="",
	activitySource="",
    department="",
	exerciseid="",
    isjustifyContent,
    hrNoMarginBottom={},
    alignCenter=true,
    t,
    ...rest
  } = props;
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    [className]: className !== undefined
  });
  let justContent = isjustifyContent ? {justifyContent: "space-between"}:{};
  return (
    <div className={cardHeaderClasses} {...rest}>
       {device ==="dashmobile"? 
       <div style={{textAlign:'center',lineHeight:'45px',...justContent}}>
       <h5 style={{marginBottom:0}}>{title}</h5>
        {children}
        <hr style={hrNoMarginBottom}></hr>
        </div>: 
        <div>
            <div style={{display:"flex",alignItems:(alignCenter ? "center":"initial"),...justContent}}><div>
				{device ==="mobile"? <h5 style={{marginBottom:60}}>{title}</h5>:<h5 style={{marginBottom:0}}>{title}</h5>}
                {subtitle!=="" && <span>{subtitle}</span>}
                {source!="" && 
				<span style={{fontSize:"13px"}}><br></br>{t("From")} :
					<i style={{fontFamily: "gotham-font-light"}}>{source}</i></span>}
				{activitySource!="" && 
				<span style={{fontSize:"13px"}}><br></br>{t("Actvity")} :
					<Link target="_blank"  to={{pathname:"/admin/custom_activity/"+exerciseid, query:0}} style={{marginTop:"20px"}}>
						<i style={{fontFamily: "gotham-font-light"}}>{activitySource}</i>
					</Link>
				</span>}	
                {/*department!="" && <span style={{fontSize:"13px"}}><br></br>{"Market: "}
                            <i style={{fontFamily: "gotham-font-light"}}>{department}</i></span>*/}
                </div>
                {children}
            
            </div> 
            <hr style={hrNoMarginBottom}></hr>
        </div>}
    </div>
  );
}

CardHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

export default withStyles(cardAnalyticsHeaderStyle)(CardHeader);
