/*!
* Description: this file contains the component for
*  the type of exercise DragAndDropQAPair, ex. guide 2, step 1, "Exercise: Important Events"
* Components: DragAndDropQAPair
*/
import React from "react";
import ReactHtmlParser from 'react-html-parser';

// Styles
import withStyles from "@mui/styles/withStyles";
import { ourGreen, standardRadious, ourOrange } from 'assets/jss/incept-sustainability-variables.jsx';
import clsx from 'clsx';

// Style components
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import checked from 'assets/img/checkedWhiteBg.png';
// Effects
import Collapse from '@mui/material/Collapse';

import DragAndDropBecomesSelect from "components/Exercise/DragAndDropQAPairBecomesSelect.jsx";

import { withTranslation } from 'react-i18next';

const styles = {
	root: {
		paddingTop: "20px",
	},
	questionBlock: {
		marginBottom: '5px',
		padding: '5px 0',
		borderRadius: '0.5rem',
		fontSize: '16px',
		fontWeight: '500',
		lineHeight: '1.6'
	},
	gridQuestionsItem: {
		padding: '10px 10px 10px 0'
	},
	gridDropItem: {
		padding: '10px'
	},
	dropBlock: {
		border: '1px solid #FFF',
		borderRadius: standardRadious,
		backgroundColor: 'transparent',
		color: '#FFF',
		textAlign: 'center',
		display: "table-cell", 
		verticalAlign: "middle",
		minHeight: '65px',
		'& div': {
			marginBottom: '0'
		},
	},
	dropdivBlock: {
		display: "table", 
		overflow: "hidden", 
		width:"100%", 
		minHeight:"65px"
	},
	drragdivBlock: {
		display: "table", 
		overflow: "hidden", 
		width:"100%", 
		minHeight:"65px",
		marginBottom:"5px"
	},
	answerBlock: {
		textAlign: 'center',
		fontWeight: 'bold',
		marginBottom: '5px',
		backgroundColor: '#ff8855',
		color: '#FFF',
		padding: '5px 10px',
		borderRadius: standardRadious,
		'&[draggable=true]': {
			cursor: 'grab',
			'&:active': {
				cursor: 'grabbing'
			},
			'&:hover': {
				boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)'
			}
		}
	},
	gridAnswersItem: {
		padding: '10px 0 10px 10px'
	},
	textf2: {
		marginTop: "10px",
	},
	buttonContainer: {
		textAlign: 'right',
		marginTop: "20px",
		paddingRight: "0",
	},
	buttonContainerAnswers: {
		textAlign: "right",
		paddingRight: "0",
		marginBottom: "60px"
	},
	buttonAnswer: {
		color: "#fff",
		backgroundColor: ourGreen,
		'&:hover, &:active, &:focus': {
			filter: 'brightness(85%)',
			backgroundColor: ourGreen,
		}
	},
	draggable: {
		backgroundColor: ourOrange,
		cursor: 'grab',
		minHeight:"65px",
		display: "table-cell", 
		verticalAlign: "middle",
		'&:active': {
			cursor: 'grabbing'
		},
		'&:hover': {
			boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)'
		}
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "0",
		marginTop: "20px",
		"@media only screen and (max-width: 960px)": {
			marginTop: "20px"
		  }
	},
};

const RealAnswer = withTranslation()(withStyles(styles)(
	({ classes, onClick, answerImg, showAnswer, t }) => (
		<React.Fragment>
			<Container className={classes.buttonContainerAnswers}>
				<ActionButton
					size="auto"
					onClick={onClick}
					type="submit"
				>
					{t('See correct answer')}
				</ActionButton>
			</Container>
			<Container className={classes.answercontainer}>
				<Collapse in={showAnswer}>{ReactHtmlParser(ReactHtmlParser(answerImg))}</Collapse>
			</Container>
		</React.Fragment>
	)
));

const Answer = withStyles(styles)(
	({ classes, children, ...rest }) =>
		<div className={classes.drragdivBlock}>
		<div draggable className={clsx(classes.draggable, classes.answerBlock)} {...rest}>
			{children}
		</div>
		</div>
);

const QuestionRow = withStyles(styles)(
	({ classes, children, questionText, ...rest }) =>
		<React.Fragment>
			<Grid item xs={12} md={7} className={classes.gridQuestionsItem}>
				<div className={classes.questionBlock}>
					{questionText}
				</div>
			</Grid>
			<Grid item xs={12} md={5} className={classes.gridDropItem}>
				<div className={classes.dropdivBlock}>
				<div className={classes.dropBlock} {...rest}>
					{children}
				</div>
				</div>
			</Grid>
		</React.Fragment>
);

class DragAndDropQAPair extends React.Component {

	// questions copies original questions array, but adds an answerIndex to which it is paired.
	// if it is not paired yet, it contains -1.
	state = {
		questions: this.props.exercise.questions.map(({ text }) => { return { text: text, answerIndex: -1 } }),
		showAnswer: false, //a flag to show answer, used by render
		submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false,
		answer_to_save: [],
	}

	/*
	* retrieves data saved
	*/
	componentDidMount() {
		//retrieve exercise here
		if (typeof this.props.datasav2 !== "undefined" && this.props.datasav2["content"].length>0) {
			this.setState({ questions: this.props.datasav2["content"] });
		}
	}

	onDragStart = (event, answerIndex) => {
		event.dataTransfer.setData("answerIndex", answerIndex.toString());
	}

	onDragOver = (event) => {
		event.preventDefault();
	}

	onDrop = (event, qIndex) => {

		let newAnswerIndex = parseInt(event.dataTransfer.getData("answerIndex"));
		this.setState(prevState => {

			/* New */

			if (prevState.questions === []) {
				return;
			}
			if (prevState.questions[qIndex].answerIndex !== -1) {
				return;
			}

			const questions = prevState.questions.map(
				({ answerIndex, ...rest }, index) =>
					//to allow dragging between question rows, we must first clean the old question row that contained the dragging answer block

					({
						...rest,
						answerIndex: (
							(index === qIndex)
								?
								newAnswerIndex
								:
								((answerIndex === newAnswerIndex)
									?
									-1
									:
									answerIndex
								)
						)
					})
			);
			//this.props.save2("DragAndDropQA", questions, this.props.inkey, this.props.exercise_id);

			return {
				questions: questions,
				answer_to_save: questions,
				submittedanswer: false
			};
		});
	}

	onDropOrigin = (event) => {
		let oldAnswerIndex = parseInt(event.dataTransfer.getData("answerIndex"));

		this.setState(prevState => {

			if (prevState.questions === []) {
				return;
			}

			const questions = prevState.questions.map(
				({ answerIndex, ...rest }, index) => ({ ...rest, answerIndex: ((answerIndex === oldAnswerIndex) ? -1 : answerIndex) })
			);
			//this.props.save2("DragAndDropQA", questions, this.props.inkey, this.props.exercise_id);

			return {
				questions: questions,
				answer_to_save: questions,
				submittedanswer: false
			};
		});
	}

	handleSubmit(e) {
		e.preventDefault();
		//Commented to allow empty answers
		//if (this.state.answer_to_save.length>0) {
			this.setState({ submittedanswer: true });
			this.props.save2("DragAndDropQA", this.state.answer_to_save, this.props.inkey, this.props.exercise_id);
		//}
	}

	handleShowAnswer(e) {
		e.preventDefault();
		this.setState({ showAnswer: !this.state.showAnswer });
	}

	handleSelectChange(event,questionIndex){
		const answerIndexSelected = parseInt(event.target.value);
		this.setState((prevState) => {
		  const newQuestions = prevState.questions.map(
			({ answerIndex, ...rest }, index) => {
			  if (index === parseInt(questionIndex)){
				return { ...rest, answerIndex:  answerIndexSelected };
			  }else if ( answerIndex === answerIndexSelected){
				return { ...rest, answerIndex: -1 };
			  }
			  return { ...rest, answerIndex: answerIndex };
			}
		  );

		  //this.props.save2("DragAndDropQA", newQuestions, this.props.inkey);
		  return {
			questions : newQuestions,
			answer_to_save: newQuestions,
			submittedanswer: false
		  }
		});
	}

	render() {
		const { classes, exercise, t } = this.props;
		const { answers, answerimg } = exercise;
		const pairedAnswersIndexes = this.state.questions.filter(({ answerIndex }) => answerIndex >= 0).map(({ answerIndex }) => answerIndex);

		const unpairedAnswers = answers.map(({ text }, index) => {
			if (pairedAnswersIndexes.indexOf(index) === -1) {
				return (
					<Answer
						key={index}
						onDragStart={(e) => this.onDragStart(e, index)}
					>
						{text}
					</Answer>
				);
			}
			return "";
		});

		const questionRows = this.state.questions.map(({ text, answerIndex }, questionIndex) =>
			<QuestionRow
				key={questionIndex}
				questionText={text}
				onDrop={(e) => this.onDrop(e, questionIndex)}
				onDragOver={(e) => this.onDragOver(e)}
			>
				{(answerIndex !== -1)
					?
					(
						<Answer
							onDragStart={(e) => this.onDragStart(e, answerIndex)}
						>
							{this.props.exercise.answers[answerIndex].text}
						</Answer>
					)
					: t('Place here')
				}
			</QuestionRow>
		);

		const dragAndDrop = (
		<React.Fragment>
			<Grid container item md={9} alignItems="center">
			{questionRows}
			</Grid>
			<Grid
				item xs={12} md={3}
				className={classes.gridAnswersItem}
				onDrop={(e) => this.onDropOrigin(e)}
				onDragOver={(e) => this.onDragOver(e)}
			>
				{unpairedAnswers}
			</Grid>
		</React.Fragment>
		);

		//const touch = window.matchMedia('(hover: none)').matches;
		//This works on andrid as well:
		const touch = window.matchMedia('(pointer: coarse)').matches;

		const select = <DragAndDropBecomesSelect
			questions={this.state.questions} 
			answers={answers}
			onChange={(e, q) => this.handleSelectChange(e,q)}
		/>;

		return (
			<div className={classes.root}>
				<Grid container >
					{touch ? select : dragAndDrop}
					<Grid item xs={12} md={12}  >
						<div className={classes.statusContainer}>
						{this.state.submittedanswer && <p>{t("Great! Your answer has been saved.")}</p>}
						</div>
						<Container className={classes.buttonContainer}>
							<ActionButton
								size="auto"
								type="submit"
								nomargin={true}
								centered={true}
								onClick={(e) => this.handleSubmit(e)}
							>
								{t('Submit')}
							</ActionButton>
						</Container>
						{
							answerimg !== "" &&
							<RealAnswer
								answerImg={answerimg}
								showAnswer={this.state.showAnswer}
								onClick={(e) => this.handleShowAnswer(e)}
							/>
						}
					</Grid>
				</Grid>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(DragAndDropQAPair));