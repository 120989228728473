import { IconButton, Snackbar } from '@mui/material'
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react'
import ShareIcon from "@mui/icons-material/Share"

const CopyToClipboardButton = (props) => {
    const { classes } = props;
    const [open, setOpen] = useState(false)
    const handleClick = () => {
      setOpen(true)
      navigator.clipboard.writeText(props.stringToShare)
    }
    
    return (
        <>
          <Tooltip title={props.toolTipText}>
            <IconButton onClick={handleClick} color="primary">
              <ShareIcon/>
            </IconButton>
          </Tooltip>
          <Snackbar
            open={open}
            onClose={() => setOpen(false)}
            autoHideDuration={2000}
            message="Copied to clipboard"
          />
        </>
    )
}

export default CopyToClipboardButton