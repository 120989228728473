/*!
* Description: this file contains the component for
*  the type of exercise Timeline, ex. guide 2, step 1, 
*  					"Exercise: Timeline"
* Components: Timeline
*/
import React from "react";


// Styles
import withStyles from "@mui/styles/withStyles";


// Style components
import Grid from '@mui/material/Grid';




// Effects
import Tooltip from '@mui/material/Tooltip';

const styles = {
	root: {
		padding: '1rem 2rem',
	},
	left: {
		textAlign: 'right',
		fontSize: '18px',
		padding: '5px'
	},
	right: {
		textAlign: 'left',
		fontSize: '18px'
	},
	middle: {
		height: '100%',
		position: 'relative'
	},
	bar: {
		borderRight: `4px solid #ffe682`,
		margin: '0 auto',
		width: '4px',
		height: '100%'
	},
	dot: {
		height: '12px',
		width: '12px',
		backgroundColor: '#ffe682',
		borderRadius: '50%',
		position: 'absolute',
		top: '-10px',
		left: '47%'
	},
	arrowRight: {
		width: '0',
		height: '0',
		borderTop: '8px solid transparent',
		borderBottom: '8px solid transparent',
		borderLeft: `8px solid #ffe682`,
		position: 'absolute',
		top: 'calc(50% - 8px)',
		left: '55%',
	},
	arrowLeft: {
		width: '0',
		height: '0',
		borderTop: '8px solid transparent',
		borderBottom: '8px solid transparent',
		borderRight: `8px solid #ffe682`,
		position: 'absolute',
		top: 'calc(50% - 8px)',
		right: '55%',
	},
	rowContainer: {
		cursor: "pointer"
	}
};

const TextOnlyTooltip = withStyles({
	tooltip: {
		fontSize: "14px",
	},
})(Tooltip);

const TextOnlyTooltipright = withStyles({
	tooltip: {
		fontSize: "14px",
	},
	popper: {
		"@media only screen and (max-width: 960px)": {
			left: "-50% !important"
		}
	}
})(Tooltip);

/*
* Component that render exercise with a timeline 
*/
class Timeline extends React.Component {

	state = {
		tooltipOpenID: ""
	}

	handleTooltipClick(e, tooltipID) {
		this.setState({
			tooltipOpenID: tooltipID
		});
	}

	_renderFactRow(fact, index, printTo) {

		const { classes } = this.props;
		const { title, statement, date } = fact;
		const arrowClass = {
			className: (printTo === 'right') ? classes.arrowRight : classes.arrowLeft
		};

		const startDot = <div className={classes.dot}></div>;
		const leftID = index + 'left';
		const leftOrStart = (
			<TextOnlyTooltip
				title={statement}
				placement="top-end"
				disableFocusListener
				disableHoverListener
				disableTouchListener
				open={leftID === this.state.tooltipOpenID}
				onClick={(e) => this.handleTooltipClick(e, leftID)}
			>
				<div>{title} <b>{date}</b></div>
			</TextOnlyTooltip>
		);
		const rightID = index + 'right';
		const right = (
			<TextOnlyTooltipright
				title={statement}
				placement="top-start"
				disableFocusListener
				disableHoverListener
				disableTouchListener
				open={rightID === this.state.tooltipOpenID}
				onClick={(e) => this.handleTooltipClick(e, rightID)}
			>
				<div><b>{date}</b> {title}</div>
			</TextOnlyTooltipright>
		);

		return (
			<Grid container key={index} className={classes.rowContainer}>
				<Grid item xs={5} className={classes.leftItem}>
					<div className={classes.left}>
						{
							(printTo === 'left' || printTo === 'start') &&
							leftOrStart
						}
					</div>
				</Grid>
				<Grid item xs={2}>
					<div className={classes.middle}>
						{
							(printTo === 'start') && startDot
						}
						<div className={classes.bar}>
						</div>
						<div {...arrowClass}>
						</div>
					</div>
				</Grid>
				<Grid item xs={5} className={classes.rightItem}>
					<div className={classes.right}>
						{
							(printTo === 'right') &&
							right
						}
					</div>
				</Grid>
			</Grid>
		);
	}

	_renderFacts(facts) {
		
		var printTo = 'start';
		const fs = facts.map(
			(fact, index) => {
				const render = this._renderFactRow(fact, index, printTo);
				printTo = printTo === 'start' || printTo === 'left' ? 'right' : 'left';
				return render;
			}
		);

		return (
			<React.Fragment>
				{fs}
			</React.Fragment>
		)
	}

	render() {
		const { classes, exercise } = this.props;
		const { facts } = exercise;
		return (
			<div className={classes.root}>
				{this._renderFacts(facts)}
			</div>
		);
	}
}

export default withStyles(styles)(Timeline);