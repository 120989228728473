import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";

// Style components
import Grid from "@mui/material/Grid";

//form inputs
import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@mui/material/MenuItem';

//Translations
import { withTranslation } from 'react-i18next';

const styles = theme => ({
	row:{
		borderBottom:'1px dotted white',
		padding:'10px',
		margin:0
	},
	dropdownContainer: {
		[theme.breakpoints.down('sm')]: {
			'&.MuiGrid-item':{
				paddingTop:'5px'
			}
		}
	},
	questionContainer: {
		[theme.breakpoints.down('sm')]: {
			'&.MuiGrid-item':{
				paddingBottom:0
			}
		}
	},
	questionLabel: {
		color:'white'
	},
	dropdownitem: {
		overflow: "auto"
	}
});

const Row = withTranslation()(withStyles(styles)(
	({classes, questionIndex, questionLabel, answers, value, onChange, t}) => {
	return (
	<Grid container item className={classes.row} spacing={4} xs={12}>
		<Grid item xs={12} sm={6} className={classes.questionContainer}>
			<div className={classes.questionLabel}>{questionLabel}</div>
		</Grid>
		<Grid item xs={12} sm={6} className={classes.dropdownContainer}>
			<DropDown
				value={value}
				color={"white"}
				onChange={(event) => onChange(event, questionIndex)}
			>
				<MenuItem value={-1}>
					<em>None</em>
				</MenuItem>
				{answers.map(
					({text},index) => <MenuItem key={index} value={index} classes={{root:classes.dropdownitem}}>{text}</MenuItem>
				)}
			</DropDown>
		</Grid>
	</Grid>
	);
})); 

class DragAndDropBecomesSelect extends React.Component {
		
	render(){

		const {questions, answers, onChange,t} = this.props;
		const renderQuestions = questions.map(({text, answerIndex},index) => 
		<Row 
			key={index} 
			questionIndex={index} 
			questionLabel={text} 
			answers={answers} 
			value={answerIndex}
			onChange={onChange}
			t={t}
		/>);
		return <React.Fragment>{renderQuestions}</React.Fragment>;
	}
}

export default withTranslation()(withStyles(styles)(DragAndDropBecomesSelect));