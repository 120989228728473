import { ourOrange } from "assets/jss/incept-sustainability-variables"
import { ourLightGreen } from "assets/jss/incept-sustainability-variables"

export const FlipAnimationCSS  = {
    flipCard: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        transformStyle: 'preserve-3d',
        transition: 'transform 1s',
        perspective: '1200px',
        cursor: 'pointer', 
        borderRadius: '20px',
        margin: '15px',
        border: `2px solid ${ourOrange}`,
        width: '100%',
        height: '100%',
    },

    flipCardFront: {
        borderRadius: '18px',
        position: 'absolute',
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backfaceVisibility: 'hidden',
        top: 0,
        left: 0,
    },

    flipCardBack: {
        borderRadius: '18px',
        position: 'absolute',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backfaceVisibility: 'hidden',
        backgroundColor: `${ourLightGreen}`,
        top: 0,
        left: 0,
        transform: 'rotateY(180deg)',
        padding: '15px',
    },

    flippedUp: {
        transform: 'rotateX(180deg)',
    },

    flippedDown: {
        transform: 'rotateX(-180deg)',
    },

    flippedRight: {
        transform: 'rotateY(180deg)',
    },

    flippedLeft: {
        transform: 'rotateY(-180deg)',
    },

    flippedDownRight: {
        transform: 'rotateZ(-180deg) rotateY(180deg)',
    },
    flippedDownLeft: {
        transform: 'rotateZ(-180deg) rotateY(-180deg)',
    },
}

