import Card from "components/Card/Card";

const CommentsCard = ({ date, commentData, isMobile }) => {

    return (
        <Card style={{ backgroundColor: '#F3F3F3', marginBottom: '15px', marginTop: '0', padding: isMobile ? '10px 0 2.3rem 10px' : '0 0 1.7rem 0' }}>
            <p style={{
                fontWeight: 'bold',
                margin: isMobile ? '' : '1.5rem 2rem 0 1.5rem',
                padding: isMobile ? "1rem" : "",
                wordWrap: "break-word"
            }}

            >
                {
                    commentData.length > 300 ?
                        <em>{commentData.slice(0, 150) + "..."}</em>
                        : <em>"{commentData}"</em>
                }
                
            </p>
            <p style={{ margin: isMobile ? '' : '1rem 0 0 1.5rem', fontWeight: 'bold', color: '#D9D9D9' }} >{date}</p>
        </Card>
    )
}

export default CommentsCard;