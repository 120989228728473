import React, { useRef, useEffect, useMemo, useState } from 'react';
import { useIframeMessaging } from './hooks/useIframeMessaging';

const NewAppIFrame = ({ iFrameOrigin, iFramePagePath, title, authToken, userId, language, onLanguageChange }) => {
    const iframeRef = useRef(null);
    const [isMounted, setIsMounted] = useState(false);

    const initialMessages = useMemo(() => [
        { type: 'userId', value: userId },
        { type: 'auth', value: authToken },
        { type: 'language', value: language },
    ], [authToken, language, userId]);

    const { handleLoad } = useIframeMessaging(iframeRef, iFrameOrigin, initialMessages, iFramePagePath, language);
    
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        }
    }, []);
    
    if (!isMounted) {
        return null; // or a loading indicator
    }
    
    return (
        <iframe
            ref={iframeRef}
            src={`${iFrameOrigin}/${iFramePagePath}`}
            style={{ width: '100%', height: '94dvh', border: 'none', overflow: 'hidden'}}
            onLoad={handleLoad}
            title={title}
        />
    );
};

export default NewAppIFrame;