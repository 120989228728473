import {IdeasAndCommentsFilter} from "../BaseFilters/IdeasAndCommentsFilter";
import {AnalyticsIdeasFilter} from "../interfaces/AnalyticsIdeasFilter";
import React from "react";
import useIdeasFilter from "../../../hooks/useIdeasFilter";


class EmployeeFilter<T, K> extends IdeasAndCommentsFilter<T, K> implements AnalyticsIdeasFilter<T, K> {
    filter(value: T, setSelfId: React.Dispatch<React.SetStateAction<T>>, setterData: K): void {
    }

    public static createFilter = <T extends number, K> (): IdeasAndCommentsFilter<T, K> => new EmployeeFilter()

    public static getClassName = (): string => "EmployeeFilter";

    clear(): void {
    }

}


export { EmployeeFilter }
