import React from "react";
import withStyles from "@mui/styles/withStyles";

import Button from '@mui/material/Button';
import { ourOrange, borderRadiousButtons,standardRadious } from 'assets/jss/incept-sustainability-variables.jsx';




const styles = (theme) => ({
	root: ({ size, centered, nomargin=false, backGround=true, isAnalytics=false }) => ({
		width: (size === 'large' ? '250px' :'auto'
		),
		maxWidth: '100%',
		borderRadius: isAnalytics ? borderRadiousButtons:standardRadious,
		border: backGround ? "none":"1px solid rgb(255, 136, 85)",
		color: "white",
		cursor: "pointer",
		fontSize: size === 'large' ? "18px" : "14px",
		fontWeight: "200",
		padding: size === 'large' ? "5px 25px" : "2px 25px",
		margin: centered ? ((nomargin) ? "0" : "0 auto") : "16px 0",
		textTransform: size === 'large' ? 'uppercase' : "none",
		[theme.breakpoints.down('md')]: {
			marginBottom: "1rem",
		},

	}),
	contained: ({ lighten,backGround=true }) => ({
		opacity: lighten ? "0.4" : "1",
		backgroundColor: backGround ? ourOrange:"transparent",
		color: backGround ? "white":ourOrange,
		'&:hover, &:focus': {
			color: backGround ? "white":ourOrange,
			backgroundColor: backGround ? ourOrange:"white",
			opacity: "0.6"
		}
	})
});

class ActionButton extends React.Component {

	render() {
		const { children, classes, centered, lighten,nomargin=false,backGround=true,isAnalytics=false, style, disabled, ...other } = this.props;
		return (
			<Button
				variant={"contained"}
				classes={classes}
				style={style}
				disabled={disabled}
				{...other}>
				{children}
			</Button>
		)
	}
}

export default withStyles(styles)(ActionButton);