/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState, useEffect,forwardRef } from 'react';
import loadable from '@loadable/component';
import withStyles from "@mui/styles/withStyles";
import guidesStyle from "assets/jss/incept-custom-css.jsx";
import axios from "axios";
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import { isAuthenticated, is_g_comp, ourLightGreen } from "assets/jss/incept-sustainability-variables.jsx";
import {
	Card,
	CardBody,
} from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
//Translations
import { withTranslation, Trans,useTranslation } from 'react-i18next';
import PageWrapper from 'components/PageWrapper/PageWrapper';

const useStyles = (theme) => ({
	containerother: {
		paddingLeft: "40px",
		marginRight: "auto",
		marginLeft: "auto",
		[theme.breakpoints.down('md')]: {
			padding: "0 25px",
		},
	},
	wrapper: {
		/*marginTop: "-9.5rem",*/
		marginRight: "2rem",
		marginBottom: "40px",
		[theme.breakpoints.down('md')]: {
			margin: "0",
		},
	},
	titlepackage: {
		fontFamily: "Raleway,Helvetica,Arial,sans-serif",
		marginBottom: "1.5rem",
		margin: "0",
		paddingTop: "3rem",
		//marginLeft: "1.2rem"
	},
	introText: {
		borderRadius: "16px",
		padding: "10px 10px 0 10px",
		backgroundColor: ourLightGreen,
		//border: "1px solid rgba(172,208,146,.3)",
		height: "100%",
		"& p, strong": {
			fontSize: "1rem",
		},
		"& p": {
			fontSize: ".875rem",
		},
		marginBottom: "1.5rem",
	},
});

const ListModules = loadable(() => import('../Guides/ListModules'));
function KnowledgeBasePage(props) {
	const {match,classes,location, content} = props;
	const { id } = match.params;
	const [loading, setloading] = useState(true);
	const [nameKB, setnameKB] = useState("");
	const [all_modules, setall_modules] = useState([]);
	const { t, i18n } = useTranslation();
	const [introText, setIntroText] = useState(null);

	useEffect(() => {
		getkbmodules();
	  }, [id]);

	/*
	 * Getting info about guides
	 *
	 */
	const getkbmodules = async () => {
		setloading(true);
		if (!isAuthenticated()) {
			props.isExpired();
		}
		const token = localStorage.getItem("token");
		const insdata = {
			kbid: id
		};

		const authstr = "Bearer " + token;
		const useLang = i18n.language;
		axios
			.get(is_g_comp + "kbmodule?wpml_language=" + useLang, { headers: { Authorization: authstr } , params: insdata })
			.then((response) => {
				setnameKB(response.data.kbname);
				setall_modules(response.data.modules)
				setIntroText(response.data.knowledge_base_intro);
			})
			.catch((err) => {
				//this.error_ocurred("Error API "+is_g_comp+" "+err);
				//log.error("Error API "+is_g_comp+"kbmodule "+err);
			}).finally(() => {
				setloading(false);
			});
	}

	const button_word = (started,completed,t) => {
		return t("Open");
	}
	const headerText = nameKB ? t('Knowledge Base')+": "+nameKB : t('Knowledge Base');

	return (
		<PageWrapper 
			activeOverlay={loading} 
			spinner={<BounceLoader/>}>
				<div className={classes.containerother}>
					<div className={classes.wrapper}>
						<h2 className={classes.titlepackage}> {headerText} </h2>
						{
							// Dont show intro text if it is subsections
							!nameKB && introText && (
								<Card className={classes.introText}>
									<CardBody style={{ padding: "1rem"}}>
										{ReactHtmlParser(introText)}
									</CardBody>
								</Card>
							)
						}

						<ListModules 
							list_modules={all_modules}
							showHideModal={false}
							button_word={button_word}
							iskb={true}
							startModule={"/1"}
							type={"/KB"+id+"/"}
							t={t} 
						/>
					</div>
				</div>
		</PageWrapper>
	);
}
export default
withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
	  ...guidesStyle(theme)
    }),
    { withTheme: true },
  )(KnowledgeBasePage);
