import { forwardRef } from "react";

const FlipCardFront = forwardRef(({ background, title, image_cover, heightAllMobile, titleColor, onImageLoad }, ref) => {
    const isHexColor = background && background.substring(0, 1) === '#';
    const isTitle = title && title.length > 0;

    return (
        <div
            style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: ' center', background: isHexColor ? background : '' }}>
            {
                isHexColor || background === null ?
                    <h4 ref={isHexColor ? ref : null}
                        style={{ padding: '15px', color: `${titleColor}`, textAlign: 'center', width: '100%' }}>
                        {title}
                    </h4> :
                    <>
                        <img ref={isHexColor ? null : ref}
                            src={background}
                            alt="background"
                            style={{ width: `${heightAllMobile !== false ? 'auto' : '100%'}`, 
									height: `${(heightAllMobile || image_cover) ? '100%' : 'auto'}`, 
									objectFit: 'cover', 
									borderRadius: '20px' }}
                            loading="lazy"
                            onLoad={onImageLoad}
                        />
                        {isTitle ? 
                            <div style={{ position: 'absolute', alignSelf: 'center', width: '100%'}}>
                                <h3 ref={isHexColor ? ref : null}
                                    style={{ 
                                        color: `${titleColor}`, 
                                        textAlign: 'center', 
                                        width: '100%', 
                                        margin: 0, 
                                        display: 'flex', 
                                        justifyContent: 'center', 
                                        alignItems: 'center',
                                        }}>
                                    {title}
                                </h3>
                            </div> : null
                        }
                    </>
            }
        </div>
    );
});

export default FlipCardFront;
