import React from 'react';
import { Node ,ResponsiveScatterPlot } from '@nivo/scatterplot';

export default ({ data,legendX,legendY,keydata,colors,t /* see data tab */ }) => (
    <ResponsiveScatterPlot
        data={data}
        margin={{ top: 60, right: 140, bottom: 70, left: 90 }}
        xScale={{ type: 'linear', min: 0, max: '4' }}
        xFormat=">-.2f"
        yScale={{ type: 'linear', min: 0, max: '4' }}
        yFormat=">-.2f"
		nodeSize={10}
        blendMode="multiply"
        axisTop={null}
        axisRight={null}
		colors={(data,data2) => {return data.serieId !== "" ? colors[data.serieId] : 'green'}}
		//colors={(data) => data["color"]}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
			tickValues: 4,
            tickRotation: 0,
            legend: legendX,
            legendPosition: 'middle',
            legendOffset: 46
        }}
        axisLeft={{
            orient: 'left',
			tickValues: 4,
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: legendY,
            legendPosition: 'middle',
            legendOffset: -60
        }}
		annotations={
			keydata
		}
    />
);