import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
// @mui/icons-material

// core components
import cardAnalyticsStyle from "assets/jss/material-dashboard-react/components/cardAnalyticsStyle.jsx";

function Card({ ...props }) {
  const {
    classes,
    className,
    children,
    ...rest
  } = props;
  const cardClasses = classNames({
    [classes.card]: true,
    [className]: className !== undefined
  });
  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.node
};

export default withStyles(cardAnalyticsStyle)(Card);
