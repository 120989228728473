/*!
* Description: this file contains a component
*  that renders Drag and Drop Multiple 
*/

import React, { useState, useEffect } from 'react';
import withStyles from "@mui/styles/withStyles";
//style for all analytics
import { ourGreen20,ourGreen,ourOrange,standardRadious } from "assets/jss/incept-sustainability-variables.jsx";

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { styled } from '@stitches/react';
import Column from 'components/DragAndDrop/Column';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Container from "@mui/material/Container";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import Alert from '@mui/lab/Alert';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { withTranslation, Trans, useTranslation } from "react-i18next";

const useStyles = (theme) => ({
	buttonContainer: {
		textAlign: "end",	
		paddingRight: "0"
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "0",
		marginBottom: "20px"
	},
	gridItem: {
		paddingRight: '10px',
	}
});

const StyledColumns = styled('div', {
  width: '100%',
})

function DragAndDropMultiple(props) {
	const {classes} = props;
	const [columns, setColumns] = useState((typeof props.datasav2 !== "undefined") ? props.datasav2.content:props.exercise.draggableContext);
	const [columns_content, setColumnsContent] = useState([]);
	const [submittedanswer, setSubmittedAnswer] = useState((typeof props.datasav2 !== "undefined") ? true: false);
	const [lengthLettKeys, setLengthKeys] = useState((columns.main.list !== undefined) ? columns.main.list.length : 0);
	const [showAlert, setShowAlert] = useState(false);
	const columnsLength = Object.keys(props.exercise.draggableContext).length;
	const ipad = useMediaQuery({ query: `(max-width: 900px)` });
	const mobile = useMediaQuery({ query: `(max-width: 600px)` });
	let gridSize = (columnsLength <=3) ? 12 : 6;	
	gridSize = (ipad) ? 6 : gridSize;
	const { t, i18n } = useTranslation();

	useEffect(() => {
		setColumns((typeof props.datasav2 !== "undefined") ? props.datasav2.content:props.exercise.draggableContext);

	  }, []);

	useEffect(() => {
		const columns_content0 = [];
		Object.values(columns).slice(0).reverse().forEach((col,index) => {
			if (col.id!=="main") {
				columns_content0.push(
					<GridItem xs={12} sm={gridSize} md={gridSize} lg={gridSize}
					space={(mobile) ? 0:1} nospaceRight={true}
					>
					<Column 
						list={col.list}
						title={col.title}
						id={col.id}
						width={col.width}
						index={index}
						key={col.id}
					/>
					</GridItem>
				);
			}
		});
		setColumnsContent(columns_content0);
	}, [columns]);

	const onDragStart = () => {
		setShowAlert(false);
	};

	function clone(obj) {
		if (null == obj || "object" != typeof obj) return obj;
		var copy = obj.constructor();
		for (var attr in obj) {
		  if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
		}
		return copy;
	}

	const onDragEnd = ({ source, destination }) => {
		// Make sure we have a valid destination
		if (destination === undefined || destination === null) return null;
	
		// Make sure we're actually moving the item
		if (
		  source.droppableId === destination.droppableId &&
		  destination.index === source.index
		)
		  return null;
	
		// Set start and end variables
		let columnsClone = clone(columns);
		const start = columnsClone[source.droppableId];
		const end = columnsClone[destination.droppableId];

		setSubmittedAnswer(false)
	
		// If start is the same as end, we're in the same column
		if (start === end) {
		  // Move the item within the list
		  // Start by making a new list without the dragged item
		  const newList = start.list.filter(
			(_ ,idx) => idx !== source.index
		  )
	
		  // Then insert the item at the right location
		  newList.splice(destination.index, 0, start.list[source.index])
	
		  // Then create a new copy of the column object
		  const newCol = {
			id: start.id,
			title: start.title,
			width: start.width,
			list: newList
		  }
	
		  // Update the state
		  setColumns(state => ({ ...state, [newCol.id]: newCol }))
		} else {
		  // If start is different from end, we need to update multiple columns
		  // StandardFilter the start list like before
		  const newStartList = start.list.filter(
			(_, idx) => idx !== source.index
		  )
	
		  // Create a new start column
		  const newStartCol = {
			id: start.id,
			title: start.title,
			width: start.width,
			list: newStartList
		  }

		  if (start.id === "main") {
			setLengthKeys(lengthLettKeys - 1);
		  }
	
		  // Make a new end list array
		  const newEndList = end.list
	
		  // Insert the item into the end list
		  newEndList.splice(destination.index, 0, start.list[source.index])
	
		  // Create a new end column
		  const newEndCol = {
			id: end.id,
			title: end.title,
			width: end.width,
			list: newEndList
		  }

		  if (end.id === "main") {
			setLengthKeys(lengthLettKeys + 1);
		  }
	
		  // Update the state
		  setColumns(state => ({
			...state,
			[newStartCol.id]: newStartCol,
			[newEndCol.id]: newEndCol
		  }))
		}
		return null;
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		if (lengthLettKeys===0) {
			setSubmittedAnswer(true);
			props.save2("DragDrop_multiple", columns, props.inkey,
				props.exercise_id);
		}
		else {
			setShowAlert(true);
		}
	}
	

	return (
        <div style={(ipad || mobile) ? {paddingTop:"0px"}:{paddingTop:"50px"}}>
			<GridContainer spacing={0}>
				<GridItem xs={12} space={0} spaceNoPadding={true}>
					<DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
						<GridContainer>
						{columns.main!==undefined &&
							<GridItem xs={12} sm={12} md={4} lg={4} 
								space={0}
								otherclasses={(mobile) ? "": classes.noLeftPadding}>
								<Column 
								list={columns.main.list}
								title={columns.main.title}
								id={columns.main.id}
								width={columns.main.width}
								index={0}
								key={columns.main.id}
							/></GridItem>
						}
						<GridItem md={8} lg={8} nospaceRight={true}>
							<StyledColumns>
							<GridContainer>
									{columns_content}
							</GridContainer>
							</StyledColumns>
						</GridItem>
						</GridContainer>
					</DragDropContext>
				</GridItem>
				<GridItem sm={12} xs={12} md={12} space={0} spaceNoPadding={true} className={clsx("paddingExercise")} >
					<div className={classes.statusContainer}>
					{submittedanswer && <p>{t("Great! Your answer has been saved.")}</p>}
					</div>
					<Container className={classes.buttonContainer}>
						<ActionButton
							type="submit"
							nomargin={true}
							centered={true}
							onClick={(e) => handleSubmit(e)}
						>
							{t('Submit')}
						</ActionButton>
					</Container>
				</GridItem>
			</GridContainer>
			{showAlert && <Alert style={{marginTop:"20px"}} severity="error" >{"You need to drag and drop all key areas"}</Alert>}
        </div>     
	);
}
export default
withStyles(
    (theme) => ({
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(DragAndDropMultiple);
