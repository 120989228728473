import React from 'react';

const SwedbankTermsAndConditionsSV = () => {
    const styles = {
        container: {
            maxHeight: "100%",
            maxWidth: '800px',
            margin: '0 auto',
            padding: '32px',
            fontFamily: 'sans-serif',
            lineHeight: '1.75'
        },
        heading2: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            marginTop: '32px'
        },
        heading3: {
            fontSize: '1.125rem',
            fontWeight: 'bold',
            marginTop: '16px'
        },
        paragraph: {
            marginTop: '16px'
        },
        innerParagraph: {
            marginTop: '16px',
            marginLeft: '24px'
        },
        link: {
            color: '#3b82f6',
            textDecoration: 'underline'
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading2}>
                Information om behandlingen av dina personuppgifter
            </h2>
            <h3 style={styles.heading3}>
                1. Vi är personuppgiftsansvariga - hur kontaktar du oss?
            </h3>
            <p style={styles.paragraph}>
                Rambøll Management Consulting äger och driver Incept Sustainability Platform (härefter kallad ”Plattformen”).
            </p>
            <p style={styles.paragraph}>
                Plattformen görs tillgänglig för Swedbank, som kan göra Plattformen tillgänglig för organisationer med en aktiv koppling till fastighetsbranschen i Danmark, så att dessa organisationer också kan använda Plattformen.
            </p>
            <p style={styles.paragraph}>
                För att kunna tillhandahålla Plattformen för Swedbank behandlas dina personuppgifter i samband med användarregistrering. Denna text beskriver hur dina personuppgifter behandlas i samband med din användning av Plattformen.
            </p>
            <p style={styles.paragraph}>
                Rambøll Management Consulting är personuppgiftsansvarig för denna behandling, och du är välkommen att kontakta oss om du har frågor om behandlingen av dina personuppgifter. Du hittar kontaktuppgifter nedan.
            </p>
            <p style={styles.paragraph}>
                Rambøll Management Consulting
                <br />
                Hannemanns Allé 53
                <br />
                2300 Köpenhamn
                <br />
                CVR-nr: 60997918
                <br />
                Telefon: +45 5161 2506
                <br />
                Mail:{' '}
                <a href='mailto:PRIVACY@ramboll.com' style={styles.link}>
                    PRIVACY@ramboll.com
                </a>
            </p>
            <h3 style={styles.heading3}>
                2. Ändamål och rättslig grund för behandlingen av dina personuppgifter
            </h3>
            <p style={styles.paragraph}>
                Rambøll Management Consulting är personuppgiftsansvarig för de personuppgifter (namn, titel, organisation, e-postadress) du lämnar och registrerar när du skapar en användarprofil på Plattformen.
            </p>
            <p style={styles.paragraph}>
                Rambøll Management Consulting behandlar dina personuppgifter i syfte att administrera åtkomst till Plattformen och ge support på plattformen.
            </p>
            <p style={styles.paragraph}>
                Behandlingsgrunden är Rambøll Management Consultings legitima intresse att tillhandahålla en tjänst till Swedbank och din organisation, som får tillgång till plattformen (dataskyddsförordningens artikel 6, punkt 1, litra f).
            </p>
            <h3 style={styles.heading3}>
                3. Mottagare eller kategorier av mottagare
            </h3>
            <p style={styles.paragraph}>
                Våra leverantörer kan få teknisk tillgång till dina personuppgifter i samband med tillhandahållandet av deras tjänster som personuppgiftsbiträden åt oss. Det kan exempelvis handla om hosting och support av Plattformen. Dina personuppgifter delas inte med andra.
            </p>
            <h3 style={styles.heading3}>
                4. Överföring till mottagare i tredjeländer, inklusive internationella organisationer
            </h3>
            <p style={styles.paragraph}>
                Dina personuppgifter lagras och behandlas i allmänhet inom EU/EES.
            </p>
            <h3 style={styles.heading3}>5. Lagring av dina personuppgifter</h3>
            <p style={styles.paragraph}>
                Rambøll Management Consulting lagrar dina personuppgifter tills din användarprofil raderas på Plattformen, eller tills avtalet med Swedbank löper ut.
            </p>
            <p style={styles.paragraph}>
                Om din användarprofil raderas, kommer dina svar att behållas i anonymiserad form på Plattformen.
            </p>
            <h3 style={styles.heading3}>
                6. Automatiserade beslut, inklusive profilering
            </h3>
            <p style={styles.paragraph}>
                Automatiserade beslut, inklusive profilering, används inte för att behandla dina personuppgifter.
            </p>
            <h3 style={styles.heading3}>7. Dina rättigheter</h3>
            <p style={styles.paragraph}>
                Du har enligt dataskyddsförordningen ett antal rättigheter i förhållande till behandlingen av dina personuppgifter.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Rätt till tillgång</strong>
                <br />
                Du har rätt att få insyn i de uppgifter vi behandlar om dig och en rad ytterligare uppgifter.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Rätt till rättelse</strong>
                <br />
                Du har rätt att få felaktiga uppgifter om dig själv rättade.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Rätt till radering</strong>
                <br />
                I vissa fall har du rätt att få uppgifter om dig raderade innan den allmänna raderingen sker.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Rätt till begränsning av behandling</strong>
                <br />
                I vissa fall har du rätt att få behandlingen av dina personuppgifter begränsad. Om du har rätt till det får vi i framtiden endast behandla uppgifterna – förutom lagring – med ditt samtycke eller för att rättsliga anspråk kan fastställas, göras gällande eller försvaras, eller för att skydda en person eller viktiga samhällsintressen.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Rätt att invända</strong>
                <br />
                Du har i vissa fall rätt att invända mot vår eller laglig behandling av dina personuppgifter. Du kan även invända mot behandlingen av dina uppgifter för direktmarknadsföring.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Rätt till dataportabilitet</strong>
                <br />
                I vissa fall har du rätt att få dina personuppgifter i ett strukturerat, allmänt använt och maskinläsbart format samt att få dessa uppgifter överförda från en personuppgiftsansvarig till en annan utan hinder.
            </p>
            <p style={styles.paragraph}>
                Du kan läsa mer om dina rättigheter i Datatilsynets vägledning om de registrerades rättigheter, som du hittar på{' '}
                <a href='https://www.datatilsynet.dk' style={styles.link}>
                    www.datatilsynet.dk
                </a>
                .
            </p>
            <p style={styles.paragraph}>
                Om du vill utnyttja dina rättigheter ska du kontakta oss (se kontaktuppgifter under punkt 1).
            </p>
            <h3 style={styles.heading3}>
                8. Klagomål till Datatilsynet
            </h3>
            <p style={styles.paragraph}>
                Du har rätt att lämna in ett klagomål till Datatilsynet om du är missnöjd med hur dina personuppgifter behandlas. Du hittar Datatilsynets kontaktuppgifter på{' '}
                <a href='https://www.datatilsynet.dk' style={styles.link}>
                    www.datatilsynet.dk
                </a>
                .
            </p>
        </div>
    );
};

export default SwedbankTermsAndConditionsSV;
