import React, { createContext, ReactNode, useContext } from 'react';
import useCountriesData from '../hooks/useCountriesData';

const CountriesContext = createContext<ReturnType<typeof useCountriesData> | null>(null);

const CountriesProvider = ({ children }: { children: ReactNode }) => {
    const countriesData = useCountriesData();

    return (
        <CountriesContext.Provider value={countriesData}>
            {children}
        </CountriesContext.Provider>
    );
}

const useCountriesContext = () => {
    const context = useContext(CountriesContext);
    if (context === null) {
        throw new Error('useCountriesContext must be used within an CountriesProvider');
    }
    return context;
}


export {useCountriesContext, CountriesProvider, CountriesContext};
