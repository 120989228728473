const PLACEHOLDER = {
		imageURL:'/img/guides-hardcoded/SDGIcons/0main.png',
		title:'SDGs'
};

const SDGS = [
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/1nopoverty.png',
		title:'No poverty'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/2nohunger.png',
		title:'No hunger'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/3goodhealth.png',
		title:'Good health'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/4education.png',
		title:'Education'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/5genderequality.png',
		title:'Gender Equality'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/6cleanwater.png',
		title:'Clean Water'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/7cleanenergy.png',
		title:'Clean Energy'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/8work.png',
		title:'Work'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/9industry.png',
		title:'Industry'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/10inequality.png',
		title:'Inequality'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/11community.png',
		title:'Community'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/12compsumption.png',
		title:'Compsumption'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/13climate.png',
		title:'Climate'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/14lifewater.png',
		title:'Life Water'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/15lifeland.png',
		title:'Life Land'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/16peace.png',
		title:'Peace'
	},
	{
		imageURL:'/img/guides-hardcoded/SDGIcons/17partnership.png',
		title:'Partnership'
	}
];

module.exports = {
  SDGS,
  PLACEHOLDER
};