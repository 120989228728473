import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";
import { ourGreen } from 'assets/jss/incept-sustainability-variables.jsx';


const styles = {
	'dimensionsContainer' : {
		display:'flex',
		flexWrap:'wrap'
	},
};

const dimensionGroupStyles = {
	'box' : {
		border:`2px solid ${ourGreen}`,
		padding:'10px',
		flex:1,
		minWidth:'200px',
		margin:'10px',

	},
	'title' : {
		textAlign:'center',
		fontSize:'20px',
		fontWeight:'bold',
		paddingBottom:'30px'
	},
	statementsContainer : {
		display:'flex',
		flexWrap:'wrap'
	}
};

const dimensionStyles = {
	'box' : {
		border:`1px solid ${ourGreen}`,
		padding:'10px',
		margin:'10px',
		width:'calc(50% - 20px)'
	},
	'title' : {
		textAlign:'center',
		fontWeight:'bold',
		paddingBottom:'5px'
	},
	'statement' : {
		textAlign:'center'
	}
}

const DimensionGroup = withStyles(dimensionGroupStyles)(
	({classes, title, elements}) => {
		const e = elements.map(
			({title, statement}, index) => <Dimension key={index} title={title} statement={statement}/>
		);
		return (
			<div className={classes.box}>
				<div className={classes.title}>{title}</div>
				<div className={classes.statementsContainer}>{e}</div>
			</div>
		);
	}
);

const Dimension = withStyles(dimensionStyles)(
	({classes, title, statement}) => {
		return (
			<div className={classes.box}>
				<div className={classes.title}>
					{title}
				</div>
				<div className={classes.statement}>
					{statement}
				</div>
			</div>
		);
	}
);

class DimensionsFeedback extends React.Component {
	render(){
		const {classes, dimensions} = this.props;
		const d = dimensions.map(
			({title, elements},index) => <DimensionGroup className={classes.dimensionGroup} key={index} title={title} elements={elements}/>
		);
		return (
			<div className={classes.dimensionsContainer}>
				{d}
			</div>
		);
		
	}
}

export default withStyles(styles)(DimensionsFeedback);