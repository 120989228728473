/*!
* Description: this file contains the component for
*  the section "example"
* Components: GuideExampleInteractive
*/
import React,{useRef} from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@mui/material/Container';
import withStyles from "@mui/styles/withStyles";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { ourMediumGreen,standardRadious,ourBackgroGreen,ourOrange } from 'assets/jss/incept-sustainability-variables.jsx';
import { GuideCardTitle } from "components/GuidePage/GuideStyleCommon.js";
//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import Collapse from "@mui/material/Collapse";
//Translations
import { withTranslation } from 'react-i18next';
import ReactHtmlParser from "react-html-parser";
import arrowdown from 'assets/img/down-arrow.svg';
import checkmark from 'assets/img/checkmark-green.svg';
import greyCheckamrk from 'assets/img/checkmark-grey.svg';
import guideIcon from 'assets/img/information.svg';
import exampleicon from 'assets/img/exampleicon.png';
import { useMediaQuery } from 'react-responsive';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {flipCards_MobileFriendly} from "assets/js/flip-cards.js";

const styles = {
	root: {},
	cardExample: {
		marginBottom: "10px",
		minHeight: "110px",
		marginTop: "0"
	},
	cardBodyText: {
		marginTop: "20px",
		fontSize: ".95rem",
	},
	iconNavBack: {
		fontSize: '30px',

	},
	container: {
		"@media only screen and (max-width: 960px)": {
			padding: "0",
		}
	},
	buttonContainer: {
		textAlign: "end",
		margin: "5px 20px",
		"@media only screen and (max-width: 960px)": {
			margin: "10px 0"
		},
		"@media only screen and (max-width: 600px)": {
			textAlign: "center",
		},
	},
    blockContainer: {
        height: "100%",
		padding: "10px 0 0 0",
        cursor: "pointer"
    },
    gridItem: {
		textAlign: "center",
		paddingRight: '10px',
		paddingBottom: '50px',
	},
    subcontainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
        width:'100%',
		borderRadius: standardRadious,
		padding: "0.5rem 1rem",
		height: "100%",
        textAlign: "center"
    },
	pressedButton: {
        backgroundColor: ourMediumGreen,
    },
	nonpressed: {
		backgroundColor: ourBackgroGreen,
		"&:hover ": {
			border: '1px solid '+ourOrange,
		}
	},
    subcontainer0: {
        height: "100%"
    },
    answercontainer: {
		padding: "0"
	},
	exampleArea: {
		marginTop: "60px",
		"@media only screen and (max-width: 960px)": {
			padding: "0 20px 0 10px"
		},
	},
	iconDownArrow: {
		cursor: "pointer",
	},
	iconDownArrowDiv: {
		paddingTop: "30px",
		textAlign: "center",
	},
	carouselPer: {
		"& .carousel": {
			margin: "0 auto"
        },
        "& .carousel .control-next.control-arrow, .carousel .control-next.control-arrow:hover,.carousel .control-prev.control-arrow, .carousel .control-prev.control-arrow:hover": {
            background: ourOrange,
        },
		"& .carousel .thumb.selected, .carousel .thumb:hover": {
			border: "3px solid "+ourOrange,
		},
		"& .carousel .control-dots .dot": {
			boxShadow: "2px 2px 3px "+ourOrange,
		}
    },
	checkmark: {
		"@media only screen and (max-width: 960px)": {
			float: "right",
		},
	  },
    
};

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

class GuideExampleInteractive extends React.Component {

	constructor(props){
		super(props);
        const { boxes } = props;
		const { options } = boxes;
		this.state = {
			isdefaultView: (this.props.videoContent!="") ? true:false,
			defaultView: (this.props.defaultView==="Video content") ? false:true,
			loading: true,
            contentExample: options,
            contentBox: 0,
			oldcontentBox: 0,
			defaultFirst: (this.props.defaultFirst) ? true: false,
			showboxContent: (this.props.defaultFirst) ? true:false,
			changecontent: false
		}
	}

	componentDidMount() {
		flipCards_MobileFriendly();
	};

	componentDidUpdate() {
		if (this.state.contentBox!==this.state.oldcontentBox) {
			flipCards_MobileFriendly(false);
			this.setState({ oldcontentBox: this.state.contentBox });
		}
	};

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ defaultView: !this.state.defaultView });
	}

    handleExampleContent(e,n) {
		e.preventDefault();
		if (!this.state.defaultFirst && (this.state.contentBox===n || !this.state.showboxContent))
			this.setState({ showboxContent: !this.state.showboxContent });
		this.setState({ oldcontentBox: this.state.contentBox,contentBox: n,changecontent:n });
	}

	render() {
		const { classes, title,textContent,videoContent, t,saveresloc,
			isContent,isLastSection,showcard,openSection,datasav,smoothtrans } = this.props;
		let nmbuttons = this.state.contentExample.length;
		let gridnm = 3;

		if ((nmbuttons % 3) === 0) {
			gridnm = 4;
		}
		else if ((nmbuttons % 2) === 0) {
			gridnm = 6;
		}

		const tab_content = this.state.contentExample.map((item, index) => {
			let htmlContent = ReactHtmlParser(ReactHtmlParser(item.content));
			let is_carrousel = false;
			let carousel_content = "";
			if (htmlContent!=="" && htmlContent[0]!== undefined && htmlContent[0].ref!==undefined) {
				if (htmlContent[0].ref==="carousel_feature_is") {
					is_carrousel = true;
					carousel_content = htmlContent[0].props.children.filter(function(image,index) {
						if (image.type!=="img") {
						return false; // skip
						}
						return true;
					}).map(function(image,index) { return <div key={index} className={"carrouselImage_isxf"}>{image}</div> });
				}
			}
			return (
			<React.Fragment key={item.id}>
				<Container className={classes.answercontainer} key={item.id}>
					<Collapse in={(this.state.contentBox===index && this.state.showboxContent)} timeout={0}
					className={((this.state.contentBox===index && this.state.showboxContent)) ? classes.exampleArea:""} key={item.id}>
					{!is_carrousel && <span>{ReactHtmlParser(ReactHtmlParser(item.content))}</span>}
					{is_carrousel &&
						<Carousel 
						showArrows={true} 
						key={this.state.changecontent}
						showStatus={false}
						className={classes.carouselPer} 
						width={"1'0%"}
						>
							{carousel_content}
						</Carousel>
					}
					</Collapse>
				</Container>
			</React.Fragment>
			);
		});

		const grid_buttons = this.state.contentExample.map((item, index) => (
			<GridItem xs={12} sm={6} md={gridnm} spaceLeft={0} gridEvenPadding={1} key={index} className={classes.gridItem}>
				<div className={classes.blockContainer}>
					<div className={classes.subcontainer0} onClick={(e) => this.handleExampleContent(e,index)}>
						<div className={`${(this.state.contentBox===index && this.state.showboxContent) ? classes.pressedButton:classes.nonpressed} ${classes.subcontainer}`}>
						{ReactHtmlParser(ReactHtmlParser(item.button))}</div>
					</div>
				</div>
			</GridItem>
		));
		let stylePaddingBody = (showcard) ? {padding: "0 30px 30px 30px"}:{padding: "0 30px"};

		return (
			<div className={classes.root}>
				<Container className={classes.container}>
					<Card className={classes.cardExample}>
						<CardBody 
							style={ (this.props.isMobile) ? {padding: "0 0 0 30px"}:stylePaddingBody}		>
							<GridContainer justifyContent="center" 
								style={
									{
										alignItems: "center",
										justifyContent: "space-between",
										minHeight: "110px",
										flexWrap: (this.props.isMobile) ? "wrap" : "nowrap",
										flexDirection: (this.props.isMobile) ? "column" : "row",
										gap: (this.props.isMobile) ? "1rem" : undefined,
										margin: (this.props.isMobile) ? "20px 0" : "auto",
									}}
							>
							<GridItem style={{ width: "100%" }} spaceLeft={0}>
								<GridContainer 
									alignItems="center"
									justifyContent="space-between"
									style={this.props.isMobile ? { width: "100%", flexDirection: "row"}:{ width: "100%", flexDirection: "row", flexWrap: "nowrap" }}
								>
								<GuideCardTitle style={{marginBottom:"0"}}>
									<GridContainer
										style={{
											alignItems:"center",
											flexDirection: "row",
											flexWrap: "nowrap",
											gap: "1rem",
											flexGrow: "1",
											width: "100%"
										}}
									>
										<GridItem  space={0} spaceNoPadding={true}>
											<img
												src={(typeof datasav !== "undefined") ? checkmark : greyCheckamrk}
												className={classes.dotimage2}
												width="80px"
												style={{paddingRight:"20px"}}
												alt='Information'
											/>
										</GridItem>
										<GridItem 
											md="auto"
											lg="auto"
											spaceLeft={0}
											style={{ flexGrow: "1", width: "100%", maxWidth: "100%" }}
										>
											<span>{
											<GuideCardTitle>{(isContent) ? "":t('Example')+":"} {title}
											</GuideCardTitle>}</span>
										</GridItem>
									</GridContainer>
								</GuideCardTitle>

							<GridItem xs={12} md={3} spaceLeft={0} 
									style={this.props.isMobile ? {cursor:"pointer",
									marginTop:`${showcard ? "20px":"10px"}`}:
									{cursor:"pointer"}}>
								<div className={`${classes.iconDownArrowDiv}`}
                                            style={(title=="") ? 
                                                {marginBottom: "12.8px",paddingTop:"7px",textAlign:"right",display:"flex"} :
                                                {paddingTop:"0",textAlign:`${this.props.isMobile ? "center":"right"}`}}
                                        >
										{showcard && this.state.isdefaultView && 
										<ActionButton 
											onClick={(e) => this.handleSubmit(e)} 
											type="submit" 
											centered={true}
											style={(this.state.isdefaultView && showcard) ? 
												{width:"auto",
												marginRight: `${this.props.isMobile ? "0":"20px"}`,
												marginLeft: `${this.props.isMobile ? "30px":"0"}`,
												marginBottom:`${this.props.isMobile ? "20px":"0"}`}:
												{visibility:"hidden"}}
										>
											{(this.state.defaultView) && t('See video instead')}
											{(!this.state.defaultView) && t('See text instead')}
										</ActionButton>	}
								</div>
							</GridItem>
							</GridContainer>
							</GridItem>
							<img
								src={arrowdown}
								style={{
									transform: (showcard) ? "rotate(-180deg)": undefined,
									display: "block",
									margin: `${this.props.isMobile ? "0":"0 auto"}`,
									flexGrow: 0,
									marginLeft:`${this.props.isMobile ? "-30px":"0"}`
								}}
								alt={t("Go to next section")}
								title={t("Go to next section")}
								onClick={openSection}
								className={classes.iconDownArrow}
							/>
						</GridContainer>

							<Collapse in={showcard} 
							style={{margin:`${this.props.isMobile ? "10px 0 20px -20px":"auto"}`}}
							addEndListener={() => {
								if (showcard) {
									saveresloc();
									setTimeout(() => {smoothtrans.scrollIntoView({behavior: "smooth"})}, 100);
								}
							}} >
							<div className={classes.cardBodyText}>
							{(this.state.defaultView) && <div>
								<div>{textContent}</div>
                            <GridContainer container justifyContent="center" alignItems="stretch">
                                {grid_buttons}
								{tab_content}
							</GridContainer></div>}
							{(!this.state.defaultView) && <div className={classes.cardBodyText}>
								{videoContent}</div>}	
								</div>
						{/*!isLastSection && <div onClick={goNextSection} className={`${classes.iconDownArrowDiv} moveArrow`}>
							<img
								src={arrowdown}
								alt="Go to next section"
								title="Go to next section"
								
								className={classes.iconDownArrow}
							/>
							</div>*/}
						</Collapse>
						</CardBody>
					</Card>
				</Container>
			</div>
		);
	}
}

GuideExampleInteractive.defaultProps = {
	children: (<p>Here comes the content for this Example</p>),
	title: 'Example',
};

export default withTranslation()(withMyHook(withStyles(styles)(GuideExampleInteractive)));