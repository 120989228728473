/*!
 * Description: this file contains a component
 *  that renders a disabled lookalike button that contains numbers or text
 */

import React, { useState,useEffect} from "react";
import withStyles from "@mui/styles/withStyles";
import guidesStyle from "assets/jss/incept-custom-css.jsx";
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import {
	isAuthenticated,
	ourBlue,
	ourGreen,
	ourYellow,
	actions_post
} from "assets/jss/incept-sustainability-variables.jsx";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";
import PieCard from "views/Analytics/AnalyticsCompany/PieCard.jsx";
import PieaRankingCard from "views/Analytics/AnalyticsCompany/PieaRankingCard.jsx";
import StackedBarCard from "views/Analytics/AnalyticsCompany/StackedBarCard.jsx";
import FreeTextCard from "views/Analytics/AnalyticsCompany/FreeTextCard.jsx";
import ScatterChartCard from "views/Analytics/AnalyticsCompany/ScatterChartCard.jsx";
import { IconButton, Grid, Divider } from "@mui/material";
import icon7 from "assets/img/icon7.png";
import TextareaAutosize from 'react-textarea-autosize';
import calendar from "assets/img/calendar.png";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import MenuItem from "@mui/material/MenuItem";
import { default as CustomModal } from "components/Modal/Modal.jsx";
import FakeButton from "components/CustomButtons/FakeButton";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
//Translations
import { withTranslation, Trans, useTranslation } from "react-i18next";
import List from "@mui/material/List";
import DropDown from "components/CustomInput/DropDown.jsx";
import DatePicker from 'react-date-picker';
import loadinggif from "assets/img/loading.gif";

const useStyles = (theme) => ({
	box: {
		height: "20px",
		width: "20px",
		marginRight: "10px",
		display: "inline-block",
		verticalAlign: "middle",
	},
	green: {
		backgroundColor: ourGreen,
	},
	yellow: {
		backgroundColor: ourYellow,
	},
	cardMargin: {
		width: "100%",
		marginBottom: "50px",
	},
	moduleTitle: {
		marginTop: "20px",
		marginBottom: "15px",
		fontWeight: "bold",
		[theme.breakpoints.down('md')]: {
			marginTop: "1rem",
		},
	},
	modalCancelButton: {
		right: "0",
		position: "relative",
		zIndex: "3",
		padding: "0px",
		float: "right",
	},
	modalBox: {
		"& .modal-content": {
			borderRadius: "2rem",
		},
	},
	sourceBox: {
		maxHeight: "150px",
		overflow: "auto",
		margin: "0 0 30px"
	},
	lineid: {
		textOverflow: "ellipsis",
		"@supports (-webkit-line-clamp: 2)": {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "initial",
			display: "-webkit-box",
			WebkitLineClamp: "2",
			WebkitBoxOrient: "vertical"
		  }
	},
	textarea1: {
		backgroundColor: "rgba(255,255,255,0.5)", 
		position: "relative", 
		marginBottom: "20px", 
		borderRadius: "16px", 
		borderColor: "grey", 
		resize: "none"
	  }
});

function AnalyticsCompany_card(props) {
	const { classes, content } = props;
	const [load, setLoad] = useState(false);
	const { t, i18n } = useTranslation();
	const [showAboutModal, setshowAboutModal] = useState(false);
	const [textAbout, settextAbout] = useState(<div></div>);
	const [startDate, setstartDate] = useState(new Date());
	const [marketvalue, setMarketvalue] = useState("All");
	const [filterNempty, setFilterNEmpty] = useState(
		new Array(props.answersCompany.length).fill(false)
	);
	const [modalactionsdiv, setmodalactionsdiv] = useState("");
	const [currentActivity, setcurrentActivity] = useState(0);
	const [currentActivityIndex, setcurrentActivityIndex] = useState(0);
	var d = new Date();
	const postd = new Date().setDate(d.getDate() + 30);
	const [modalactioninfo, setmodalactioninfo] = useState({
		market: 0,
		responsible: 0,
		compltedby: new Date(postd)
	});
	let modalactionTitle = React.createRef()
	let modalactionDescription = React.createRef();

	useEffect(() => {
		if (modalactionsdiv!=="") {
			//open modal
			props.setaddActionModalValue(!props.addActionModalValue);
		}
		
	}, [modalactionsdiv]);

	const updatefilterEmpty = (ind) => {
		// 👇️ passing function to setData method
		setFilterNEmpty((prevState) => {
			const newState = prevState.map((obj, index) => {
				if (index === ind) {
					return !obj;
				}

				return obj;
			});

			return newState;
		});
	};
	//modal for add action page
	const addActionModal = (title,activityid,index) => {
		//open modal
		//setaddActionModalValue(!addActionModalValue);
		const contentDiv = <div style={{color:ourBlue,textAlign:"center",marginTop:"20px"}}>
							<b>{title}</b><br></br>
							</div>;
		setmodalactionsdiv(contentDiv);
		setcurrentActivity(activityid);
		setcurrentActivityIndex(index);
	};

	//dropdwn
	const onChangeMarket = (e) => {
		setMarketvalue(e.target.value);
	};

	const onChangeAction = (event, type) => {
		if (type === "Market") {
			let {market, ...restItem} = modalactioninfo;
			setmodalactioninfo({...restItem, market: event.target.value});
		} else if (type === "Responsible") {
			let {responsible, ...restItem} = modalactioninfo;
			setmodalactioninfo({...restItem, responsible: event.target.value});
		} else if (type === "completedby") {
			let {compltedby, ...restItem} = modalactioninfo;
			setmodalactioninfo({...restItem, compltedby: event});
		}
	};

	const showHideModal = (typeActivity) => {
		try {
			setshowAboutModal(!showAboutModal);
			let text = (<div>
				<b>Answers: </b>all employees that submitted an answer (including empty submissions).<br></br>
				<b>Empty submissions: </b>all employees that submitted empty answers.<br></br>
				<b>Non empty submissions: </b>(Answers - Empty submissions)<br></br></div>);
			if (typeActivity === "pie") {
				text = (<div>{text}<h4></h4><p>
					Each half pie chart and bar chart shows the percentage of how many employees selected that option,
					of all the employees that did not submit empty.<br></br>
					Have in mind that some activities require users to select more than 1 option. </p>
				</div>)
			}
			else if (typeActivity === "bar") {
				text = (<div>{text}<h4></h4><p>
					Each bar chart shows the percentage of employees that dragged and dropped an option to right or left box
					(green and yellow legends).<br></br>
					The grey area of the bar represents the percentage of employees that did not choose this option at all. </p>
				</div>)
			}
			settextAbout(text);
		}
		catch (error) {
			throw new Error("Component: AnalyticsCompany Card - showHideModal");
		}
	};

	const CustomInputActionDate = React.forwardRef((props, ref) => {
		let auxx = modalactioninfo.compltedby.toISOString().replace(/T.*/,'').split('-').reverse().join('-')
		return (
			<span onClick={props.onClick}><span className={classes.datepickerinput} style={{verticalAlign:"middle"}}>{auxx}</span><img
			src={calendar}
			alt="calendar icon"
			style={{ width: "20px", height: "20px" }}
		/></span>
		);
	  });

	const submitNewAction = (e) => {
		props.submitNewAction(modalactionTitle.current.value,modalactionDescription.current.value,modalactioninfo,currentActivity,currentActivityIndex);
	}

	const answerCard = props.answersCompany.map((data_content, index) => (
		<div style={{ width: "100%" }} key={data_content.exerciseid + "CC" + index} id={data_content.exerciseid}>
			{(() => {
				if (data_content.answers!==null && data_content.answers.length > 0 || data_content.empty > 0) {
					const newTo = {
						pathname: "/admin/my_actions",
						search: "?activity=" + data_content.exerciseid
					};
					if (data_content.type_chart === "pie") {						
						return <div className={classes.cardMargin}>
							<PieCard
								title={data_content.title}
								index={index}
								includeEmpty={filterNempty[index]}
								updatefilterEmpty={updatefilterEmpty}
								filter={props.filter}
								addActionModal={addActionModal}
								data={data_content.answers}
								pempty={data_content.pempty}
								showHideModal={showHideModal}
								customvalues={data_content.customanswers !== undefined ? data_content.customanswers : []}
								pcustom={data_content.customanswers !== undefined ? data_content.pcustom : 0}
								ncustom={data_content.customanswers !== undefined ? data_content.ncustom : 0}
								exerciseid={data_content.exerciseid}
								isCustomManager={props.isCustomManager}
							>
								<GridContainer>
									<GridItem space={0} style={{ paddingRight: "20px" }} lg={data_content.isaction ? 9 : 12}>
										<div>
											<span style={{ fontWeight: "bold" }}>{"Module: "}</span>
											<span>{data_content.module_name}</span>
										</div>
										<div>
											<span style={{ fontWeight: "bold" }}>{"Description: "}</span>
											<span>{data_content.description}</span>
										</div>
										<div style={{ marginTop: "20px" }}>
											<span style={{ fontWeight: "bold" }}>{"Answers: "}</span>
											<span style={{ color: ourBlue }}>
												{(data_content.answers.length > 0) ? data_content.answers[0].totalUsers : data_content.empty}
											</span>
											{" ("}<span style={{ color: ourBlue }}>{data_content.pactive + "% of all active users for this module"}</span>)
										</div>
										<div>
											<span style={{ fontWeight: "bold" }}>{"Empty submissions: "}</span>
											<span style={{ color: ourBlue }}>
												{data_content.empty + " of the " + ((data_content.answers.length > 0) ? data_content.answers[0].totalUsers : data_content.empty) + " "}
											</span>(<span style={{ color: ourBlue }}>{data_content.pempty + "%"}</span>)
										</div>
										<div>
											<span style={{ fontWeight: "bold" }}>{"Non empty submissions: "}</span>
											<span style={{ color: ourBlue }}>
												{(data_content.answers.length > 0) ? data_content.answers[0].totalUsers_nempty : 0}
											</span>
										</div>
									</GridItem>
									{(!props.isCustomManager && (data_content.isaction===1)) && <GridItem space={0} style={{ textAlign: "right" }} lg={data_content.isaction ? 3 : 0}>
										<Link target="_blank" to={newTo} >
											<FakeButton
												color={ourGreen}
												textColor={"rgba(0, 0, 0, 0.87)"}
												style={{
													fontWeight: "200",
													padding: "2px 20px 2px 20px",
													marginLeft: "10px",
													marginBottom: "5px",
												}}
												disabledb={false}
												content={"Converted to Action"}
												labelIconCheck={true}
											/>
										</Link>
									</GridItem>}
								</GridContainer>
							</PieCard></div>;
					}
					else if (data_content.type_chart === "bar") {
						return <div className={classes.cardMargin}><StackedBarCard
						title={data_content.title}
						index={index}
						includeEmpty={filterNempty[index]}
						updatefilterEmpty={updatefilterEmpty}
						addActionModal={addActionModal}
						data={data_content.answers}
						keysTitle={data_content.keys_title}
						keysId={data_content.keys_id}
						legends={data_content.legends}
						showHideModal={showHideModal}
						exerciseid={data_content.exerciseid}
						isCustomManager={props.isCustomManager}
						type={data_content.type_bar}
						term={data_content.term_graph}
					>
							<GridContainer>
								<GridItem space={0} style={{ paddingRight: "20px" }} lg={data_content.isaction ? 9 : 12}>
									<div>
										<span style={{ fontWeight: "bold" }}>{"Module: "}</span>
										<span>{data_content.module_name}</span>
									</div>
									<div>
										<span style={{ fontWeight: "bold" }}>{"Description: "}</span>
										<span>{data_content.description}</span>
									</div>
									<div style={{ marginTop: "20px" }}>
										<span style={{ fontWeight: "bold" }}>{"Answers: "}</span>
										<span style={{ color: ourBlue }}>
											{data_content.totalanswers}
										</span>
										{" ("}<span style={{ color: ourBlue }}>{data_content.pactive + "% of all active users for this module"}</span>)
									</div>
									<div>
										<span style={{ fontWeight: "bold" }}>{"Empty submissions: "}</span>
										<span style={{ color: ourBlue }}>
											{data_content.empty + " of the " + data_content.totalanswers+" "}
										</span>(<span style={{ color: ourBlue }}>{data_content.pempty + "%"}</span>)
									</div>
									<div style={{ marginBottom: "50px" }}>
										<span style={{ fontWeight: "bold" }}>{"Non empty submissions: "}</span>
										<span style={{ color: ourBlue }}>
											{(data_content.totalanswers > 0) ? parseInt(data_content.totalanswers) - parseInt(data_content.empty) : 0}
										</span>
									</div>
								</GridItem>
								{(!props.isCustomManager && (data_content.isaction===1)) && <GridItem space={0} style={{ textAlign: "right" }} lg={data_content.isaction ? 3 : 0}>
									<Link target="_blank" to={newTo} >
										<FakeButton
											color={ourGreen}
											textColor={"rgba(0, 0, 0, 0.87)"}
											style={{
												fontWeight: "200",
												padding: "2px 20px 2px 20px",
												marginLeft: "10px",
												marginBottom: "5px",
											}}
											disabledb={false}
											content={"Converted to Action"}
											labelIconCheck={true}
										/>
									</Link>
								</GridItem>}
							</GridContainer>
						</StackedBarCard></div>;
					}
					else if (data_content.type_chart === "pie_ranking") {
						return <div className={classes.cardMargin}><PieaRankingCard
							data={data_content.answers}
							title={data_content.title}
							empty={data_content.empty}
							showHideModal={showHideModal}
							titlesGraph={data_content.graphTitles}
						>
							<div>
								<span style={{ fontWeight: "bold" }}>{"Module: "}</span>
								<span>{data_content.module_name}</span>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>{"Description: "}</span>
								<span>{data_content.description}</span>
							</div>
							<div style={{ marginTop: "20px" }}>
								<span style={{ fontWeight: "bold" }}>{"Answers: "}</span>
								<span style={{ color: ourBlue }}>
									{data_content.totalusers}
								</span>
								{" ("}<span style={{ color: ourBlue }}>{data_content.pactive + "% of all active users for this module"}</span>)
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>{"Empty submissions: "}</span>
								<span style={{ color: ourBlue }}>
									{data_content.empty + " of the " + (data_content.totalusers) + " "}
								</span>(<span style={{ color: ourBlue }}>{data_content.pempty + "%"}</span>)
							</div>
							<div style={{ marginBottom: "50px" }}>
								<span style={{ fontWeight: "bold" }}>{"Non empty submissions: "}</span>
								<span style={{ color: ourBlue }}>
									{parseInt(data_content.totalusers) - parseInt(data_content.empty)}
								</span>
							</div>
						</PieaRankingCard></div>;
					}
					else if (data_content.type_chart === "scatter_plot") {
						return <div className={classes.cardMargin}>
							<ScatterChartCard 
							title={data_content.title}
							data={data_content.answers}
							colors={data_content.colors}
							legends={data_content.labels}
							boxes={data_content.boxesNames}
							annotations={data_content.annotations}>
								<div>
									<span style={{ fontWeight: "bold" }}>{"Module: "}</span>
									<span>{data_content.module_name}</span>
								</div>
								<div>
									<span style={{ fontWeight: "bold" }}>{"Description: "}</span>
									<span>{data_content.description}</span>
								</div>
								<div style={{ marginTop: "20px" }}>
									<span style={{ fontWeight: "bold" }}>{"Answers: "}</span>
									<span style={{ color: ourBlue }}>
										{(data_content.nanswers > 0) ? data_content.nanswers : data_content.nempty}
									</span>
									{" ("}<span style={{ color: ourBlue }}>{data_content.pactive + "% of all active users for this module"}</span>)
								</div>
								<div>
									<span style={{ fontWeight: "bold" }}>{"Empty submissions: "}</span>
									<span style={{ color: ourBlue }}>
										{data_content.nempty + " of the " + ((data_content.nanswers > 0) ? data_content.nanswers : data_content.nempty) + " "}
									</span>(<span style={{ color: ourBlue }}>{data_content.pempty + "%"}</span>)
								</div>
								<div>
									<span style={{ fontWeight: "bold" }}>{"Non empty submissions: "}</span>
									<span style={{ color: ourBlue }}>
										{(data_content.nanswers > 0) ? parseInt(data_content.nanswers) - parseInt(data_content.nempty) : 0}
									</span>
								</div>
							</ScatterChartCard>
						</div>;
					}
					else {
						return <div className={classes.cardMargin}><FreeTextCard
							title={data_content.title}
							data={data_content.answers}
							nitems={data_content.nanswers}
							showHideModal={showHideModal}
							exerciseid={data_content.exerciseid}
							filterData={props.filterData}
							filter={props.filter}
							generalPage={true}
						>
							<div>
								<span style={{ fontWeight: "bold" }}>{"Module: "}</span>
								<span>{data_content.module_name}</span>
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>{"Description: "}</span>
								<span>{data_content.description}</span>
							</div>
							<div style={{ marginTop: "20px" }}>
								<span style={{ fontWeight: "bold" }}>{"Answers: "}</span>
								<span style={{ color: ourBlue }}>
									{(data_content.nanswers > 0) ? data_content.nanswers : data_content.nempty}
								</span>
								{" ("}<span style={{ color: ourBlue }}>{data_content.pactive + "% of all active users for this module"}</span>)
							</div>
							<div>
								<span style={{ fontWeight: "bold" }}>{"Empty submissions: "}</span>
								<span style={{ color: ourBlue }}>
									{data_content.nempty + " of the " + ((data_content.nanswers > 0) ? data_content.nanswers : data_content.nempty) + " "}
								</span>(<span style={{ color: ourBlue }}>{data_content.pempty + "%"}</span>)
							</div>
							<div style={{ marginBottom: "50px" }}>
								<span style={{ fontWeight: "bold" }}>{"Non empty submissions: "}</span>
								<span style={{ color: ourBlue }}>
									{(data_content.nanswers > 0) ? parseInt(data_content.nanswers) - parseInt(data_content.nempty) : 0}
								</span>
							</div>
						</FreeTextCard></div>;
					}
				}
				else {
					return <div className={classes.cardMargin}>
						<CardAnalytics>
							<CardAnalyticsHeader title={data_content.title}>
							</CardAnalyticsHeader>
							<CardAnalyticsBody>
								<div>
									<span style={{ fontWeight: "bold" }}>{"Module: "}</span>
									<span>{data_content.module_name}</span>
								</div>
								<div>
									<span style={{ fontWeight: "bold" }}>{"Description: "}</span>
									<span>{data_content.description}</span>
								</div>
								<div style={{ marginTop: "20px" }}>
									<span style={{ fontWeight: "bold" }}>{"Answers: "}</span>
									<span style={{ color: ourBlue }}>
										{(data_content.nanswers > 0) ? data_content.nanswers : data_content.nempty}
									</span>
									{" ("}<span style={{ color: ourBlue }}>{data_content.pactive + "% of all active users for this module"}</span>)
								</div>
								<div style={{ marginBottom: "50px" }}>
									<span style={{ fontWeight: "bold" }}>{"Empty submissions: "}</span>
									<span style={{ color: ourBlue }}>
										{(data_content.nempty !== undefined) ? data_content.nempty + " of the " + ((data_content.nanswers > 0) ? data_content.nanswers : data_content.nempty) + " " : "0 "}
									</span>(<span style={{ color: ourBlue }}>{data_content.pempty + "%"}</span>)
								</div>
								<div style={{ margin: "50px 0 50px 0", textAlign: "center" }}>
									<span>{t("No results")}</span>
								</div>
							</CardAnalyticsBody>
						</CardAnalytics>
					</div>;
				}
			})()
			}
		</div>
	));

	return (
        <GridContainer>
			{answerCard}
			<Modal
				id="AnalyticsCard3"
				show={showAboutModal}
				onHide={() => setshowAboutModal(false)}
				centered
				size="lg"
				className={classes.modalBox}
			>
				<Modal.Body>
					<IconButton
                        onClick={() => setshowAboutModal(false)}
                        className={classes.modalCancelButton}
                        size="large">
						<img src={icon7} alt="cancel icon" width="40" height="40" />
					</IconButton>
					<div style={{ margin: "20px" }}>
						<h4 style={{ marginBottom: "15px" }}>
							{"How are the stats calculated?"}
						</h4>
						<GridContainer>
							<GridItem space={0} style={{ paddingRight: "20px" }}>
								{textAbout}
							</GridItem>
						</GridContainer>
					</div>
				</Modal.Body>
			</Modal>
			<Modal
					id="4"
					show={props.addActionModalValue}
					disabled={props.submittingnewaction}
					onHide={() => {
						props.setaddActionModalValue(false);
					}}
					centered
					size="xl"
					className={classes.modalBox}
				>
					<Modal.Body>
						<IconButton
                            onClick={() => {
								props.setaddActionModalValue(false);
							}}
                            disabled={props.submittingnewaction}
                            className={classes.modalCancelButton}
                            size="large">
							<img
								src={icon7}
								alt="cancel icon"
								style={{ width: "35px", height: "35px" }}
							/>
						</IconButton>
						<div>
							<h5
								style={{
									fontWeight: "bold",
									marginTop: "20px",
									paddingLeft: "20px",
								}}
							>
								{t("Create Action")}
							</h5>
							<Divider />
							<div style={{ padding: "0 20px" }}>
								<Form>
									<div
										style={{
											paddingTop: "10px",
											marginBottom: "0px",
											fontWeight: 700,
										}}
									>
										<h4 className={classes.formTitle} style={{margin:"5px 0"}}>{"From the following activity: "}</h4>
									</div>
									<div className={classes.sourceBox} >
									<List>{modalactionsdiv}</List>
									</div>
									<div style={{margin:"25px 0 0 0"}}>
										<h4 className={classes.formTitle} style={{margin:"5px 0"}}>{"Title: "}</h4>
										<Form.Control 
											as="textarea" 
											rows="1" 
											ref={modalactionTitle}
											className={classes.textarea1} />
									</div>
									<div style={{margin:"25px 0 0 0"}}>
										<h4 className={classes.formTitle} style={{margin:"5px 0"}}>{"Description: "}</h4>
										<TextareaAutosize 
											minRows="2"
											maxRows="3"
											className={classes.textareaAutoSize}
											ref={modalactionDescription}
											style={{borderRadius:"0"}}
											/>
									</div>
									<div style={{margin:"25px 0 0 0"}}>
										<span className={classes.formTitle+" gMedium"} style={{margin:"5px 0 0"}}>{"Action for: "}</span>
										<span>
											{" "}
											<DropDown
												value={modalactioninfo.market}
												analytics={true}
												paddingShort={true}
												fullWidth={false}
												otherClasses={classes.doubleLineDD}
												style={{
													width: "200px",
													color: "#009df0",
													fontWeight: "700",
												}}
												onChange={(e) => onChangeAction(e,"Market")}
											>
												<MenuItem key={0} value={0}>
														{"All"}
												</MenuItem>
												{props.departments.map((item) => (
													<MenuItem key={item.id} value={parseInt(item.id)}>
														{item.name}
													</MenuItem>
												))}
											</DropDown>
										</span>
									</div>
									<div style={{margin:"25px 0 0 0"}}>
										<span className={classes.formTitle+" gMedium"} >{"Responsible: "}</span>
										<span>
											{" "}
											<DropDown
												value={modalactioninfo.responsible}
												analytics={true}
												paddingShort={true}
												fullWidth={false}
												otherClasses={classes.doubleLineDD}
												style={{
													width: "200px",
													color: "#009df0",
													fontWeight: "700",
												}}
											onChange={(e) => onChangeAction(e,"Responsible")}>
												 <MenuItem key={0} value={0}>
												{"Unassigned"}
												</MenuItem> 
												 {Object.keys(props.managers).map(key => 
													<MenuItem key={key} value={key} name={key}>
													{props.managers[key].first_name+" "+props.managers[key].last_name}
													</MenuItem>
												)}
											</DropDown>
										</span>
									</div>
									<div style={{ display: "flex",margin:"25px 0 0 0" ,alignItems:"center"}}>
										<span className={classes.formTitle+" gMedium"}>{"Complete by: "}</span>
										<span className={classes.datepicker}>
											<DatePicker 
											className={classes.datepickercl}
											format={"dd-MM-y"}
											onChange={(date) => onChangeAction(date,"completedby")}
											value={modalactioninfo.compltedby} />
										</span>
									</div>
									<div>
										<ActionButton
											onClick={(e) => submitNewAction(e)}
											style={{ float: "right" }}
											disabled={props.submittingnewaction}
											className={classes.submitButton}
										>
											{t("Submit")}
										</ActionButton>
									</div>
								</Form>
								{props.submittingnewaction && (
									<div style={{ paddingTop: "20px", textAlign: "right" }}>
										<img
											style={{marginRight:"20px"}}
											src={loadinggif}
											width="25"
											alt=""
										/>
									</div>
								)}
							</div>
						</div>
					</Modal.Body>
				</Modal>
		</GridContainer>
    );
}
export default withStyles(
	(theme) => ({
		...analyticsStyle(theme),
		...useStyles(theme),
		...guidesStyle(theme)
	}),
	{ withTheme: true }
)(AnalyticsCompany_card);
