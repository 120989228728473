import { useState, useRef } from "react";
import {IdeaProp} from "./types/UseIdeasTypes";

const useIdeasFilter = () => {
    const [ideasInfo, setIdeasInfo] = useState<IdeaProp>({
        ideas: [],
        active: [],
        nideas: 0,
        nparticipants: 0,
        labelsideas: [],
        departmentStats: {},
        checked: [],
        loadingfirst: true,
        listCleared: true,
    });
    const [currentDepartment, setCurrentDepartment] = useState(0);
    const [currentModule, setCurrentModule] = useState("0");
    const [currentLabel, setCurrentLabel] = useState<string>("-1");
    const [listItems, setListItems] = useState<any[]>([]);
    const [ideasFiltered, setIdeasFiltered] = useState<any[]>([]);
    const [originalList, setOriginalList] = useState<any[]>([]);
    const [searchVal, setSearchVal] = useState("");
    const [showactiveEmployees, setShowActiveEmployees] = useState(true);
    const [managers, setManagers] = useState([]);
    const [filterFlags, setFilterFlags] = useState({ department: 0, country: 0 });
    const [currentCountry, setCurrentCountry] = useState([]);
    const multiselectRef = useRef(null);

    return {
        ideasInfo, setIdeasInfo,
        currentDepartment, setCurrentDepartment,
        currentModule, setCurrentModule,
        currentLabel, setCurrentLabel,
        listItems, setListItems,
        ideasFiltered, setIdeasFiltered,
        originalList, setOriginalList,
        searchVal, setSearchVal,
        showactiveEmployees, setShowActiveEmployees,
        managers, setManagers,
        filterFlags, setFilterFlags,
        currentCountry, setCurrentCountry,
        multiselectRef,
    }
}

export default useIdeasFilter
