import React, { createContext, ReactNode, useContext } from 'react';
import useLabelsData from "../hooks/useLabelsData";

const LabelsContext = createContext<ReturnType<typeof useLabelsData> | null>(null);

const LabelsProvider = ({ children }: { children: ReactNode }) => {
    const labelsData = useLabelsData();

    return (
        <LabelsContext.Provider value={labelsData}>
            {children}
        </LabelsContext.Provider>
    );
}

const useLabelsContext = () => {
    const context = useContext(LabelsContext);
    if (context === null) {
        throw new Error('useLabelsContext must be used within an LabelsProvider');
    }
    return context;
}

export {useLabelsContext, LabelsProvider, LabelsContext};