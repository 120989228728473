/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";

import analyticsStyle from "assets/jss/views/analyticsStyle.jsx"; //style for all analytics
import { ourBlueLight, ourBlue, ourOrange } from "assets/jss/incept-sustainability-variables.jsx";
import search from "assets/img/search.svg";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import DropDown from 'components/CustomInput/DropDown.jsx';
import MenuItem from '@mui/material/MenuItem';
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import FakeButton from "components/CustomButtons/FakeButton";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { Link } from "react-router-dom";
import newtab from "assets/img/newtabOrange.png";
import { useMediaQuery } from 'react-responsive'; //for mobile responsiveness

const useStyles = (theme) => ({
	paddingMobile: ({color, textColor}) => ({
		"@media only screen and (max-width: 960px)": {
			margin: "20px 0"
		}
	}),
	lineid: {
		textOverflow: "ellipsis",
		"@supports (-webkit-line-clamp: 2)": {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "initial",
			display: "-webkit-box",
			WebkitLineClamp: "2",
			WebkitBoxOrient: "vertical"
		  }
	}
});

function withMyHook(Component) {
    return function WrappedComponent(props) {
      const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
      return <Component {...props} isMobile={mobile} />;
    }
}

function MyActions_FilterBar(props) {
	const {classes, content} = props;
	const [load, setLoad] = useState(false);

	return (
        <GridContainer space={false} alignItems="center">
            <GridItem xs={12} sm={12} md={12} otherclasses={classes.paddingFilter2} style={false ? {marginTop:"0"}:{paddingLeft:"0"}}>
                <GridContainer space={false} alignItems="center">
					{(props.sourcePage.idea!=="0" || props.sourcePage.activity!=="0") && 
					<GridItem xs={12} sm={12} lg={2} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                        <GridContainer direction="column">
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <span style={{marginRight:"15px"}}><b>{props.sourcePage.idea!=="0" ? "From idea: ":"From activity: "}</b></span>
                            </GridItem>
                            <GridItem otherclasses={classes.noLeftPadding}>
							<Link target="_blank"  to={{pathname:(props.sourcePage.idea!=="0" ? "/admin/analytics_ideas#"+props.sourcePage.idea:"/admin/analytics_company#"+props.sourcePage.activity), query:0}} className={classes.lineid}>
								{props.sourcePage.sourcetext}
								<img
									src={newtab}
									width="10"
									alt=""
									style={{ marginLeft: "5px" }}
								/>
							</Link>
                            </GridItem>
                        </GridContainer>
                    </GridItem>}
					<GridItem xs={12} sm={12} lg={2} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                        <GridContainer direction="column">
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <span style={{marginRight:"15px"}}>{"Action for: "}</span>
                            </GridItem>
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <DropDown
                                value={props.currentFilters.actionfor}
                                analytics={true}
                                paddingShort={true}
                                fullWidth={false}
                                style={{width:"140px"}}
								onChange={(e) => props.onChangeActionFor(e)}
                            >
                                <MenuItem key={0} value={0}>{"All"}</MenuItem>
                                {props.departments.map(
                                    (item) => <MenuItem key={item.id} value={parseInt(item.id)}>{item.name}</MenuItem>
                                )}
                            </DropDown>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} lg={2} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                        <GridContainer direction="column">
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <span style={{marginRight:"15px"}}>{"Responsible: "}</span>
                            </GridItem>
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <DropDown
                                value={props.currentFilters.responsible}
                                analytics={true}
                                paddingShort={true}
                                fullWidth={false}
                                style={{width:"140px"}}
								onChange={(e) => props.onChangeResponsible(e)}
                            >
                                <MenuItem key={0} value={0}>{"All"}</MenuItem>
								{	/*Iterate through objects*/
									Object.keys(props.managers).map(key => 
										<MenuItem key={key} value={key} name={key}>
										{props.managers[key].first_name+" "+props.managers[key].last_name}
										</MenuItem>
									)
								}
                            </DropDown>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} lg={2} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                        <GridContainer direction="column">
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <span style={{marginRight:"15px"}}>{"Source: "}</span>
                            </GridItem>
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <DropDown
                                value={props.currentFilters.source}
                                analytics={true}
                                paddingShort={true}
                                fullWidth={false}
                                style={{width:"140px"}}
								onChange={(e) => props.onChangeSource(e)}
                            >
                                <MenuItem key={0} value={0}>{"All"}</MenuItem>
                                <MenuItem key={1} value={"ideas"} name={"Ideas and comments"}>{"Ideas and comments"}</MenuItem>
                                <MenuItem key={2} value={"activity"} name={"Feedback activity"}>{"Feedback activity"}</MenuItem>
                            </DropDown>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} lg={2} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                        <GridContainer direction="column">
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <span style={{marginRight:"15px"}}>{"Status: "}</span>
                            </GridItem>
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <DropDown
                                value={props.currentFilters.status}
                                analytics={true}
                                paddingShort={true}
                                fullWidth={false}
                                style={{width:"140px"}}
								onChange={(e) => props.onChangeStatus(e)}
                            >
                                <MenuItem key={0} value={0}>{"All"}</MenuItem>
                                <MenuItem key={1} value={"To do"} name={"To do"}>{"To do"}</MenuItem>
								<MenuItem key={1} value={"In Progress"} name={"In Progress"}>{"In Progress"}</MenuItem>
								<MenuItem key={1} value={"Completed"} name={"Completed"}>{"Completed"}</MenuItem>
                            </DropDown>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} lg={2} style={props.isMobile ? {marginTop:"0"}:{marginTop: "24px"}}>
                        <ActionButton
                            type="submit"
                            backGround={false}
                            isAnalytics={true}
                            onClick={props.clearFilters}
                        >
                            {'Clear filters'}
                        </ActionButton>
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
	);
}
export default
withMyHook(withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(MyActions_FilterBar));
