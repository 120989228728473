
/*!
* Description: this file contains a component
*  that renders ******
*/
import React, { useState } from 'react';
import withStyles from "@mui/styles/withStyles";
//style for all analytics
import { ourDarkGreen } from "assets/jss/incept-sustainability-variables.jsx";
import { Draggable } from 'react-beautiful-dnd';
import { styled } from '@stitches/react';

const useStyles = (theme) => ({

});

const StyledItem = styled('div', {
	color: ourDarkGreen,
	border: "1px solid #125A40",
	borderRadius: 16,
	padding: '13px',
	fontSize: "13px",
	transition: 'background-color .8s ease-out',
	marginTop: 8,
	maxWidth: 310,
  
	':hover': {
		fontWeight: "bold",
		border: "2px solid "+ourDarkGreen,
	  	transition: 'background-color .1s ease-in'
	}
  })

function Item(props) {
    const {classes} = props;
    
    return (
        <Draggable key={props.item.id} draggableId={props.item.id} index={props.index}>
		{(provided, snapshot) => (
			<StyledItem
				ref={provided.innerRef}
				css={props.stylecss}
				{...provided.draggableProps}
				{...provided.dragHandleProps}
			>
				{props.item.content}
			</StyledItem>
		)}
		</Draggable>   
    );
}

export default
withStyles(
    (theme) => ({
      ...useStyles(theme),
    }),
    { withTheme: true },
  )(Item);