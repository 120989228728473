import React from "react";

// Styles
import withStyles from "@mui/styles/withStyles";
import { ourOrange } from 'assets/jss/incept-sustainability-variables.jsx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {ArrowDropDownIcon} from '@mui/icons-material';

//form inputs
import Select from '@mui/material/Select';

const styles = ({color,marginB,paddingShort}) => ({
	root: {
		color: ({color}) => color === 'magenta' ? ourOrange : color,
		"@media only screen and (max-width: 600px)": {
			width: "160px",
			marginTop: "15px"
		},
		"@media only screen and (max-width: 1140px)": {
			marginBottom: "15px",
		},
		'& .MuiOutlinedInput-notchedOutline':{
			borderColor: ({color}) => color === 'magenta' ? ourOrange : color,
			borderRadius:'24px'
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline':{
			borderColor: ({color}) => color === 'magenta' ? "#FFA8A5" : color
		},
		'& .MuiSelect-select:focus':{
			backgroundColor:'rgba(255, 255, 255, 0.3)',
			borderRadius:'24px'
		},
		'& .MuiSelect-icon':{
			color:({color}) => color === 'magenta' ? ourOrange : color
		},
		'& .MuiSelect-root':{
			// "@media only screen and (max-width: 960px)": {
			// 	maxWidth: "100px"
			//   }
		},
		'& .MuiOutlinedInput-input':{
			padding: ({paddingShort}) => paddingShort && "6px 32px 7px 10px",
		}
	},
	rootAnalytics: {
		backgroundColor: "#e7e7e7",
		borderRadius:'11px',
		minWidth: "120px",
		textAlign:"left",
		fontSize: "1rem",
		padding: "5px 10px",
		"@media only screen and (max-width: 600px)": {
			width: "160px",
			marginTop: "15px"
		},
		"@media only screen and (max-width: 1140px)": {
			marginBottom: "15px",
		},
		'& .MuiOutlinedInput-notchedOutline':{
			border: 0,
			borderRadius:'16px',
			
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline':{
			borderColor: ({color}) => color === 'magenta' ? "#FFA8A5" : color
		},
		'& .MuiSelect-select:focus':{
			//backgroundColor:'rgba(255, 255, 255, 0.3)',
			backgroundColor: "transparent",
			//borderRadius:'24px'
		},
		'& .MuiSelect-icon':{
			color: ourOrange,
		},
		'& .MuiSelect-root':{
			// "@media only screen and (max-width: 960px)": {
			// 	maxWidth: "100px"
			//   }
		},
		'& .MuiOutlinedInput-input':{
			padding: (paddingShort) ? "6px 32px 7px 10px":"0",
		}
	},
});

class DropDown extends React.Component {
		
	render(){
		const {classes, fullWidth=true,children, color, paddingShort,otherClasses,noPadding=false, analytics=false, ...rest} = this.props;
		return (
			<Select 
				className={analytics ? classes.rootAnalytics+" "+otherClasses:classes.root}
				variant="outlined" 
				fullWidth={fullWidth} 
				IconComponent={KeyboardArrowDownIcon}
				{...rest}>
				{children}
			</Select>
		);
	}
}

export default withStyles(styles)(DropDown);