/*!
* Description: this file contains the component for
*  the type of exercise ExtraSDG, ex. guide 3, step 3, 
*  					"  Exercise: The 6th SDGs at DFDS" (only for DFDS)
* Components: ExtraSDG
*/
import React from "react";
import Likert from 'react-likert-scale';
// Styles
import withStyles from "@mui/styles/withStyles";
import { ourDarkGreen,ourMediumGreen,ourGreen,ourOrange } from 'assets/jss/incept-sustainability-variables.jsx';

// Style components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from "@mui/material/Container";
import ActionButton from "components/CustomButtons/ActionButton";

//Translations
import { withTranslation } from 'react-i18next';


const styles = {
    likert:  {
        cursor:"pointer",
        '& .likertResponse:hover .likertIndicator': {
			//backgroundColor: "#009df0"
		},
        '& .likertIndicator': {
			cursor:"pointer",
            width: "2.2em",
            height: "2.2em",
            backgroundColor: ourGreen,
            border: "thin solid "+ourDarkGreen,
            borderRadius: "1.1em"
		},
        '& .likertBand': {
            "@media only screen and (max-width: 960px)": {
                display: "block",
            }
		},
        '& .likertIndicator:hover': {
			backgroundColor: ourDarkGreen,
            border: "1px solid "+ourOrange,
		},
        '& .likertResponse': {
            '&:hover .likertIndicator': {
                backgroundColor: ourDarkGreen,
                border: "1px solid "+ourOrange,
            },
            "@media only screen and (max-width: 960px)": {
                width: "100%",
                marginBottom: "60px"
            }
		},
        '& .likertResponse > input:checked + .likertIndicator': {
            backgroundColor: ourDarkGreen,
		},
        '& .likertLine': {
			borderTop: "3px solid "+ourDarkGreen,
            marginTop: "1.1em",
            "@media only screen and (max-width: 960px)": {
                width: "0",
            }
		},
        '& .likertText': {
            fontSize: "16px",
            color: "rgba(0, 0, 0, 0.87)",
            paddingTop: "2em",
            "@media only screen and (max-width: 960px)": {
                paddingTop: "3em",
            }
		},
    },
    cardbodyP: {
        backgroundColor: "transparent",
        boxShadow: "none"
    },
    buttonContainer: {
		textAlign: "right",
		//marginBottom: "60px",
		paddingRight:"0",
        "@media only screen and (max-width: 960px)": {
            paddingRight: "20px",
        }
	},
    statusContainer: {
		textAlign: "right",
		paddingRight: "0",
		marginTop: "50px",
        "@media only screen and (max-width: 960px)": {
            paddingRight: "20px",
        }
	},
	
};

/*
* this component renders the exercise for DFDS, "Extra SDG"
*/
class LikertScale2 extends React.Component {
	
	constructor(props){
		super(props);
		this.state = {
            selectedValue : {value:-1, text:""},
            submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
        }
	
		if(typeof this.props.datasav2  !== "undefined") {
            var aux = this.props.datasav2["content"][0];
            this.state.selectedValue = {value:aux.statement_id,text:aux.text};
        }
	}

    handleSubmit(e) {
        this.setState({submittedanswer: true});
		this.props.save2("LikertScale", [{statement_id:this.state.selectedValue.value, text:this.state.selectedValue.text}], 
            this.props.inkey, this.props.exercise_id);
	}

    handleChange(val) {
        this.setState({selectedValue:val, submittedanswer: false})
    }

	render(){
		const {classes,exercise, t} = this.props;
        const { options } = exercise;
        let responsesArr = [];

        options.map((option, answerIndex) => {
            const { title, statement_id } = option;
            if (this.state.selectedValue.value===statement_id) {
                responsesArr.push({value:statement_id, text:title, checked: true});
            }
            else
                responsesArr.push({value:statement_id, text:title});
        });

        const likertOptions = {
            question: "",
            responses: responsesArr,
            onChange: val => {
                this.handleChange(val);
            }
          };
		return (
			<div className={classes.root}>
				<Card className={classes.cardbodyP}>
					<CardBody isexercise={true} style={{paddingBottom:"0"}}>
						<Likert {...likertOptions} className={classes.likert} flexible={false} layout={"stacked"}/>
                        <div className={classes.statusContainer}>
						{this.state.submittedanswer && <p>{t("Thank you! Your feedback has been submitted")}</p>}
						</div>
                        <Container className={classes.buttonContainer}>
                            <ActionButton
                                style={{width:"auto"}}
                                type="submit"
                                centered={true}
                                onClick={(e) => this.handleSubmit(e)}
                            >
                                {t('Submit')}
                            </ActionButton>
                        </Container>
					</CardBody>
				</Card>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(LikertScale2));