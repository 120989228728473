/*!
 * Description: this file contains a component
 *  that renders a disabled lookalike button that contains numbers or text
 */

import React, { useState } from "react";
import withStyles from "@mui/styles/withStyles";

import analyticsStyle from "assets/jss/views/analyticsStyle.jsx"; //style for all analytics
import {
  ourGreen20,
  ourGreen,
  ourOrange,
} from "assets/jss/incept-sustainability-variables.jsx";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Checkbox from "@mui/material/Checkbox";
import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";
import ResponsivePie from "components/Nivo/ResponsivePie.jsx";
import ProgressBar from "react-bootstrap/ProgressBar";
import newtab from "assets/img/newtab.png";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import { Link } from "react-router-dom";
import ProgressButton from "components/CustomButtons/Progressbutton.jsx";
import CustomBox from "components/CustomLayout/CustomBox.jsx";
import { useMediaQuery } from "react-responsive"; //for mobile responsiveness
import moreInfo from "assets/img/moduleIcon.svg";
import Tooltip from "@mui/material/Tooltip";

const useStyles = (theme) => ({
  responsivePie: {
    "& g:nth-child(2)": {
      "& g:nth-child(1) text": {
        fontSize: "28px !important",
      },
    },
  },
  pieLabel: {
    fontSize: "18px",
    fontWeight: "bold",
  },
  pieLabelVal: {
    fontSize: "26px",
    fontWeight: "bold",
    color: ourGreen,
  },
  centerV: {
    display: "grid",
    alignItems: "center",
  },
});

const themePie = {
  legends: {
    text: {
      fontSize: 18,
    },
  },
};

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
    return <Component {...props} isMobile={mobile} />;
  };
}

const TextOnlyTooltip = withStyles({
  tooltip: {
    fontSize: "14px",
  },
})(Tooltip);

function PieCard(props) {
  const { classes, content } = props;

  const pieColors = [ourGreen, ourGreen20];

  const items_bars = [];
  const items_pies = [];
  const defs = [];
  let i = 0;
  props.data.forEach((arrayItem) => {
    if (i < 3) {
      let constructPie = {
        id: "Did not selected this option",
        label: "Did not selected this option %",
        value: props.includeEmpty ? arrayItem.rest : arrayItem.rest_empty,
      };
      let finalPie = {
        id: arrayItem.id,
        label: arrayItem.label + " %",
        value: props.includeEmpty ? arrayItem.value : arrayItem.value_nempty,
      };
      items_pies.push(
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={4}
          key={props.exerciseid + "pie" + i}
        >
          <div
            className={classes.responsivePie}
            style={{ height: "300px", width: "100%", position: "relative" }}
          >
            <ResponsivePie
              data={[finalPie, constructPie]}
              pieColors={pieColors}
              enableArcLinkLabels={false}
              theme={themePie}
              defs={defs}
            />
          </div>
          <GridContainer
            justifyContent="center"
            style={{ marginTop: "-90px" }}
            key={props.exerciseid + "intC" + i}
          >
            <GridItem
              xs={12}
              sm={12}
              md={12}
              key={props.exerciseid + "int" + i}
              style={{ textAlign: "center" }}
            >
              <div>
                <span className={classes.pieLabelVal}>
                  {props.includeEmpty
                    ? arrayItem.value + "%"
                    : arrayItem.value_nempty + "%"}
                </span>
              </div>
              <div>
                <span className={classes.pieLabel}>{arrayItem.label}</span>
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
      );
    } else {
      items_bars.push(
        <div
          className={classes.progressInfo}
          key={props.exerciseid + "div" + i}
          style={{ marginBottom: "10px" }}
        >
          <GridContainer
            justifyContent="center"
            key={props.exerciseid + "tmC" + i}
          >
            <GridItem
              xs={12}
              sm={12}
              md={5}
              key={props.exerciseid + "tm" + i}
              otherclasses={classes.centerV}
              space={0}
              spaceNoPadding={true}
            >
              <ProgressButton
                val={
                  props.includeEmpty ? arrayItem.value : arrayItem.value_nempty
                }
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={7}
              key={"tm" + i}
              otherclasses={classes.centerV}
            >
              <p style={{ marginBottom: "0", fontWeight: "500" }}>
                {arrayItem.label}
              </p>
            </GridItem>
          </GridContainer>
        </div>
      );
    }
    i++;
  });

  if (props.customvalues.length > 0) {
    items_bars.push(
      <div
        className={classes.progressInfo}
        key={props.exerciseid + "div" + i}
        style={{ marginBottom: "10px" }}
      >
        <GridContainer
          justifyContent="center"
          key={props.exerciseid + "tmC" + i}
        >
          <GridItem
            xs={12}
            sm={12}
            md={5}
            key={props.exerciseid + "tm" + i}
            otherclasses={classes.centerV}
            space={0}
            spaceNoPadding={true}
          >
            <ProgressButton val={props.pcustom} />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={7}
            key={"tm" + i}
            otherclasses={classes.centerV}
          >
            <p style={{ marginBottom: "0", fontWeight: "500" }}>
              {"Custom answers"}
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  if (props.includeEmpty) {
    items_bars.push(
      <div
        className={classes.progressInfo}
        key={props.exerciseid + "div" + i}
        style={{ marginBottom: "10px" }}
      >
        <GridContainer
          justifyContent="center"
          key={props.exerciseid + "tmC" + i}
        >
          <GridItem
            xs={12}
            sm={12}
            md={5}
            key={props.exerciseid + "tm" + i}
            otherclasses={classes.centerV}
            space={0}
            spaceNoPadding={true}
          >
            <ProgressButton val={props.pempty} />
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={7}
            key={"tm" + i}
            otherclasses={classes.centerV}
          >
            <p style={{ marginBottom: "0", fontWeight: "500" }}>
              {"Empty submissions"}
            </p>
          </GridItem>
        </GridContainer>
      </div>
    );
  }

  const items_content = [];
  props.customvalues.forEach((arrayItem, index) => {
    items_content.push(
      <GridItem
        xs={12}
        sm={12}
        md={6}
        lg={4}
        key={props.exerciseid + "" + index}
        otherclasses={classes.columnCardsPadding}
      >
        <CustomBox
          key={props.exerciseid + "" + index}
          index={index}
          exerciseid={props.exerciseid}
          content={arrayItem.answer}
        />
      </GridItem>
    );
  });

  return (
    <CardAnalytics>
      <CardAnalyticsHeader title={props.title}>
        <div style={{ marginLeft: "auto", cursor: "pointer" }}>
          {!props.isCustomManager && <ActionButton
            type="submit"
            isAnalytics={true}
            style={{ marginRight: "10px" }}
            onClick={(e) => props.addActionModal(props.title,props.exerciseid,props.index)}
          >
            {"+add to actions page"}
          </ActionButton>}
          <TextOnlyTooltip title={"How are the stats calculated?"} arrow>
            <img
              className="responsive2"
              src={moreInfo}
              alt="How are the stats calculated?"
              onClick={(e) => props.showHideModal("pie")}
            />
          </TextOnlyTooltip>
        </div>
      </CardAnalyticsHeader>
      <CardAnalyticsBody>
        {props.children}
        <div style={{ marginBottom: "60px", marginTop: "30px" }}>
          <FormGroup style={{ paddingLeft: "15px" }}>
            <FormControlLabel
              style={{
                position: "absolute",
                zIndex: "999",
                color: "rgba(0, 0, 0, 0.87)",
              }}
              control={
                <Checkbox
                  color="default"
                  disableRipple
                  checked={props.includeEmpty}
                  style={{
                    color: ourOrange,
                    marginRight: "10px",
                    height: "1.5em",
                    width: "1.5em",
                  }}
                  className={classes.radioButton}
                  onClick={(e) => props.updatefilterEmpty(props.index)}
                />
              }
              label="Include empty submissions in results"
            />
          </FormGroup>
          <GridContainer justifyContent="center">{items_pies}</GridContainer>
          <div style={{ marginTop: "60px" }}>
            <GridItem xs={12} sm={12} md={10} lg={12} xl={12}>
              {items_bars}
            </GridItem>
          </div>

          {props.customvalues.length > 0 && (
            <div style={{ marginTop: "60px" }}>
              <div style={{ marginBottom: "20px" }}>
                <span style={{ fontWeight: "bold" }}>{"Custom answers: "}</span>
                <span>{props.ncustom}</span>
              </div>
              <GridContainer justifyContent="center">
                {items_content}
              </GridContainer>
              <div style={{ textAlign: "right", marginRight: "30px" }}>
                <Link
                  target="_blank"
                  to={{
                    pathname: "/admin/custom_activity/" + props.exerciseid,
                    search:props.filter ? "?filter="+JSON.stringify(props.filter):""
                  }}
                  style={{ marginTop: "20px" }}
                >
                  <ActionButton centered={true} nomargin={true}>
                    {"See all custom answers "}
                    <img
                      className="responsive"
                      src={newtab}
                      width="15"
                      alt=""
                      style={{ marginLeft: "10px" }}
                    />
                  </ActionButton>
                </Link>
              </div>
            </div>
          )}
        </div>
      </CardAnalyticsBody>
    </CardAnalytics>
  );
}
export default withMyHook(
  withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true }
  )(PieCard)
);
