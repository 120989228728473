
import React from "react";
import { Switch, Route } from "react-router-dom";
import withStyles from "@mui/styles/withStyles";

import Guide from "components/GuidePage/Guide.js";

//We import custom css to be applied for all guides
import guidesStyle from "assets/jss/incept-custom-css.jsx";

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const NotFoundGuides = () => (<h1>No guide found.</h1>);

class Guides extends React.Component {

	routeName = 'guides';

	constructor(props){
        super(props);

        this.state = {
        	imageSrc:false, //false => no lightbox is displayed
        };
        
        this.onClickImagesToLightbox = this.onClickImagesToLightbox.bind(this); //bind function once
    }

	componentDidMount(){
		let ancestor = document.getElementById('root');
		ancestor.addEventListener('click', this.onClickImagesToLightbox, false);
	}

	componentWillUnmount(){
		let ancestor = document.getElementById('root');
		ancestor.removeEventListener('click', this.onClickImagesToLightbox, false);
	}

	onClickImagesToLightbox(event){
		if (event.target.tagName === "IMG" && event.target.classList.contains('responsive')){
			this.setState({imageSrc:event.target.src});
		}
		else if (event.target.className === "carrouselImage_isxf"){
			console.log(event.target.querySelector('img').src);
			this.setState({imageSrc:event.target.querySelector('img').src});
		}
	}

	render() {

		return (
			<React.Fragment>
				<Switch>
					<Route path={`/${this.routeName}/:guideName/:pageNum/:typemod`} component={Guide} />
					<Route path={`/${this.routeName}/:guideName/:pageNum/`} component={Guide} />
					<Route component={NotFoundGuides}/>
				</Switch>
				{
					this.state.imageSrc && 
					<Lightbox
						mainSrc={this.state.imageSrc}
						onCloseRequest={() => this.setState({ imageSrc: false })}
					/>
				}
			</React.Fragment>
		);
	}
}

export default withStyles(guidesStyle)(Guides);
