/*!
* Description: this file contains the component for
*  the type of exercise SimpleChoiceGroupedText, ex. guide 3, step 2, 
*  					"Exercise: Why work with the SDGs?"
* Components: SimpleChoiceGroupedText
*/
import React from "react";


// Styles
import withStyles from "@mui/styles/withStyles";
import {  grayCape20 } from 'assets/jss/incept-sustainability-variables.jsx';

// Style components
import Grid from '@mui/material/Grid';

//Buttons
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import Container from "@mui/material/Container";
// Forms
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import checked from 'assets/img/checkedWhiteBg.png';

//Translations
import { withTranslation } from 'react-i18next';

const styles = {
	root: {
		paddingTop: '30px',
	},
	formControlLabelRoot: {
		maxWidth: '400px',
		margin: '0 auto',
	},
	buttonContainer: {
		textAlign: "right",
		marginTop: "20px",
		marginBottom: "60px"
	},
	formControlLabelLabel: {

		borderRadius: '1rem',
		border: `1px solid #FFF`,
		padding: '20px',
		'&:hover': {
			boxShadow: 'inset 1px 1px 10px 8px '+grayCape20,
		},
		'.Mui-checked ~ &': {
			backgroundColor: grayCape20,
		},
		'&:active': {
			boxShadow: 'inset 1px 1px 10px 18px '+grayCape20
		}
	},
	gridItem: {
		textAlign: 'center',
		paddingRight: '10px',
		paddingBottom: '50px'
	},
	labelTitle: {
		fontSize: '24px',
		color: 'white',
		marginBottom: '10px'
	},
	labelStatement: {
		color: 'white',
	},
	statusContainer: {
		textAlign: "right",
		paddingRight: "24px"
	},
};

/*
* component that renders exercise with selection of a box
*/
class SimpleChoiceGroupedText extends React.Component {

	constructor(props) {
		super(props);
		
		
		this.state = {
			selectedAnswer: {'value':-1,'statement_id':-1},
			submittedanswer: (typeof this.props.datasav2 !== "undefined") ? true: false
		}
		if (typeof this.props.datasav2 !== "undefined") {
			this.state.selectedAnswer = this.props.datasav2["content"][0];
			this.state.selectedAnswer.value = parseInt(this.state.selectedAnswer.value);
		}
	}

	handleChange(e,value_stat) {
		const answerIndex = {'value': value_stat.value, 'statement_id':value_stat.statement_id}
		this.setState({
			selectedAnswer: answerIndex,
			submittedanswer: false
		});
		//this.props.save2("SimpleChoiceGT", [answerIndex], this.props.inkey, this.props.exercise_id);
	}

	handleSubmit(e) {
		e.preventDefault();
		//Commented to allow empty answers
		//if (this.state.selectedAnswer.statement_id!==-1) {
			this.setState({ submittedanswer: true });
			this.props.save2("SimpleChoiceGT", [this.state.selectedAnswer], this.props.inkey, this.props.exercise_id);
		//}
	}

	_renderOptions(options) {
		const { classes } = this.props;
		const st = options.map(
			(option, answerIndex) => {
				const { title, statement, statement_id } = option;
				const radio = <Radio color="default" style={{ display: 'none' }} />;

				const label = (
					<React.Fragment>
						<div className={classes.labelTitle}>{title}</div>
						<div className={classes.labelStatement}>{statement}</div>
					</React.Fragment>
				);

				const value_stat = {value:answerIndex,statement_id:statement_id};

				return (
					<Grid item xs={12} sm={6} key={answerIndex} className={classes.gridItem}>
						<FormControlLabel
							classes={{
								root: classes.formControlLabelRoot,
								label: classes.formControlLabelLabel
							}}

							checked={this.state.selectedAnswer.value === answerIndex}
							control={radio}
							label={label}
							onChange={(e) => this.handleChange(e,value_stat)}
							value={0}
						/>
					</Grid>
				)
			}
		);

		return (
            <Grid container justifyContent="space-evenly">
				{st}
			</Grid>
        );
	}

	render() {
		const { classes, exercise, t } = this.props;
		const { options } = exercise;
		return (
			<div className={classes.root}>
				{ this._renderOptions(options)}
				<div className={classes.statusContainer}>
				{this.state.submittedanswer && <p>{t("Thank you! Your feedback has been submitted")}</p>}
				</div>
				<Container className={classes.buttonContainer}>
					<ActionButton
						size="auto"
						type="submit"
						nomargin={true}
						centered={true}
						onClick={(e) => this.handleSubmit(e)}
					>
						{t('Submit')}
					</ActionButton>
				</Container>
			</div>
		);
	}
}

export default withTranslation()(withStyles(styles)(SimpleChoiceGroupedText));