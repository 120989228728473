/*!
 * Description: this file contains a component
 *  that renders a disabled lookalike button that contains numbers or text
 */

import React from "react";
import withStyles from "@mui/styles/withStyles";
import Tooltip from "@mui/material/Tooltip";
import Remove from "../../assets/img/remove.png";
import checkmarkactions from "../../assets/img/checkmarkactions.png";
import { standardRadious } from 'assets/jss/incept-sustainability-variables.jsx';

import { useState } from "react";

const useStyles = () => ({
  smallButton: ({ color, textColor, border = 0, borderColor = "" }) => ({
    backgroundColor: color,
    color: textColor,
    border: border + "px solid " + borderColor,
    borderRadius: standardRadious,
    fontSize: "14px",
    padding: "5px 15px",
  }),
});

const TextOnlyTooltip = withStyles({
  tooltip: {
    fontSize: "14px",
  },
})(Tooltip);

function FakeButton(props) {
  const [ShowModalBool, setShowModalBool] = useState(false);
  const [EditModalBool, setEditModalBool] = useState(false);
  const {
    classes,
    textColor,
    borderColor,
    border,
    color,
    atooltip = false,
    textTooltip = "",
    disabledb = true,
    labelIcon,
    content,
    ShowModalFunc,
    onTextClick,
	labelIconCheck =false,
    ...other
  } = props;

  function ShowModal() {
    setShowModalBool(true);
    ShowModalFunc(ShowModalBool);
  }
  function ShowEditModal() {
    setEditModalBool(true);
    onTextClick(EditModalBool);
  }

  if (atooltip) {
    return (
      <button disabled className={classes.smallButton} {...other}>
        <TextOnlyTooltip title={textTooltip} arrow>
          <span style={{ display: "inherit", width: "100%" }}>{content}</span>
        </TextOnlyTooltip>
      </button>
    );
  }
  if (labelIcon) {
    return (
      <button className={classes.smallButton} {...other}>
        <span onClick={ShowEditModal}>{content}</span>
        <img
          style={{ width: "20px", float: "right", marginLeft: "10px",marginTop:"2px" }}
          onClick={ShowModal}
          src={Remove}
        />
      </button>
    );
  } 
  if (labelIconCheck) {
    return (
      <button className={classes.smallButton} {...other}>
        <span>{content}</span>
        <img
          style={{ width: "20px", float: "right", marginLeft: "10px",marginTop:"2px" }}
          src={checkmarkactions}
        />
      </button>
    );
  }
  else if (disabledb) {
    return (
      <button disabled className={classes.smallButton} {...other}>
        {content}
      </button>
    );
  } else {
    return (
      <button className={classes.smallButton} {...other}>
        {content}
      </button>
    );
  }
}

export default withStyles(useStyles)(FakeButton);
