import { default as CustomModal } from 'components/Modal/Modal.jsx';
import loadinggif from "assets/img/loading.gif";
import ActionButton from "components/CustomButtons/ActionButton.jsx";


const pStyle = {
    modalTittle: {
        alignSelf: "flex-start",
    },
    modalBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "auto",
        maxHeight: " 400px",
    },
};

    const TermsAndConditionModal = ({ 
        customModalProps: {
        showModal,
        setShowModal,
		textModal,
        buttonAction,
    } }) => {
        return (
            <CustomModal
                show={showModal}
                onHide={() => setShowModal(false)}
                backdrop={true}
            >
                <div style={{ maxHeight: "500px" }}>
                    <div id="loadinggd2" style={{ display: "none" }}>
                        <img
                            className="responsive"
                            src={loadinggif}
                            width="25"
                            alt=""
                            style={{ marginBottom: "20px" }}
                        />
                    </div>
                    <h1 style={pStyle.modalTittle}>{translator('Terms')}</h1>
                    <div
                        style={pStyle.modalBody}
                    >
                        <p>
                            {textModal}
                        </p>

                        <ActionButton
                            size="auto"
                            onClick={() => {
                                document.getElementById("acceptCheckbox").checked = true;
                                buttonAction();
                            }}
                        >
                            Accept
                        </ActionButton>
                    </div>
                </div>
            </CustomModal>
        )
};

    export default TermsAndConditionModal;