import {Dispatch, SetStateAction} from "react";
import {AnalyticsIdeasFilter} from "../interfaces/AnalyticsIdeasFilter";
import {IdeasAndCommentsFilter} from "../BaseFilters/IdeasAndCommentsFilter";
import useIdeasFilter from "../../../hooks/useIdeasFilter";

class ModuleFilter<T extends string, K extends ReturnType<typeof useIdeasFilter>> extends IdeasAndCommentsFilter<T, K> implements AnalyticsIdeasFilter<T, K> {

    filter(moduleId: T, setSelfId: Dispatch<SetStateAction<T>>, ideasFilterData: ReturnType<typeof useIdeasFilter>): void {
        setSelfId(moduleId)

        if (moduleId === "0") {
            ideasFilterData.setListItems(ideasFilterData.ideasInfo.ideas);
            ideasFilterData.setIdeasFiltered(ideasFilterData.ideasInfo.ideas);
            return;
        }
        let ideasFilteredL = ideasFilterData.ideasInfo.ideas.filter((idea) => idea.moduleid == moduleId);
        if (ideasFilteredL.length < 5) {
            ideasFilterData.setIdeasFiltered([]);
            ideasFilterData.setIdeasInfo({
                ...ideasFilterData.ideasInfo,
                nideas: 0,
                nparticipants: 0,
            });
            ideasFilterData.setListItems([]);
            return;
        }
        ideasFilterData.setIdeasFiltered(ideasFilteredL);
        ideasFilterData.setListItems(ideasFilteredL);
    }

    clear = (ideasFilterData: ReturnType<typeof useIdeasFilter>): void =>  {
        this.filter("0" as T, ideasFilterData.setCurrentModule as Dispatch<SetStateAction<T>>, ideasFilterData );
    }

    
    public static createFilter = <T extends string, K extends ReturnType<typeof useIdeasFilter>> (): IdeasAndCommentsFilter<T, K> => new ModuleFilter();
    public static getClassName = (): string => "ModuleFilter";

    

}

export {ModuleFilter}
