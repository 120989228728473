/*
* This is not used anymore. For test only
*/
import React from "react";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Container from '@mui/material/Container';
import withStyles from "@mui/styles/withStyles";

const styles = {
  root: {},
};

/*
* This is not used anymore. For test only
*/
class GuidePage extends React.Component {

	render(){
		const { classes, children } = this.props;
		return (
			<div className={classes.root}>
				<Container>
					<Card>
					<CardBody>
						{children}
						</CardBody>
					</Card>
				</Container>
			</div>
		);
	} 
}

GuidePage.defaultProps = {
	children : (<h1>I'm a default content for this guide page!</h1>),
	currentPage : 0
};
 

export default withStyles(styles)(GuidePage);