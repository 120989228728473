import React from "react";

//Styles
import withStyles from "@mui/styles/withStyles";

const ikonStyles = {
	ikon:{
		width:'32px',
		height:'32px',
		backgroundSize:'contain',
		backgroundRepeat:'no-repeat',
		display:'inline-block',
		verticalAlign:'middle',

		'h1 &, h2 &, h3 &, h4 &, h5 &, h6 &':{
			marginRight:'5px'
		}
	}
};

const Ikon = 
	(props) => {
		const {classes, url} = props;
		return (
			<span className={classes.ikon} style={{backgroundImage:`url('${url}')`}}></span>
		);
	};

export default withStyles(ikonStyles)(Ikon);