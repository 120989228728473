/*!
 * Description: this file contains a component
 *  that renders a disabled lookalike button that contains numbers or text
 */

import React, { useState, useEffect, useRef, useContext } from "react";
import withStyles from "@mui/styles/withStyles";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import guidesStyle from "assets/jss/incept-custom-css.jsx";
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import DatePicker from 'react-date-picker';
import {
	n_comp_nly_3,
	isAuthenticated,
	save_label,
	ourGreen,
	actions_post
} from "assets/jss/incept-sustainability-variables.jsx";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import loadinggif from "assets/img/loading.gif";
import AnalyticsIdeas_Header from "views/Analytics/AnalyticsIdeas/AnalyticsIdeas_Header.jsx";
import AnalyticsFilterBar from "views/Analytics/AnalyticsIdeas/AnalyticsFilterBar.jsx";
import AnalyticsIdeas_cards from "views/Analytics/AnalyticsIdeas/AnalyticsIdeas_cards.jsx";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Form from "react-bootstrap/Form";
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from "@mui/material/TextField";
import { Divider, IconButton } from "@mui/material";
import icon7 from "assets/img/icon7.png";
import ActionButton from "components/CustomButtons/ActionButton";
import calendar from "assets/img/calendar.png";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import listimg from "assets/img/listimg.png";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import DropDown from "components/CustomInput/DropDown.jsx";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from 'react-textarea-autosize';
import jwt from "jwt-decode";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

// context ----
import { UseSharedTokenInfo, TokenContext } from "../../../context/auth_context/TokenContext.jsx";

import { useIdeaContext } from "../../../components/AnalyticsIdeas/context/IdeasContext";
import { useCountriesContext } from "../../../components/AnalyticsIdeas/context/IdeasCountriesContext";
import { useIdeasDepartmentsContext } from "../../../components/AnalyticsIdeas/context/IdeasDepartmentsContext";
import { useIdeasFilterContext } from "../../../components/AnalyticsIdeas/context/IdeasFilterContext";
import { useModulesContext } from "../../../components/AnalyticsIdeas/context/IdeasModulesContext";
import { useLabelsContext } from "../../../components/AnalyticsIdeas/context/IdeasLabelContext";
import { useModalActionsDivContext } from "../../../components/AnalyticsIdeas/context/IdeasModalActionDivContext";

import { AnalyticsIdeasHandleFilter } from "../../../components/AnalyticsIdeas/actions/FilterIdeas/FilterHandler";

// hooks -----
import useFetchDataForIdeasList from "../../../components/AnalyticsIdeas/hooks/useFetchDataForIdeasList";

const filter = createFilterOptions();

//Translations
import { withTranslation, Trans, useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import PageWrapper from "components/PageWrapper/PageWrapper.jsx";

const useStyles = (theme) => ({
	cardPaddingRight: ({ color, textColor }) => ({
		marginRight: "15px",
		"@media only screen and (max-width: 960px)": {
			marginRight: "15px",
		},
	}),
	modalCancelButton: {
		right: "0",
		position: "relative",
		zIndex: "3",
		padding: "0px",
		float: "right",
	},
	showElement: {
		display: "block",
	},
	hideElement: {
		display: "none !important",
	},
	textField: {
		marginTop: "0",
	},
	sourceBox: {
		maxHeight: "150px",
		overflow: "auto",
		margin: "0 0 25px"
	},
	lineid: {
		textOverflow: "ellipsis",
		"@supports (-webkit-line-clamp: 2)": {
			overflow: "hidden",
			textOverflow: "ellipsis",
			whiteSpace: "initial",
			display: "-webkit-box",
			WebkitLineClamp: "2",
			WebkitBoxOrient: "vertical"
		}
	},
	textarea1: {
		backgroundColor: "rgba(255,255,255,0.5)",
		position: "relative",
		marginBottom: "20px",
		borderRadius: "16px",
		borderColor: "grey",
		resize: "none"
	},
	actionRadios: {
		"& label": {
			color: "#273943"
		},

	}
});

function AnalyticsIdeas(props) {
	const { classes, content } = props;
	const { match } = props;

	const { authString } = useContext(TokenContext);
	var decodedtoken = "";

	// We don't use this for anything?  Redi ----
	var redi = false;
	!isAuthenticated() ? redi = true : decodedtoken = jwt(localStorage.getItem('token'));
	let roleaux = decodedtoken.role;

	const fetchDataForIdeasList = useFetchDataForIdeasList();


	const ideasFilter = useIdeasFilterContext();
	const ideasDepartmentFilter = useIdeasDepartmentsContext();
	const ideasModuleFilter = useModulesContext();
	const ideasLabelFilter = useLabelsContext();
	const ideasCountryFilter = useCountriesContext();
	const ideasModalActionsDiv = useModalActionsDivContext();
	const ideaData = useIdeaContext();


	const [isCustomManager, setCustomManager] = useState(roleaux === "Custom Manager" || roleaux === "Custom Learner Manager");
	const { archived } = match.params;
	const URLarchived = archived !== undefined;
	const [startDate, setStartDate] = useState(new Date());

	const [isFetching, setIsFetching] = useState(false);
	const [marketvalue, setMarketvalue] = useState("All ");
	const { t, i18n } = useTranslation();


	useEffect(() => {
		if (ideasModalActionsDiv.modalideasdiv.length > 0) {
			//open modal
			ideasModalActionsDiv.setaddActionModalValue(!ideasModalActionsDiv.addActionModalValue);
		}

	}, [ideasModalActionsDiv.modalideasdiv]);

	useEffect(() => {
		const fetchData = async () => {
			if (ideasFilter.ideasInfo.loadingfirst) {
				fetchDataForIdeasList(URLarchived);
			}
		};
		fetchData();
	}, [ideasFilter.ideasInfo.listCleared === true && URLarchived]);


	useEffect(() => {
		ideasFilter.setIdeasInfo(prev => ({ ...prev, loadingfirst: true }));
	
		const fetchData = async () => {
			await fetchDataForIdeasList(URLarchived);
			ideasFilter.setIdeasInfo(prev => ({ ...prev, loadingfirst: false }));
		};
	
		// Delay fetching data by 100ms to allow component to re-render with loading state
		setTimeout(fetchData, 100);
	}, [URLarchived]);

	// console.log("currentFilters: Department: " + ideasFilter.currentDepartment + ", Module: " + ideasFilter.currentModule + ", Label: " + ideasFilter.currentLabel + ", Country: " + ideasFilter.currentCountry);


	function search(key) {
		let ideasFilteredL = ideasFilter.ideasFiltered.filter((item) => {
			return (
				item.description.toLowerCase().indexOf(ideasFilter.searchVal.toLowerCase()) > -1
			);
		});
		ideasFilter.setShowActiveEmployees(false);
		ideasFilter.setIdeasFiltered(ideasFilteredL);
		ideasFilter.setListItems(ideasFilteredL);
	}

	function testingSetSearch(val) {
		if (val === "") {
			ideasFilter.setShowActiveEmployees(true);
			ideasFilter.setCurrentDepartment(0);
			ideasFilter.setListItems(ideasFilter.ideasInfo.ideas);
			ideasFilter.setIdeasFiltered(ideasFilter.ideasInfo.ideas);
		}
		ideasFilter.setSearchVal(val);
	}

	const addLabelModal = (event, ideaid, index) => {
		//open modal
		ideasLabelFilter.setLabelIdeaId(ideaid);
		ideasLabelFilter.setLabelIdeaIdIndex(index);
		ideasLabelFilter.setAddNewLabel(!ideasLabelFilter.addNewLabel);
	};

	const editLabelModal = (event, label, ideaid, index) => {
		ideasLabelFilter.setLabelIdeaId(ideaid);
		ideasLabelFilter.setLabelIdeaIdIndex(index);
		ideasLabelFilter.setEditLabelArray(label);
		ideasLabelFilter.setEditLabel(!ideasLabelFilter.editLabel);
	};

	const deleteLabelModal = (modalLabelBool, label, index, ideaid, labelid) => {
		//open modal
		ideasLabelFilter.setLabelIdeaId(ideaid);
		ideasLabelFilter.setLabelIdDel(labelid);
		ideasLabelFilter.setLabelIdeaIdIndex(index);
		ideasLabelFilter.setDeleteLabelValue(label);
		ideasLabelFilter.setDeleteLabel(!ideasLabelFilter.deleteLabel);
	};

	const addActionModal = () => {
		//fill out the ideas div box
		//look for the ideas text
		const contentDiv = ideasFilter.listItems
			.filter((r, index) => { return ideasFilter.ideasInfo.checked[index]; })
			.map((m) => {
				return <ListItem key={m.ideaid}>
					<ListItemAvatar>
						<Avatar src={listimg} style={{ width: "25px", height: "25px" }} />
					</ListItemAvatar>
					<ListItemText>
						<span className={classes.lineid}>{'"' + m.description + '"'}</span>
					</ListItemText>
				</ListItem>;
			});
			ideasModalActionsDiv.setmodalideasdiv(contentDiv);
	};

	const addMyActionModal = () => {
		const contentDiv = ideasModalActionsDiv.listActions
			.map((m) => { return <FormControlLabel key={m.idaction} value={m.idaction} control={<Radio />} label={m.title} />; });
			ideasModalActionsDiv.setlistActionsdiv(contentDiv);
			ideasModalActionsDiv.setaddMyActionModalValue(true);
	};

	const handleChangeMyActions = (event) => {
		ideasModalActionsDiv.setmodalSelAction(event.target.value);
	}

	const onChangeMarket = (e) => {
		setMarketvalue(e.target.value);
	};

	const handleChange_editText = (e) => {
		let textvalue = e.target.value;
		ideasLabelFilter.setEditLabelArray((editLabelArray) => ({
			...editLabelArray,
			name: textvalue,
		}));
	};

	const handleChange_text = (e) => {
		ideasLabelFilter.setTextLabel(e.target.value);
	};

	const handleChangeCheckbox = (e, ideaid, indexIdea) => {
		ideasFilter.setIdeasInfo(prevSa => {
			let { checked, ...restItem } = ideasFilter.ideasInfo;
			let newItem = checked;
			let allfalse = true;
			let sa = checked.map((checkedValue, index) => {
				if (index === indexIdea) {
					if (!checkedValue && allfalse)
						allfalse = false;
					return !checkedValue;
				}
				if (checkedValue && allfalse)
					allfalse = false;
				return checkedValue;
			});
			ideasModalActionsDiv.setnonCheckedideas(allfalse);
			newItem = { ...restItem, checked: sa };
			return newItem;
		});
	};

	const DeleteModal = (e) => {
		ideasLabelFilter.setSubmittingNewLabel(true);
		if (!isAuthenticated()) {
			props.isExpired();
		}

		const insdata = {
			labelid: ideasLabelFilter.labelIdDel,
			labelidea: ideasLabelFilter.labelIdeaId
		};

		//post to send request to backend
		axios
			.post(save_label + "labeldel", insdata, {
				headers: { Authorization: authString },
			})
			.then((res) => {
				console.log(res);
				ideasFilter.listItems.map((listValue, index) => {
					if (listValue.ideaid === ideasLabelFilter.labelIdeaId) {
						listValue.label.map((labelvalue, index1) => {
							if (labelvalue.name == ideasLabelFilter.deleteLabelValue) {
								delete listValue.label[index1];
							}
						});
					}
				});
				ideasLabelFilter.setDeleteLabel(false);
				ideasLabelFilter.setSubmittingNewLabel(false);
				ideasFilter.setListItems(ideasFilter.listItems);
			})
			.catch((err) => {
				console.log(err);
				ideasLabelFilter.setSubmittingNewLabel(false);
				ideasLabelFilter.setSubmittingNewLabel(false);
				ideasLabelFilter.setEditLabel(false);
			});

	};

	const DeleteCloseModal = (e) => {
		ideasLabelFilter.setDeleteLabel(false);
	};

	const submitEditLabel = (e) => {
		if (ideasLabelFilter.editLabelArray.name == "" || ideasLabelFilter.editLabelArray.name == null) {
			ideasLabelFilter.setEditLabel(false);
		} else {
			ideasLabelFilter.setSubmittingNewLabel(true);

			if (!isAuthenticated()) {
				props.isExpired();
			}

			const insdata = {
				newlabel: ideasLabelFilter.editLabelArray.name,
				labelid: ideasLabelFilter.editLabelArray.id,
			};

			//post to send request to backend
			axios
				.post(save_label + "labeledit", insdata, {
					headers: { Authorization: authString },
				})
				.then((res) => {
					console.log(res);
					let newIdeaArray = ideasFilter.listItems;
					newIdeaArray.map((listValue, index) => {
						if (listValue.ideaid == ideasLabelFilter.labelIdeaId) {
							let objIndex = listValue.label.findIndex(
								(obj) => obj.id == ideasLabelFilter.editLabelArray.id
							);

							listValue.label[objIndex].name = ideasLabelFilter.editLabelArray.name;
						}
					});

					newIdeaArray.map((ideavalue, index) => {
						ideavalue.label.filter((label) => label == "");
					});
					ideasFilter.setListItems(newIdeaArray);
					ideasLabelFilter.setSubmittingNewLabel(false);
					ideasLabelFilter.setEditLabel(false);
				})
				.catch((err) => {
					console.log(err);
					ideasLabelFilter.setSubmittingNewLabel(false);
					ideasLabelFilter.setEditLabel(false);
				});
		}
	};

	const submittoMyActions = () => {
		if (ideasModalActionsDiv.modalSelAction == "") {
			//setNewLabel(false);
		} else {
			ideasLabelFilter.setSubmittingNewLabel(true);

			if (!isAuthenticated()) {
				props.isExpired();
			}

			const ideasids = ideasFilter.listItems
				.filter((r, index) => { return ideasFilter.ideasInfo.checked[index]; })
				.map((m, index) => { return { ideaid: m.ideaid, index: index } });

			const insdata = {
				actionid: ideasModalActionsDiv.modalSelAction,
				ideasids: JSON.stringify(ideasids),
				type: "ideas"
			};

			//post
			axios
				.post(actions_post + "addMyAction", insdata, {
					headers: { Authorization: authString },
				})
				.then((res) => {
					console.log(res);
					//Ad "Action" label
					let actionids = res.data;
					let newIdeaArray = ideasFilter.listItems;
					actionids.forEach((actionid) => {
						if (newIdeaArray[parseInt(actionid.index)].actions.length === 0)
							newIdeaArray[parseInt(actionid.index)].actions.push({ id: actionid.actionid });
					});
					ideasFilter.setListItems(newIdeaArray);

					//Deselect all actions
					ideasFilter.setIdeasInfo(prevSa => {
						let { checked, ...restItem } = ideasFilter.ideasInfo;
						let newItem = { ...restItem, checked: [] };
						return newItem;
					});

					ideasLabelFilter.setSubmittingNewLabel(false);
					ideasModalActionsDiv.setaddMyActionModalValue(false);
					ideasModalActionsDiv.setmodalSelAction("");
				})
				.catch((err) => {
					console.log(err);
					ideasLabelFilter.setSubmittingNewLabel(false);

				});
		}
	}

	const submitNewAction = (e) => {
		if (ideasModalActionsDiv.modalactionTitle.current.value == "" || ideasModalActionsDiv.modalactionTitle.current.value == null) {
			//setNewLabel(false);
		} else {
			ideasLabelFilter.setSubmittingNewLabel(true);

			if (!isAuthenticated()) {
				props.isExpired();
			}

			const ideasids = ideasFilter.listItems
				.filter((r, index) => { return ideasFilter.ideasInfo.checked[index]; })
				.map((m, index) => { return { ideaid: m.ideaid, index: index } });


			const insdata = {
				title: ideasModalActionsDiv.modalactionTitle.current.value,
				description: ideasModalActionsDiv.modalactionDescription.current.value,
				department: ideasModalActionsDiv.modalactioninfo.market,
				responsible: ideasModalActionsDiv.modalactioninfo.responsible,
				completedby: ideasModalActionsDiv.modalactioninfo.compltedby.toDateString(),
				ideasids: JSON.stringify(ideasids),
				type: "ideas"
			};

			//post
			axios
				.post(actions_post + "add", insdata, {
					headers: { Authorization: authString },
				})
				.then((res) => {
					console.log(res);
					//Ad "Action" label
					let actionids = res.data;
					let newIdeaArray = ideasFilter.listItems;
					actionids.forEach((actionid) => {
						if (newIdeaArray[parseInt(actionid.index)].actions.length === 0)
							newIdeaArray[parseInt(actionid.index)].actions.push({ id: actionid.actionid });
					});
					ideasFilter.setListItems(newIdeaArray);

					//Deselect all actions
					ideasFilter.setIdeasInfo(prevSa => {
						let { checked, ...restItem } = ideasFilter.ideasInfo;
						let newItem = { ...restItem, checked: [] };
						return newItem;
					});

					ideasLabelFilter.setSubmittingNewLabel(false);
					ideasModalActionsDiv.setaddActionModalValue(false);
					let { title, description, market, responsible, ...restItem } = ideasModalActionsDiv.modalactioninfo;
					ideasModalActionsDiv.setmodalactioninfo({
						title: "",
						description: "",
						market: 0,
						responsible: 0,
						...restItem
					});
				})
				.catch((err) => {
					console.log(err);
					ideasLabelFilter.setSubmittingNewLabel(false);

				});
		}
	}

	const onChangeAction = (event, type) => {
		switch (type) {
			case "Market": {
				let {market, ...restItem} = ideasModalActionsDiv.modalactioninfo;
				ideasModalActionsDiv.setmodalactioninfo({...restItem, market: event.target.value})}
				break;
			case "Responsible": {
				let {responsible, ...restItem} = ideasModalActionsDiv.modalactioninfo;
				ideasModalActionsDiv.setmodalactioninfo({...restItem, responsible: event.target.value})}
				break;
			case "completedby": {
				let {compltedby, ...restItem} = ideasModalActionsDiv.modalactioninfo;
				ideasModalActionsDiv.setmodalactioninfo({...restItem, compltedby: event})}
				break;
		}
	};

	const submitNewLabel = (e) => {
		if (ideasLabelFilter.valueLabel == null || ideasLabelFilter.valueLabel.label == "") {
			ideasLabelFilter.setAddNewLabel(false);
		} else {
			ideasLabelFilter.setSubmittingNewLabel(true);

			if (!isAuthenticated()) {
				props.isExpired();
			}

			console.log("new label", ideasLabelFilter.valueLabel);

			const insdata = {
				newlabel: ideasLabelFilter.valueLabel.label,
				labelideaid: ideasLabelFilter.labelIdeaId,
				labelid: (ideasLabelFilter.valueLabel.id !== undefined) ? ideasLabelFilter.valueLabel.id : ""
			};

			console.log(insdata);

			//post
			axios
				.post(save_label + "label", insdata, {
					headers: { Authorization: authString },
				})
				.then((res) => {
					console.log(res);
					let newIdeaArray = ideasFilter.listItems;
					newIdeaArray[ideasLabelFilter.labelIdeaIdIndex].label.push({ name: ideasLabelFilter.valueLabel.label, id: res.data });
					ideasFilter.setListItems(newIdeaArray);
					ideasLabelFilter.setSubmittingNewLabel(false);
					ideasLabelFilter.setAddNewLabel(false);
					//check if it exists already
					let newLabels = ideasFilter.ideasInfo.labelsideas;
					let labelexists = false;
					newLabels.map((labelarr, index) => {
						if (labelarr.label === ideasLabelFilter.valueLabel.label) {
							labelexists = true;
						}
					});
					if (!labelexists) {
						newLabels.push({ label: ideasLabelFilter.valueLabel.label, id: res.data + "" });
						ideasFilter.setIdeasInfo({ ...ideasFilter.ideasInfo, labelsideas: newLabels });
					}
					ideasLabelFilter.setValueLabel(null);
				})
				.catch((err) => {
					console.log(err);
					ideasLabelFilter.setSubmittingNewLabel(false);
					ideasLabelFilter.setAddNewLabel(false);
				});
		}
	};

	const deleteIdeaModal = (e, ideaid, name) => {
		ideaData.setdeleteIdeaBl(!ideaData.deleteIdeaBl);
		ideaData.setdelIdeaid(ideaid);
		ideaData.setdeleteIdeaBlName(name);
	}

	const deleteCloseModal = () => {
		ideaData.setdeleteIdeaBl(!ideaData.deleteIdeaBl);
		ideaData.setdelIdeaid(-1);
		ideaData.setdeleteIdeaBlName("");
	}

	const deleteIdea = (e) => {
		if (ideaData.delIdeaid === -1) {
			//
		} else {
			ideasLabelFilter.setSubmittingNewLabel(true);

			if (!isAuthenticated()) {
				props.isExpired();
			}

			const insdata = {
				ideaid: ideaData.delIdeaid
			};


			//post
			axios
				.post(n_comp_nly_3 + "deleteAnswer", insdata, {
					headers: { Authorization: authString },
				})
				.then((res) => {
					console.log(res);
					let newIdeaArray = ideasFilter.listItems.filter((value, index) => {
						return value.ideaid !== ideaData.delIdeaid;
					});
					ideasFilter.setIdeasInfo(prevSa => {
						let { ideas, ...restItem } = ideasFilter.ideasInfo;
						return { ...restItem, ideas: newIdeaArray };
					});
					ideasFilter.setListItems(newIdeaArray);
					ideasFilter.setIdeasFiltered(newIdeaArray);
					ideasLabelFilter.setSubmittingNewLabel(false);
					deleteCloseModal();
					ideasLabelFilter.setAddNewLabel(false);
				})
				.catch((err) => {
					console.log(err);
					ideasLabelFilter.setSubmittingNewLabel(false);
					ideasLabelFilter.setAddNewLabel(false);
				});
		}
	};

	const archiveIdea = (ideaId) => {
		//open modal
		ideasModalActionsDiv.setopenArchModal(true);
	};

	const archiveIdeaReq = (e) => {
		ideasLabelFilter.setSubmittingNewLabel(true);

		if (!isAuthenticated()) {
			props.isExpired();
		}

		const ideasIds = ideasFilter.listItems
			.filter((r, index) => { return ideasFilter.ideasInfo.checked[index]; })
			.map((m, index) => { return { ideaid: m.ideaid, index: index } });

		const insData = {
			ideasids: JSON.stringify(ideasIds),
			archive: !URLarchived ? 0 : 1
		};

		//post
		axios
			.post(n_comp_nly_3 + "archive", insData, {
				headers: { Authorization: authString },
			})
			.then((res) => {
				console.log(res);
				//Remove ideas from list
				let newIdeaArray = ideasFilter.listItems.filter((value, index) => {
					return !ideasFilter.ideasInfo.checked[index];
				});
				ideasFilter.setIdeasInfo(prevSa => {
					let { ideas, checked, ...restItem } = ideasFilter.ideasInfo;
					return { ...restItem, ideas: newIdeaArray, checked: new Array(newIdeaArray.length).fill(false) };
				});
				ideasModalActionsDiv.setnonCheckedideas(true);
				ideasFilter.setListItems(newIdeaArray);
				ideasFilter.setIdeasFiltered(newIdeaArray);
				ideasLabelFilter.setSubmittingNewLabel(false);
				ideasModalActionsDiv.setopenArchModal(false);
			})
			.catch((err) => {
				console.log(err);
				ideasLabelFilter.setSubmittingNewLabel(false);

			});
	}

	const filterSectionsMod = (event) => {
		ideasFilter.setCurrentModule(event.target.value);
	};

	function filter_country_multichoice(selectedList, selectedItem) {
		ideasFilter.setCurrentCountry(selectedList);
	}

	const filterSectionsDep = (event) => {
		ideasFilter.setCurrentDepartment(event.target.value);
	};

	async function filter_results() {
		ideasFilter.setIdeasInfo({
			...ideasFilter.ideasInfo,
			loadingfirst: true,
		});
		fetchDataForIdeasList(false, ideasFilter.currentDepartment, ideasFilter.currentCountry);
	}

	if (ideasFilter.listItems.length > 5) {
		const filterCustom = createFilterOptions({
			ignoreCase: true,
		});
		return (

			<PageWrapper
				activeOverlay={ideasFilter.ideasInfo.loadingfirst}
			>
				<div className={classes.containerAnalytics} id="testscroll">
					<h2 className={classes.titleHeader} style={URLarchived ? { marginLeft: "30px" } : {}}>{t("Ideas and comments")}{URLarchived && ": "+t("Archived")}</h2>
					<div className={classes.cardPaddingRight}>
						<AnalyticsIdeas_Header
							nideas={ideasFilter.ideasFiltered.length}
							nparticipants={ideasFilter.ideasInfo.nparticipants}
							departments={ideasDepartmentFilter.departments}
							currentDepartment={ideasFilter.currentDepartment}
							ideas={ideasFilter.listItems}
							showactive={ideasFilter.showactiveEmployees}
							ideas2={ideasFilter.ideasFiltered}
							isArchived={URLarchived}
						/>
					</div>
					<div style={{ margin: "50px 0 10px 0" }}>
						<AnalyticsFilterBar
							modules={ideasModuleFilter.modules}
							labels={ideasFilter.ideasInfo.labelsideas}
							currentLabel={ideasFilter.currentLabel}
							searchVal={ideasFilter.searchVal}
							onChange={AnalyticsIdeasHandleFilter}
							addActionModal={addActionModal}
							addMyActionModal={addMyActionModal}
							setsearch={testingSetSearch}
							currentModule={ideasFilter.currentModule}
							searchWord={search}
							clearFilters={AnalyticsIdeasHandleFilter}
							departments={ideasDepartmentFilter.departments}
							currentDepartment={ideasFilter.currentDepartment}
							onChangeDep={AnalyticsIdeasHandleFilter}
							nonCheckedideas={ideasModalActionsDiv.nonCheckedideas}
							isCustomManager={isCustomManager}
							archiveIdea={archiveIdea}
							isArchived={URLarchived}
							countries={ideasCountryFilter.countries}
							filterSectionsCountry={filter_country_multichoice}
							multiselectRef={ideasFilter.multiselectRef}
							currentCountry={ideasCountryFilter.selectedCountry}
							filterSectionsDep={filterSectionsDep}
							searchFilter={filter_results}
						/>
					</div>
					<div style={{ margin: "0 0 50px 0" }}>
						<AnalyticsIdeas_cards
							ideas={ideasFilter.listItems}
							nideas={ideaData.nideasSt}
							selectedIdeas={ideasFilter.ideasInfo.checked}
							handleChangeCheckbox={handleChangeCheckbox}
							addLabelModal={addLabelModal}
							deleteLabelModal={deleteLabelModal}
							editLabelModal={editLabelModal}
							departments={ideasDepartmentFilter.departments}
							deleteIdeaModal={deleteIdeaModal}
							isArchived={URLarchived}
						/>
						<div
							id="loadinggd2"
							style={isFetching ? { display: "block" } : { display: "none" }}
						>
							<img
								className="responsive"
								src={loadinggif}
								width="25"
								alt=""
								style={{ marginBottom: "20px" }}
							/>
						</div>
					</div>
					<Modal
						id="4"
						show={ideasLabelFilter.addNewLabel}
						onHide={() => {
							ideasLabelFilter.setAddNewLabel(false);
							ideasLabelFilter.setTextLabel("");
							ideasLabelFilter.setLabelIdeaId(-1);
						}}
						centered
						size="md"
						className={classes.modalBox}
					>
						<Modal.Body>
							<IconButton
								onClick={() => {
									ideasLabelFilter.setAddNewLabel(false);
									ideasLabelFilter.setTextLabel("");
									ideasLabelFilter.setLabelIdeaId(-1);
								}}
								disabled={ideasLabelFilter.submittingNewLabel}
								className={classes.modalCancelButton}
								size="large">
								<img
									src={icon7}
									alt="cancel icon"
									style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							<div style={{ padding: "2rem" }}>
								<h5 style={{ fontWeight: "bold", marginTop: "20px" }}>
									{t("Add a new label") + ":"}
								</h5>
								<div
									id="ideabox"
									className={
										!ideasModalActionsDiv.showideabox ? classes.showElement : classes.hideElement
									}
								>
									<Form>
										{/*<TextField
										label=" "
										className={classes.textField}
										margin="normal"
										placeholder=" "
										fullWidth
										onChange={(e) => handleChange_text(e)}
										value={textLabel}
									/>
									<ActionButton
										onClick={(e) => submitNewLabel(e)}
										style={{ float: "right" }}
										disabled={ideasLabelFilter.submittingNewLabel}
										className={classes.submitButton}
									>
										{t("Submit")}
							</ActionButton>*/}

										<Autocomplete
											value={ideasLabelFilter.valueLabel}
											onChange={(event, newValue) => {
												if (typeof newValue === 'string') {
													ideasLabelFilter.setValueLabel({
														label: newValue,
													});
												} else if (newValue && newValue.inputValue) {
													// Create a new value from the user input
													ideasLabelFilter.setValueLabel({
														label: newValue.inputValue,
													});
												} else {
													ideasLabelFilter.setValueLabel(newValue);
												}
											}}
											filterOptions={(options, params) => {
												const filtered = filterCustom(options, params);

												const { inputValue } = params;
												// Suggest the creation of a new value
												const isExisting = options.some((option) => inputValue === option.label);
												if (inputValue !== '' && !isExisting) {
													filtered.push({
														inputValue,
														label: `Add "${inputValue}"`,
													});
												}

												return filtered;
											}}
											selectOnFocus
											clearOnBlur
											handleHomeEndKeys
											id="new-label-idea-modal"
											options={ideasFilter.ideasInfo.labelsideas}
											getOptionLabel={(option) => {
												// Value selected with enter, right from the input
												if (typeof option === 'string') {
													return option;
												}
												// Add "xxx" option created dynamically
												if (option.inputValue) {
													return option.inputValue;
												}
												// Regular option
												return option.label;
											}}
											renderOption={(props, option) => <li {...props}>{option.label}</li>}
											sx={{ width: "100%" }}
											renderInput={(params) => (
												<TextField
													onKeyDown={(ev) => {
														if (ev.key === 'Enter') {
															// Do code here
															ev.defaultMuiPrevented = true;
															ev.preventDefault();
															ev.stopPropagation();
														}
													}}
													{...params}
													label={t("Write label here")} />
											)}
										/>
										<ActionButton
											onClick={(e) => submitNewLabel(e)}
											style={{ float: "right" }}
											disabled={ideasLabelFilter.submittingNewLabel}
											className={classes.submitButton}
										>
											{t("Submit")}
										</ActionButton>
									</Form>
								</div>
								{ideasLabelFilter.submittingNewLabel && (
									<div style={{ paddingTop: "20px" }}>
										<img
											className="responsive"
											src={loadinggif}
											width="25"
											alt=""
										/>
									</div>
								)}
								<div
									style={{
										margin: "30px 0 60px 0",
										textAlign: "center",
										position: "relative",
										zIndex: "9999",
									}}
									className={
										"responsive " +
										(ideasModalActionsDiv.showideabox ? classes.showElement : classes.hideElement)
									}
								>
									<h5>{t("Label created")}</h5>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						id="4"
						show={ideasLabelFilter.editLabel}
						onHide={() => {
							ideasLabelFilter.setEditLabel(false);
							ideasLabelFilter.setLabelIdeaId(-1);
						}}
						centered
						size="sm"
						className={classes.modalBox}
					>
						<Modal.Body>
							<IconButton
								onClick={() => {
									ideasLabelFilter.setEditLabel(false);
									ideasLabelFilter.setLabelIdeaId(-1);
								}}
								disabled={ideasLabelFilter.submittingNewLabel}
								className={classes.modalCancelButton}
								size="large">
								<img
									src={icon7}
									alt="cancel icon"
									style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							<div style={{ padding: "2rem" }}>
								<h5 style={{ fontWeight: "bold", marginTop: "20px" }}>
									{t("Edit Existing label") + ":"}
								</h5>
								<div
									id="ideabox"
									className={
										!ideasModalActionsDiv.showideabox ? classes.showElement : classes.hideElement
									}
								>
									<Form>
										<TextField
											label=" "
											className={classes.textField}
											margin="normal"
											placeholder=" "
											fullWidth
											onChange={(e) => handleChange_editText(e)}
											value={ideasLabelFilter.editLabelArray.name}
										/>
										<ActionButton
											onClick={(e) => submitEditLabel(e)}
											style={{ float: "right" }}
											disabled={ideasLabelFilter.submittingNewLabel}
											className={classes.submitButton}
										>
											{t("Submit")}
										</ActionButton>
									</Form>
								</div>
								{ideasLabelFilter.submittingNewLabel && (
									<div style={{ paddingTop: "20px" }}>
										<img
											className="responsive"
											src={loadinggif}
											width="25"
											alt=""
										/>
									</div>
								)}
								<div
									style={{
										margin: "30px 0 60px 0",
										textAlign: "center",
										position: "relative",
										zIndex: "9999",
									}}
									className={
										"responsive " +
										(ideasModalActionsDiv.showideabox ? classes.showElement : classes.hideElement)
									}
								>
									<h5>{t("Label created")}</h5>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						id="4"
						show={ideasLabelFilter.deleteLabel}
						onHide={() => {
							ideasLabelFilter.setDeleteLabel(false);
							ideasLabelFilter.setLabelIdeaId(-1);
						}}
						centered
						size="sm"
						className={classes.modalBox}
					>
						<Modal.Body>
							<IconButton
								onClick={() => {
									ideasLabelFilter.setDeleteLabel(false);
									ideasLabelFilter.setLabelIdeaId(-1);
								}}
								disabled={ideasLabelFilter.submittingNewLabel}
								className={classes.modalCancelButton}
								size="large">
								<img
									src={icon7}
									alt="cancel icon"
									style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							<div style={{ padding: "2rem" }}>
								<h5 style={{ fontWeight: "bold", marginTop: "20px" }}>
									{t("Are you sure you want to delete  ") +
									ideasLabelFilter.deleteLabelValue +
										"?"}
								</h5>
								<div
									id="ideabox"
									className={
										!ideasModalActionsDiv.showideabox ? classes.showElement : classes.hideElement
									}
								>
									<Form>
										<ActionButton
											onClick={(e) => DeleteModal(e)}
											style={{ float: "right" }}
											disabled={ideasLabelFilter.submittingNewLabel}
											className={classes.submitButton}
										>
											{t("Yes")}
										</ActionButton>
										<ActionButton
											onClick={(e) => DeleteCloseModal(e)}
											disabled={ideasLabelFilter.submittingNewLabel}
											className={classes.submitButton}
										>
											{t("No")}
										</ActionButton>
									</Form>
								</div>
								{ideasLabelFilter.submittingNewLabel && (
									<div style={{ paddingTop: "20px" }}>
										<img
											className="responsive"
											src={loadinggif}
											width="25"
											alt=""
										/>
									</div>
								)}
								<div
									style={{
										margin: "30px 0 60px 0",
										textAlign: "center",
										position: "relative",
										zIndex: "9999",
									}}
									className={
										"responsive " +
										(ideasModalActionsDiv.showideabox ? classes.showElement : classes.hideElement)
									}
								>
									<h5>{t("Label created")}</h5>
								</div>
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						id="5"
						show={ideasModalActionsDiv.addActionModalValue}
						disabled={ideasLabelFilter.submittingNewLabel}
						onHide={() => {
							ideasModalActionsDiv.setaddActionModalValue(false);
						}}
						centered
						size="xl"
						className={classes.modalBox}
					>
						<Modal.Body>
							<IconButton
								onClick={() => {
									ideasModalActionsDiv.setaddActionModalValue(false);
								}}
								disabled={ideasLabelFilter.submittingNewLabel}
								className={classes.modalCancelButton}
								size="large">
								<img
									src={icon7}
									alt="cancel icon"
									style={{ width: "35px", height: "35px" }}
								/>
							</IconButton>
							<div>
								<h5
									style={{
										fontWeight: "bold",
										marginTop: "20px",
										paddingLeft: "20px",
									}}
								>
									{t("Create Action")}
								</h5>
								<Divider />
								<div style={{ padding: "0 20px" }}>
									<Form>
										<span
											style={{
												paddingTop: "10px",
												marginBottom: "0px",
												fontWeight: 700,
											}}
										>
											<strong>
												{" "}
												<h4 className={classes.formTitle} style={{ margin: "25px 0 5px 0" }}>{"From the following ideas: "}</h4>
											</strong>
										</span>
										<div className={classes.sourceBox} >
											<List>{ideasModalActionsDiv.modalideasdiv}</List>
										</div>
										<div>
											<h4 className={classes.formTitle} style={{ margin: "5px 0" }}>{"Title: "}</h4>
											<Form.Control
												as="textarea"
												rows="1"
												ref={ideasModalActionsDiv.modalactionTitle}
												className={classes.textarea1} />
										</div>
										<div>
											<h4 className={classes.formTitle} style={{ margin: "5px 0" }}>{"Description: "}</h4>
											<TextareaAutosize
												minRows="2"
												maxRows="3"
												className={classes.textareaAutoSize}
												ref={ideasModalActionsDiv.modalactionDescription}
												style={{ borderRadius: "0" }}
											/>
										</div>
										<div style={{ margin: "25px 0 0 0" }}>
											<span className={classes.formTitle + " gMedium"} style={{ margin: "5px 0 0" }}>{"Action for: "}</span>
											<span>
												{" "}
												<DropDown
													value={ideasModalActionsDiv.modalactioninfo.market}
													analytics={true}
													paddingShort={true}
													fullWidth={false}
													otherClasses={classes.doubleLineDD2}
													style={{
														width: "200px",
														color: "#009df0",
														fontWeight: "700",
													}}
													onChange={(e) => onChangeAction(e, "Market")}
												>
													<MenuItem key={0} value={0}>
														{"All"}
													</MenuItem>
													{ideasDepartmentFilter.departments.map((item) => (
														<MenuItem key={item.id} value={parseInt(item.id)}>
															{item.name}
														</MenuItem>
													))}
												</DropDown>
											</span>
										</div>
										<div style={{ margin: "25px 0 0 0" }}>
											<span className={classes.formTitle + " gMedium"} >{"Responsible: "}</span>
											<span>
												{" "}
												<DropDown
													value={ideasModalActionsDiv.modalactioninfo.responsible}
													analytics={true}
													paddingShort={true}
													fullWidth={false}
													otherClasses={classes.doubleLineDD2}
													style={{
														width: "200px",
														color: "#009df0",
														fontWeight: "700",
													}}
													onChange={(e) => onChangeAction(e, "Responsible")}>
													<MenuItem key={0} value={0}>
														{"Unassigned"}
													</MenuItem>
													{Object.keys(ideasFilter.managers).map(key =>
														<MenuItem key={key} value={key} name={key}>
															{ideasFilter.managers[key].first_name + " " + ideasFilter.managers[key].last_name}
														</MenuItem>
													)}
												</DropDown>
											</span>
										</div>
										<div style={{ display: "flex", margin: "25px 0 0 0", alignItems: "center" }}>
											<span className={classes.formTitle + " gMedium"}>{"Complete by: "}</span>
											<span className={classes.datepicker}>
												<DatePicker
													className={classes.datepickercl}
													format={"dd-MM-y"}
													onChange={(date) => onChangeAction(date, "completedby")}
													value={ideasModalActionsDiv.modalactioninfo.compltedby} />
											</span>
										</div>
										<div>
											<ActionButton
												onClick={(e) => submitNewAction(e)}
												style={{ float: "right" }}
												disabled={ideasLabelFilter.submittingNewLabel}
												className={classes.submitButton}
											>
												{t("Submit")}
											</ActionButton>
										</div>
									</Form>
									{ideasLabelFilter.submittingNewLabel && (
										<div style={{ paddingTop: "20px", textAlign: "right" }}>
											<img
												style={{ marginRight: "20px" }}
												src={loadinggif}
												width="25"
												alt=""
											/>
										</div>
									)}
								</div>
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						id="6"
						show={ideasModalActionsDiv.addMyActionModalValue}
						onHide={() => {
							ideasModalActionsDiv.setaddMyActionModalValue(false);
						}}
						centered
						size="lg"
						className={classes.modalBox}
					>
						<Modal.Body>
							<IconButton
								onClick={() => {
									ideasModalActionsDiv.setaddMyActionModalValue(false);
								}}
								disabled={ideasLabelFilter.submittingNewLabel}
								className={classes.modalCancelButton}
								size="large">
								<img
									src={icon7}
									alt="cancel icon"
									style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							<div style={{ padding: "2rem" }}>
								<h5
									style={{
										fontWeight: "bold",
										marginTop: "20px",
									}}
								>
									{t("Add to My Actions")}
								</h5>
								<Divider />
								<div>
									<Form>
										<p style={{ margin: "20px 0" }}>{"Select the action you want to add the Idea/comment to:"}</p>
										<div style={{ maxHeight: "500px", overflowY: "scroll" }}>
											<RadioGroup
												aria-labelledby="demo-controlled-radio-buttons-group"
												name="controlled-radio-buttons-group"
												value={ideasModalActionsDiv.modalSelAction}
												onChange={handleChangeMyActions}
												className={classes.actionRadios}
											>
												{ideasModalActionsDiv.listActionsdiv}
											</RadioGroup>
										</div>
										<div>
											<ActionButton
												onClick={(e) => submittoMyActions(e)}
												style={{ float: "right" }}
												disabled={ideasLabelFilter.submittingNewLabel}
												className={classes.submitButton}
											>
												{t("Add to Action")}
											</ActionButton>
										</div>
									</Form>
								</div>
								{ideasLabelFilter.submittingNewLabel && (
									<div style={{ paddingTop: "20px" }}>
										<img
											className="responsive"
											src={loadinggif}
											width="25"
											alt=""
										/>
									</div>
								)}
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						id="7"
						show={ideaData.deleteIdeaBl}
						onHide={() => {
							ideaData.setdeleteIdeaBl(false);
							ideaData.setdelIdeaid(-1);
						}}
						centered
						size="md"
						disabled={ideasLabelFilter.submittingNewLabel}
						className={classes.modalBox}
					>
						<Modal.Body>
							<IconButton
								onClick={() => {
									ideaData.setdeleteIdeaBl(false);
									ideaData.setdelIdeaid(-1);
								}}
								disabled={ideasLabelFilter.submittingNewLabel}
								className={classes.modalCancelButton}
								size="large">
								<img
									src={icon7}
									alt="cancel icon"
									style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							<div style={{ padding: "2rem" }}>
								<p style={{ fontWeight: "bold", marginTop: "20px" }}>
									{t("Are you sure you want to delete idea") +": \"" +
									ideaData.deleteIdeaBlName +
										"\"?"}
								</p>
								<div
									id="ideabox"
								>
									<Form>
										<ActionButton
											onClick={(e) => deleteIdea(e)}
											style={{ float: "right" }}
											disabled={ideasLabelFilter.submittingNewLabel}
											className={classes.submitButton}
										>
											{t("Yes")}
										</ActionButton>
										<ActionButton
											onClick={(e) => deleteCloseModal(e)}
											disabled={ideasLabelFilter.submittingNewLabel}
											className={classes.submitButton}
										>
											{t("No")}
										</ActionButton>
									</Form>
								</div>
								{ideasLabelFilter.submittingNewLabel && (
									<div style={{ paddingTop: "20px" }}>
										<img
											className="responsive"
											src={loadinggif}
											width="25"
											alt=""
										/>
									</div>
								)}
							</div>
						</Modal.Body>
					</Modal>
					<Modal
						id="7"
						show={ideasModalActionsDiv.openArchModal}
						onHide={() => {
							ideasModalActionsDiv.setopenArchModal(false);
						}}
						centered
						size="md"
						className={classes.modalBox}
					>
						<Modal.Body>
							<IconButton
								onClick={() => {
									ideasModalActionsDiv.setopenArchModal(false);
								}}
								disabled={ideasLabelFilter.submittingNewLabel}
								className={classes.modalCancelButton}
								size="large">
								<img
									src={icon7}
									alt="cancel icon"
									style={{ width: "40px", height: "40px" }}
								/>
							</IconButton>
							<div style={{ padding: "2rem" }}>
								<h5 style={{ fontWeight: "bold", marginTop: "20px" }}>
									{!URLarchived && t("Are you sure you want to archive the selected ideas/comments?")}
									{URLarchived && t("Are you sure you want to unarchive the selected ideas/comments?")}
								</h5>
								<div
									id="ideabox"
									className={classes.showElement}
								>
									<Form>
										<ActionButton
											onClick={(e) => archiveIdeaReq(e)}
											style={{ float: "right" }}
											disabled={ideasLabelFilter.submittingNewLabel}
											className={classes.submitButton}
										>
											{t("Yes")}
										</ActionButton>
										<ActionButton
											onClick={(e) => {
												ideasModalActionsDiv.setopenArchModal(false);
											}}
											disabled={ideasLabelFilter.submittingNewLabel}
											className={classes.submitButton}
										>
											{t("No")}
										</ActionButton>
									</Form>
								</div>
								{ideasLabelFilter.submittingNewLabel && (
									<div style={{ paddingTop: "20px" }}>
										<img
											className="responsive"
											src={loadinggif}
											width="25"
											alt=""
										/>
									</div>
								)}
							</div>
						</Modal.Body>
					</Modal>
				</div>
			</PageWrapper>
		);
	} else {
		return (
			<PageWrapper
				activeOverlay={ideasFilter.ideasInfo.loadingfirst}
			>
				<div className={classes.containerAnalytics} id="testscroll">
					<h2 className={classes.titleHeader} style={URLarchived ? { marginLeft: "30px" } : {}}>{"Ideas and comments"}{URLarchived && ": Archived"}</h2>
					<div style={{ marginRight: "30px" }}>
						<AnalyticsIdeas_Header
							nideas={ideasFilter.ideasFiltered.length}
							nparticipants={ideasFilter.ideasInfo.nparticipants}
							departments={ideasDepartmentFilter.departments}
							ideas2={ideasFilter.listItems}
							showactive={ideasFilter.showactiveEmployees}
							ideas={ideasFilter.ideasFiltered}
							currentDepartment={ideasFilter.currentDepartment}
							onChange={AnalyticsIdeasHandleFilter}
							isArchived={URLarchived}
						/>
					</div>
					<div style={{ margin: "50px 0 0px 0" }}>
						<AnalyticsFilterBar
							modules={ideasModuleFilter.modules}
							labels={ideasFilter.ideasInfo.labelsideas}
							currentLabel={ideasFilter.currentLabel}
							setCurrentLabel={ideasFilter.setCurrentLabel}
							searchVal={ideasFilter.searchVal}
							onChange={AnalyticsIdeasHandleFilter}
							addActionModal={addActionModal}
							addMyActionModal={addMyActionModal}
							setsearch={testingSetSearch}
							currentModule={ideasFilter.currentModule}
							setCurrentModule={ideasFilter.setCurrentModule}
							searchWord={search}
							clearFilters={AnalyticsIdeasHandleFilter}
							ideaFilters={ideasFilter}
							departments={ideasDepartmentFilter.departments}
							currentDepartment={ideasFilter.currentDepartment}
							onChangeDep={AnalyticsIdeasHandleFilter}
							nonCheckedideas={ideasModalActionsDiv.nonCheckedideas}
							isCustomManager={isCustomManager}
							archiveIdea={archiveIdea}
							isArchived={URLarchived}
							countries={ideasCountryFilter.countries}
							filterSectionsCountry={filter_country_multichoice}
							multiselectRef={ideasFilter.multiselectRef}
							currentCountry={ideasCountryFilter.selectedCountry}
							filterSectionsDep={filterSectionsDep}
							searchFilter={filter_results}
						/>
					</div>
					<div style={{ margin: "0 0 50px 0", textAlign: "center" }}>
						<span>{t("No results")}</span>
					</div>
				</div>
			</PageWrapper>
		);
	}
}
export default withStyles(
	(theme) => ({
		...analyticsStyle(theme),
		...useStyles(theme),
		...guidesStyle(theme)
	}),
	{ withTheme: true }
)(AnalyticsIdeas);
