import React from 'react';

const SwedbankTermsAndConditionsDA = () => {
    const styles = {
        container: {
          maxHeight: "100%",
            maxWidth: '800px',
            margin: '0 auto',
            padding: '32px',
            fontFamily: 'sans-serif',
            lineHeight: '1.75'
        },
        heading2: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            marginTop: '32px'
        },
        heading3: {
            fontSize: '1.125rem',
            fontWeight: 'bold',
            marginTop: '16px'
        },
        paragraph: {
            marginTop: '16px'
        },

        innerParagraph:{
            marginTop: '16px',
            marginLeft: '24px'
        },

        link: {
            color: '#3b82f6',
            textDecoration: 'underline'
        }
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.heading2}>
                Oplysninger om behandling af dine personoplysninger
            </h2>
            <h3 style={styles.heading3}>
                1. Vi er den dataansvarlige - hvordan kontakter du os?      
            </h3>
            <p style={styles.paragraph}>
               Rambøll Management Consulting ejer og driver platformen Incept Sustainability Platform (her-efter kaldet ”Platformen”). 
            </p>
            <p style={styles.paragraph}>
                Platformen er stillet til rådighed for Swedbank, som kan stille Platformen til rådighed for orga-nisationer med en aktiv tilknytning til ejendomsbranchen i Danmark,
                 så disse organisationer ligeledes kan bruge Platformen.
            </p>

            <p style={styles.paragraph}>
                For at kunne stille Platformen til rådighed for Swedbank, behandles dine personoplysninger i Platformen i forbindelse med brugeroprettelse.
                Denne tekst beskriver, hvordan dine personop-lysninger behandles i forbindelse med din brug af Platformen.
            </p>

            <p style={styles.paragraph}>
                Rambøll Management Consulting er dataansvarlig for denne behandling, og du er velkommen til at kontakte os, hvis du har spørgsmål til behandlingen af dine personoplysninger.
                 Du finder kontaktoplysninger nedenfor.
            </p>

            <p style={styles.paragraph}>
                Rambøll Management Consulting
                <br />
                Hannemanns Allé 53
                <br />
                2300 København 
                <br />
                CVR-nr: 60997918
                <br />
                Telefon: +45 5161 2506
                <br />
                Mail:{' '}
                <a href='mailto:PRIVACY@ramboll.com' style={styles.link}>
                    PRIVACY@ramboll.com
                </a>
            </p>

            <h3 style={styles.heading3}>
                2. Formålene med og retsgrundlaget for behandlingen af dine personop-lysninger
            </h3>
            <p style={styles.paragraph}>
                Rambøll Management Consulting er dataansvarlig for de personoplysninger (navn, titel, organi-sation, e-mailadresse),
                 du afgiver og registrerer, når du opretter en brugerprofil i Platformen.
            </p>

            <p style={styles.paragraph}>
                Rambøll Management Consulting behandler dine personoplysninger med det formål at admini-strere adgange til Platformen samt til at yde support på platformen. 
            </p>
            <p style={styles.paragraph}>
                Behandlingsgrundlaget er Rambøll Management Consultings legitime interesse i at yde en ser-vice til Swedbank og din organisation,
                 der får stillet platformen til rådighed (databeskyttelses-forordningens artikel 6, stk. 1, litra f).
            </p>

            <h3 style={styles.heading3}>
                3. Modtagere eller kategorier af modtagere
            </h3>
            <p style={styles.paragraph}>
                Vores leverandører vil kunne få teknisk adgang til dine personoplysninger i forbindelse med levering af deres services som databehandler til os.
                Det drejer sig eksempelvis om hosting og support af Platformen. Dine personoplysninger deles ikke med andre.
            </p>


            <h3 style={styles.heading3}>
                4. Overførsel til modtagere i tredjelande, herunder internationale organi-sationer
            </h3>
            <p style={styles.paragraph}>
                Dine personoplysninger bliver som udgangspunkt opbevaret og behandlet indenfor EU/EØS. 
            </p>


            <h3 style={styles.heading3}>5. Opbevaring af dine personoplysninger</h3>
            <p style={styles.paragraph}>
                Rambøll Management Consulting opbevarer dine personoplysninger indtil din brugerprofil slettes i Platformen,
                 eller indtil aftalen med Swedbank udløber. 
            </p>
            <p style={styles.paragraph}>
                Hvis din brugerprofil slettes,
                bibeholdes dine besvarelser i anonymiseret form i Platformen.
            </p>

            <h3 style={styles.heading3}>
                 6. Automatiske afgørelser, herunder profilering
            </h3>
            <p style={styles.paragraph}>
                Der anvenders ikke automatiske afgørelser, herunder profilering til behandling af dine person-oplysninger. 
            </p>

            <h3 style={styles.heading3}>7. Dine rettigheder</h3>
            <p style={styles.paragraph}>
                Du har efter databeskyttelsesforordningen en række rettigheder i forhold til behandlingen af dine personoplysninger. 
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Ret til at se oplysninger (indsigtsret)</strong>
                <br />
                Du har ret til at få indsigt i de oplysninger, som vi behandler om dig, samt en række yderligere oplysninger.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Ret til berigtigelse (rettelse)</strong>
                <br />
                Du har ret til at få urigtige oplysninger om dig selv rettet.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Ret til sletning</strong>
                <br />
                I særlige tilfælde har du ret til at få slettet oplysninger om dig, inden tidspunktet for vo-res almindelige generelle sletning indtræffer.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Ret til begrænsning af behandling</strong>
                <br />
                Du har visse tilfælde ret til at få behandlingen af dine personoplysninger begrænset. Hvis du har ret til at få begrænset behandlingen,
                må vi fremover kun behandle oplys-ningerne - bortset fra opbevaring - med dit samtykke,
                eller med henblik på at retskrav kan fastlægges, gøres gældende eller forsvares, eller for at beskytte en person eller vigtige samfundsinteresser.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Ret til indsigelse</strong>
                <br />
                Du har i visse tilfælde ret til at gøre indsigelse mod vores eller lovlige behandling af dine personoplysninger. 
                Du kan også gøre indsigelse mod behandling af dine oplysninger til direkte markedsføring.
            </p>
            <p style={styles.innerParagraph}>
                <strong>- Ret til at transmittere oplysninger (dataportabilitet)</strong>
                <br />
                Du har i visse tilfælde ret til at modtage dine personoplysninger i et struktureret,
                almin-deligt anvendt og maskinlæsbart format samt at få overført disse personoplysninger fra én dataansvarlig til en anden uden hindring.
            </p>
            <p style={styles.paragraph}>
                Du kan läsa mer om dina rettigheder i Datatilsynets vejledning om de registreredes rettigheder, som du finder på{' '}
                <a href='https://www.datatilsynet.dk' style={styles.link}>
                    www.datatilsynet.dk
                </a>
                .
            </p>
            <p style={styles.paragraph}>
                Hvis du vil gøre brug af dine rettigheder skal du kontakte os (se kontaktoplysninger under punkt 1).
            </p>

            <h3 style={styles.heading3}>
                8. Klage til Datatilsynet 
            </h3>
            <p style={styles.paragraph}>
                 Du har ret til at indgive en klage til Datatilsynet, hvis du er utilfreds med den måde, dine per-sonoplysninger behandles på. 
                 Du finder Datatilsynets kontaktoplysninger på {' '}
                <a href='https://www.datatilsynet.dk' style={styles.link}>
                    www.datatilsynet.dk
                </a>
                .
            </p>
        </div>
    );
};

export default SwedbankTermsAndConditionsDA;
