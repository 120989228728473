/*eslint-disable*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import { Form, FormGroup, Label, Input, FormText } from "reactstrap";


import { ourLightGreen, ourMediumGreen, isAuthenticated, help_email } from "assets/jss/incept-sustainability-variables.jsx";

import axios from "axios";
import jwt from "jwt-decode";

import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";

import "bootstrap/dist/css/bootstrap.min.css";
import { blackColor } from "assets/jss/material-dashboard-react";

//Buttons
import ActionButton from "components/CustomButtons/ActionButton";

//Translations
import { withTranslation } from 'react-i18next';

const style = (theme) => ({
	typo: {
		paddingLeft: "25%",
		marginBottom: "40px",
		position: "relative",
	},
	titlepackage: {
		fontFamily: '"Raleway", "Helvetica", "Arial", sans-serif',
		fontStyle: "normal",
		//marginLeft: "1.2rem"
	},
	cont: {
		margin: "0 5px !important"
	},
	lineunder: {
		display: "block",
		border: "1px solid #116628",
		marginLeft: "-60px",
		width: "50%",
		marginBottom: "40px",
		color: "rgba(0, 0, 0, 0.87)",
	},
	buttoncolor: {
		backgroundColor: "#0095a9",
		borderColor: "#0095a9",
	},
	button: {
		width: "120px",
		backgroundColor: "rgb(255, 111, 105)",
		color: "white",
		border: "1px",
		borderRadius: "20px",
		cursor: "pointer",
		fontSize: "15px",
		padding: "2px 8px",
		display: "block",
		position: "inherit",
		float: "right",
		"&:hover": {
			backgroundColor: "#FF9894",
		},
	},
	wrapper: {
		// marginTop: "-9.5rem",
		marginLeft: "4rem",
		marginRight: "4rem",
		[theme.breakpoints.down('md')]: {
			margin: "0",
		},
		border: "1px solid red",
	},
	containerother: {
		// marginTop: "3rem",
		paddingLeft: "40px",
		[theme.breakpoints.down('md')]: {
			padding: "0 25px",
		},
	},
	input: {
		borderRadius: "16px",
		border: "1.5px solid lightgray",
	},
	kickOff: {
		borderRadius: "16px",
		padding: "1rem 1rem 0 1rem",
		backgroundColor: ourLightGreen,
		marginTop: "15px",
		marginBottom: "0.8rem",
	},
	didYouKnow: {
		marginBottom: "2.2rem",
		borderRadius: "16px",
		padding: "0 1rem 2rem 1rem",
		backgroundColor: "white",
		"& p, strong": {
			color: "black",
			[theme.breakpoints.down('md')]: {
				marginRight: "0",
			},
		},
	},
	welcomeGridButton: {
		width: "120px",
		backgroundColor: "rgb(255, 111, 105)",
		color: "white",
		border: "1px",
		float: "right",
		borderRadius: "16px",
		cursor: "pointer",
		fontSize: "13px",
		padding: "2px 8px",
		display: "block",
		position: "inherit",
		marginTop: "1.5rem",
	},
	moduleCard: {
		borderRadius: "16px",
		marginBottom: "2.2rem",
	},
});

class Help extends React.Component {
	constructor(props) {
		super(props);
		var redlog = false;
		var decodedtoken = "";
		if (!isAuthenticated()) {
			redlog = true;
		}
		else
			decodedtoken = jwt(localStorage.getItem("token"));
		this.state = {
			title: "",
			description: false,
			loading: false,
			userid: (decodedtoken!="") ? decodedtoken.userid:"",
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		if (!isAuthenticated()) {
			this.props.isExpired();
		}
		if (this.state.title == "") {
			alert("Title cannot be empty");
			return;
		} else if (this.state.description == "") {
			alert("Description cannot be empty");
			return;
		}

		document.getElementById("thankyoudiv").style.display = "none";
		document.getElementById("errordiv").style.display = "none";

		const user_id = this.state.userid;
		this.setState({ loading: true });
		const formdata = {
			subject: this.state.title,
			text: this.state.description,
		};

		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;

		this.setState({ loading: true }, () => {
			axios
				.post(help_email, formdata, { headers: { Authorization: authstr } })
				.then((res) => {
					this.setState({ loading: false, title: "", description: "" });
					document.getElementById("thankyoudiv").style.display = "block";
				})
				.catch((err) => {
					if (err.response!==undefined)
						console.log(err.response.data.message);
					else 
						console.log(err);
					this.setState({ loading: false });
					document.getElementById("errordiv").style.display = "block";
				});
		});
	}
	render() {
		const { classes, t } = this.props;
		return (
			<LoadingOverlay active={this.state.loading} spinner={<BounceLoader />}>
				<h2 className={classes.titlepackage} style={{ fontFamily: "gotham-font-medium", paddingTop: "1.5rem", paddingLeft: "40px" }}> {t('Contact')} </h2>
				<div className={classes.containerother}>
					<GridContainer className={classes.cont}>
						<GridItem xs={12} sm={12} md={9} spaceLeft={0}>
							<Card className={classes.kickOff}>
								<CardBody style={{ padding: "1rem" }}>
									<p>
										{t('helptext2')}
									</p>
								</CardBody>
							</Card>
						</GridItem>
						<GridItem xs={12} sm={12} md={9} spaceLeft={0}>
							<Card className={classes.didYouKnow}>
								<CardBody>
									<Form>
										<FormGroup>
											<Label
												for="titleform"
												style={{ color: blackColor, fontWeight: "bold" }}
											>
												{t('Subject')}
											</Label>
											<Input
												className={classes.input}
												type="text"
												name="title"
												id="titleform"
												placeholder=""
												onChange={(e) =>
													this.setState({ title: e.target.value })
												}
											/>
										</FormGroup>
										<FormGroup>
											<Label
												for="textform"
												style={{ color: blackColor, fontWeight: "bold" }}
											>
												{t('Message')}
											</Label>
											<Input
												className={classes.input}
												type="textarea"
												name="text"
												id="textform"
												height="168px"
												rows="6"
												onChange={(e) =>
													this.setState({ description: e.target.value })
												}
											/>
										</FormGroup>
										<div
											id="thankyoudiv"
											style={{
												color: "green",
												marginBottom: "20px",
												display: "none",
											}}
										>
											{t('We will reach you', { defaultValue: 'Thank you for your message. We will reach back as soon as possible.' })}
										</div>
										<div
											id="errordiv"
											style={{
												color: "red",
												marginBottom: "20px",
												display: "none",
											}}
										>
											{t('There was an error', {
												defaultValue: 'There was an error, please try again or contact us at inceptsustainability@ramboll.com.'
											})}
										</div>
										<ActionButton
											style={{width:"auto"}}
											onClick={this.toggle}
										>
											{t('Submit')}
										</ActionButton>
									</Form>
								</CardBody>
							</Card>
						</GridItem>
					</GridContainer>
				</div>
			</LoadingOverlay>
		);
	}
}

Help.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withTranslation()(withStyles(style)(Help));
