import React from "react";

//Different Feedbacks
import DimensionsFeedback from "components/Feedback/DimensionsFeedback.js";

class WrapperFeedback extends React.Component {
	render(){
		const {type} = this.props;
		let   compRender = null;

		switch(type){
			case 'dimensions':
				const dimensions = [
					{
						title:'Economics', 
						elements:[
							{title:'Holaa!!!', 
							statement:'chaooo'}
						]
					},
					{
						title:'Social', 
						elements:[
							{title:'Holaa!!!', 
							statement:'chaooo'},
							{title:'Holaa!!!', 
							statement:'chaooo'},
							{title:'Holaa!!!', 
							statement:'chaooo'},
							{title:'Holaa!!!', 
							statement:'chaooo'}
						]
					},
					{
						title:'Enviromental 3', 
						elements:[
							{title:'Holaa!!!', 
							statement:'chaooo'}
						]
					}
				];
				compRender = <DimensionsFeedback dimensions={dimensions}/>
			break;
		}
		return compRender;
	}
}

export default WrapperFeedback;