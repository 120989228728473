/*!
 * Description: this file contains the main base component for
 *  the rendering the main page view of analytics
 *
 * Components: Analytics_New
 */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material components
import withStyles from "@mui/styles/withStyles";
// core components
import Grid from "@mui/material/Grid";
import Modal from '@mui/material/Modal';
import {Link } from "react-router-dom";
//data
// import data from './data.json';
import pencil from 'assets/img/pencil.png'
import analyticsinfo from 'assets/img/analytics-info.png'
import ResponsiveLine from "components/Nivo/Line.jsx";

import {
    n_comp_pck, ourGreen, ourDarkGreen, ourBlueLight,
    ourBlue, isAuthenticated,ourLightGreen,
} from "assets/jss/incept-sustainability-variables.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";
//import { ResponsiveLine } from '@nivo/line'
import FakeButton from "components/CustomButtons/FakeButton";
import ProgressButton from 'components/CustomButtons/Progressbutton.jsx'
import ActionButton from 'components/CustomButtons/ActionButton';
//table
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import Typography from "@mui/material/Typography";
//styles
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import { useMediaQuery } from 'react-responsive';

import "react-circular-progressbar/dist/styles.css";
import ActiveEmployees_tooltip from "components/Tooltip/ActiveEmployees_tooltip";
//Translate
import { withTranslation, Trans, useTranslation } from 'react-i18next';
import i18n from "i18next";
import i18next from "i18next";
//commentBox
import CommentBubble from "components/ContentBox/CommentBubble";

const useStyles = (theme) => ({
    smallButton: ({ color, textColor }) => ({
        backgroundColor: color,
        color: textColor,
        border: "0px",
        borderRadius: "20px",
        fontSize: "14px",
        padding: "5px 30px",
        width: "100%"
    }),
    quoteText: {
        fontSize: "0.875rem",
        "&:before": {
            fontSize: "1.5rem",
            lineHeight: "0.5rem",
        },
        "&:after": {
            fontSize: "1.5rem",
            lineHeight: "0.5rem",
        },
    }
});

function withMyHook(Component) {
    return function WrappedComponent(props) {
        const mobile = useMediaQuery({ query: `(max-width: 1024px)` });
        const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
        return <Component {...props} isMobile={mobile} isTablet={isTablet} />;
    }
}
function Dashboard_manager(props) {
    const { classes, content } = props;

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { t, i18n } = useTranslation();

    const ideaList = props.data.ideas.map((idea, index) => {
        if (index>0)
            return;
        return (
                <span key={index} className={classes.quoteText}>{idea+'"'}</span>
        );
    });

    const activeModules = props.modulesInfo.map((module, index) => {
        return (
            <TableRow
                key={index}
            >
                <TableCell component="th" scope="row" className={classes.completionRowTh} >
                    {module.title}
                </TableCell>
                <TableCell className={classes.completionRow}  style={{width: "80px"}} >
                    <FakeButton
                        style={{
                            width: "80px"
                        }}
                        color={ourBlueLight}
                        textColor={ourBlue}
                        atooltip={true}
                        textTooltip={"Number of employees that have completed this module"}
                        content={module.completed} />
                </TableCell>
                <TableCell style={{ color: '#add095',width: "80px" }} component="th" scope="row" 
                className={classes.completionRowTh2} >
                <FakeButton
                        style={{
                            width: "80px"
                        }}
                        color={ourBlue}
                        textColor={"white"}
                        content={module.completed_today} />
                    </TableCell>
            </TableRow>
        );
    });

    return (
            <Grid>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} space={0} >
                    <h2 className={classes.titleHeader}>{props.username}</h2>

                    </GridItem>
                </GridContainer>
				{/*Maintenance <GridItem xs={12} sm={12} md={9}>
								<p style={{marginTop:"-25px",marginBottom:"30px",fontSize:"0.9rem",paddingLeft:"1px",backgroundColor:"#FFB899"}}>
								{"On 29 January 2023 from 10:00 CET to 16:00 CET, this portal will be briefly unavailable due to a software update."}</p>
							</GridItem>*/}
                <GridContainer style={{marginBottom:"15px"}}>
                    <GridItem xs={12} sm={12} md={12} lg={6} space={0} spaceNoPadding={true}  >
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12} spaceLeft={0} style={(props.isMobile || props.isTablet) ? { marginBottom: "30px" } : {}}>
                        <CardAnalytics style={{ height: "100%" }}>
                            <CardAnalyticsHeader device={props.isMobile ? 'dashmobile' : ''} title={t("General overview")} >
                                <Link className={props.isMobile ? classes.Link_color : classes.Link_style} to={"/admin/analytics_completion"}>
                                {t("View all completion data")}
                                </Link>
                            </CardAnalyticsHeader>
                            <CardAnalyticsBody style={{paddingTop:"0"}}>
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12} space={0} spaceNoPadding={true}>
                                        <TableContainer >
                                            <Table aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className={classes.completionRowTh}></TableCell>
                                                        <TableCell className={classes.completionRowTh}></TableCell>
                                                        <TableCell className={classes.completionRowTh}></TableCell>
                                                        <TableCell  style={{fontWeight:'800',textAlign:'center',padding: "21px 16px 5px"}} 
                                                        className={classes.completionRowTh}>Today</TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        key={"All time registered employees"}
                                                    >
                                                        <TableCell component="th" scope="row" className={classes.completionRowTh2} >
                                                            {"All time registered employees:"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow} style={{width: "80px"}} >
                                                        </TableCell>
                                                        <TableCell className={classes.completionRowTh} style={{width: "80px"}} >
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}
                                                                
                                                                color={ourBlueLight}
                                                                textColor={ourBlue}
                                                                content={props.data.registeredUsers} />
                                                        </TableCell>
                                                        <TableCell style={{ color: '#add095' ,width: "80px"}} component="th" scope="row"
                                                        className={classes.completionRowTh2} >
                                                        <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}
                                                                color={ourBlue}
                                                                textColor={"white"}
                                                                content={props.data.allusers_today} />
                                                            </TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        key={"Current registered employees"}
                                                    >
                                                        <TableCell component="th" scope="row" className={classes.completionRowTh2} >
                                                            {"Current registered employees:"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow} style={{width: "80px"}} >
                                                        </TableCell>
                                                        <TableCell className={classes.completionRowTh} style={{width: "80px"}} >
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}
                                                                
                                                                color={ourBlueLight}
                                                                textColor={ourBlue}
                                                                content={props.data.total_users} />
                                                        </TableCell>
                                                        <TableCell style={{ color: '#add095' ,width: "80px"}} component="th" scope="row"
                                                        className={classes.completionRowTh2} >
                                                        <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}
                                                                color={ourBlue}
                                                                textColor={"white"}
                                                                content={props.data.allusers_today} />
                                                            </TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        key={"All time active employees"}
                                                    >
                                                        <TableCell component="th" scope="row" className={classes.completionRowTh2}>
                                                            {"All time active employees:"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={"white"}
                                                                border={"1"}
                                                                atooltip={true}
                                                                textTooltip={t("% of the registered employees that have started at least 1 module")}
                                                                borderColor={ourBlue}
                                                                textColor={ourBlue}
                                                                content={props.data.p_started_alltime_modules+"%"} />
                                                        </TableCell>
                                                        <TableCell className={classes.completionRowTh}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={ourBlueLight}
                                                                textColor={ourBlue}
                                                                content={props.data.nusersAllTime} />
                                                        </TableCell>
                                                        <TableCell style={{ color: '#add095' }} component="th" 
                                                        scope="row" className={classes.completionRowTh2} >
                                                        <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={ourBlue}
                                                                textColor={"white"}
                                                                content={props.data.nusers_today} />
                                                            </TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        key={"Current active employees"}
                                                    >
                                                        <TableCell component="th" scope="row" className={classes.completionRowTh2}>
                                                            {"Current active employees:"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={"white"}
                                                                border={"1"}
                                                                atooltip={true}
                                                                textTooltip={t("% of the registered employees that have started at least 1 module")}
                                                                borderColor={ourBlue}
                                                                textColor={ourBlue}
                                                                content={props.data.p_started_modules+"%"} />
                                                        </TableCell>
                                                        <TableCell className={classes.completionRowTh}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={ourBlueLight}
                                                                textColor={ourBlue}
                                                                content={props.data.nusers} />
                                                        </TableCell>
                                                        <TableCell style={{ color: '#add095' }} component="th" 
                                                        scope="row" className={classes.completionRowTh2} >
                                                        <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={ourBlue}
                                                                textColor={"white"}
                                                                content={props.data.nusers_today} />
                                                            </TableCell>
                                                    </TableRow>
                                                    <TableRow
                                                        key={"Completed employees (all modules):"}
                                                    >
                                                        <TableCell component="th" scope="row" className={classes.completionRowTh2}>
                                                          
                                                      <ActiveEmployees_tooltip tooltipid={"someid"} />{t("that completed 1 module")+":"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={"white"}
                                                                border={"1"}
                                                                borderColor={ourBlue}
                                                                textColor={ourBlue}
                                                                atooltip={true}
                                                                textTooltip={t("% of the active employees that have completed at least 1 module")}
                                                                content={props.data.pend_onemodule+"%"} />
                                                        </TableCell>
                                                        <TableCell className={classes.completionRowTh}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={ourBlueLight}
                                                                textColor={ourBlue}
                                                                content={props.data.end_one_module} />
                                                        </TableCell>
                                                        <TableCell style={{ color: '#add095' }} component="th" 
                                                        scope="row" className={classes.completionRowTh2} >
                                                        <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={ourBlue}
                                                                textColor={"white"}
                                                                content={props.data.end_onemodule_today} />
                                                            </TableCell>
                                                    </TableRow>

                                                    <TableRow
                                                        key={"Completed at least 1 module:"}
                                                    >
                                                        <TableCell component="th" scope="row" className={classes.completionRowTh2}>
                                                       <ActiveEmployees_tooltip tooltipid={"someid"} /> {t("that completed all modules")+":"}
                                                        </TableCell>
                                                        <TableCell className={classes.completionRow}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={"white"}
                                                                border={"1"}
                                                                borderColor={ourBlue}
                                                                textColor={ourBlue}
                                                                atooltip={true}
                                                                textTooltip={"% of the active employees that have completed all modules"}
                                                                content={props.data.pend_allmodule+"%"} />
                                                        </TableCell>
                                                        <TableCell className={classes.completionRowTh}>
                                                            <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={ourBlueLight}
                                                                textColor={ourBlue}
                                                                content={props.data.end_allmodule} />
                                                        </TableCell>
                                                        <TableCell style={{ color: '#add095' }} component="th" scope="row" 
                                                        className={classes.completionRowTh2} >
                                                        <FakeButton
                                                                style={{
                                                                    width: "80px"
                                                                }}

                                                                color={ourBlue}
                                                                textColor={"white"}
                                                                content={props.data.end_allmodule_today} />
                                                            </TableCell>
                                                    </TableRow>




                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </GridItem>

                                </GridContainer>
                            </CardAnalyticsBody>
                        </CardAnalytics>
                        </GridItem>
                        </GridContainer>
                       
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12} lg={6} space={0} 
                    spaceNoPadding={true}
                    style={(props.isMobile || props.isTablet) ? { marginRight: "15px" } : {}}>
                        <CardAnalytics style={{ height: "100%" }}>
                            <CardAnalyticsHeader device={props.isMobile ? 'dashmobile' : ''} title="Ideas and comments" >
                                <Link className={props.isMobile ? classes.Link_color : classes.Link_style} to={"/admin/analytics_ideas"}>
                                {t("View all")}
                                </Link>
                            </CardAnalyticsHeader>
                            <CardAnalyticsBody style={{paddingTop:"0"}}>
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12} space={0} className={classes.columnCardsPadding}>
										<Table aria-label="simple table">
										<TableBody>
                                        <TableRow
                                            key={"Total Ideas and comments"}
                                            style={{display:"table",tableLayout:"fixed"}}
                                        >
                                            <TableCell component="th" scope="row" 
                                            style={{
                                                width: "auto"
                                            }}
                                            className={classes.completionRowTh} >
                                                {"Total:"}
                                            </TableCell>
                                            <TableCell className={classes.completionRowTh2} >
                                                <FakeButton
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                    color={ourBlueLight}
                                                    textColor={ourBlue}
                                                    content={props.data.ideas_submited} />
                                            </TableCell>
                                            <TableCell component="th" scope="row" style={{paddingLeft:"50px"}}
                                            className={classes.completionRowTh} ><ActiveEmployees_tooltip tooltipid={"someid"} />{t("that submitted ideas")+":"}</TableCell>
                                            <TableCell style={{ color: '#add095' }} component="th" scope="row" className={classes.completionRowTh} >
                                            <FakeButton
                                                    style={{
                                                        width: "100%"
                                                    }}
                                                    color={ourBlueLight}
                                                    textColor={ourBlue}
                                                    content={props.data.users_ideas+"%"} />
                                                </TableCell>
                                        </TableRow>
                                        <TableRow
                                            key={"Newest"}
                                        >
                                            <TableCell component="th" scope="row" className={classes.completionRowTh} >
                                                {t("Newest")+":"}
                                            </TableCell>

                                        </TableRow>
										</TableBody>
										</Table>
                                        <CommentBubble 
										color={ourLightGreen} style={{marginTop:"10px"}} 
										borderColor={ourGreen} textColor="black" content={ideaList}/>
                                        {/* {ideaList} */}
                                    </GridItem>

                                </GridContainer>
                            </CardAnalyticsBody>
                        </CardAnalytics>
                    </GridItem>
                 
                </GridContainer>
                <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={6} spaceLeft={0}  style={(props.isMobile || props.isTablet) ? { marginBottom: "30px" } : {}}>
                        <CardAnalytics style={{ height: "100%" }}>
                            <CardAnalyticsHeader device={props.isMobile ? 'dashmobile' : ''} title={t("Active modules")+":"} >
                                <span style={{color:{ourBlue},marginLeft:'5px',fontSize:24}} to={"/admin/analytics_completion"}>
                               {props.modulesInfo.length}
                                </span>
                            </CardAnalyticsHeader>
                            <CardAnalyticsBody>
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12} lg={12} space={0} spaceNoPadding={true}>
                                        <TableContainer >
                                            <Table aria-label="simple table">
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell className={classes.completionRowTh}></TableCell>
                                                        <TableCell  style={{fontWeight:'800',textAlign:'center',width:"80px"}} 
                                                        className={classes.completionRowTh2}>{t("Completed")}</TableCell>
                                                        <TableCell  style={{fontWeight:'800',textAlign:'center',width:"80px"}} 
                                                        className={classes.completionRowTh2}>{t("Today")}</TableCell>
                                                    </TableRow>
                                                    {activeModules}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </GridItem>

                                </GridContainer>
                            </CardAnalyticsBody>
                        </CardAnalytics>
                            </GridItem>
                        {/*<GridItem xs={12} sm={12} md={12} lg={6}  space={0} spaceNoPadding={true}   
                        style={(props.isMobile || props.isTablet) ? { marginRight: "15px" } : {}}>
                        <CardAnalytics style={{ height: "100%" }}>
                            <CardAnalyticsHeader device={props.isMobile ? 'dashmobile' : ''} title="Follow Up on Action" >
                                <Link className={props.isMobile ? classes.Link_color : classes.Link_style} to={"/admin/my_actions"}>
                                {"view all actions"}
                                </Link>
                            </CardAnalyticsHeader>
                            <CardAnalyticsBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} lg={12} space={0}>
                                        <div style={{
                                            border: '1px solid #009df0', borderRadius: '7px', padding: ' 0.8rem 0rem 0rem 0.8rem',
                                            lineHeight: '35px', marginBottom: '20px'
                                        }}>  <div><b>Make list of example proposal</b></div>
                                            <div style={{
                                                display: 'flex', paddingRight: '20px'
                                            }}><b> {"Progress"+": "} </b>To do<span style={props.isMobile ? { marginLeft: 'auto', color: '#ff8855' } : { marginLeft: 'auto', color: '#ff8855' }}>Go to action</span></div></div>



                                    </GridItem>
                                </GridContainer>

                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12} space={0}>
                                        <div style={{
                                            border: '1px solid #009df0', borderRadius: '7px', padding: ' 0.8rem 0rem 0rem 0.8rem',
                                            lineHeight: '35px', marginBottom: '20px'
                                        }}>
                                            <div><b>Make list of example proposal</b></div>
                                            <div style={{ display: 'flex', paddingRight: '20px' }}><b>{"Progress"+": "} </b>To do<span style={props.isMobile ? { marginLeft: 'auto', color: '#ff8855' } : { marginLeft: 'auto', color: '#ff8855' }}>Go to action</span></div></div>
                                    </GridItem>
                                </GridContainer>


                            </CardAnalyticsBody>
                        </CardAnalytics>
                                    </GridItem>*/}
                        <GridItem xs={12} sm={12} md={12} lg={6}  space={0} spaceNoPadding={true}   
                        style={(props.isMobile || props.isTablet) ? { marginRight: "15px" } : {}}>
                        <CardAnalytics style={{ height: "100%" }}>
							<CardAnalyticsHeader device={props.isMobile ? 'dashmobile' : ''} title={t("First activity overview")} >
                                <Link className={props.isMobile ? classes.Link_color : classes.Link_style} to={"/admin/analytics_company"}>
                                {t("View all employee feedback")}
                                </Link>
                            </CardAnalyticsHeader>
                            <CardAnalyticsBody >
                                <GridContainer >
                                    <GridItem xs={12} sm={12} md={12} lg={12} space={0} spaceNoPadding={true}>
									<p style={{marginTop:"14px"}}><b>{"From Module: "}</b>{props.analytics_viewer.title_module}</p>
									<TableContainer >
											
                                            <Table aria-label="simple table">
                                                <TableBody>
													<TableRow
														key={456}
													>
														<TableCell component="th" scope="row" className={classes.completionRowTh} >
															{"Total answers of this module"}
														</TableCell>
														<TableCell className={classes.completionRow}  style={{width: "80px"}} >
															<FakeButton
																style={{
																	width: "80px"
																}}
																color={ourBlue}
                        										textColor={"white"}
																content={props.analytics_viewer.active_module} />
														</TableCell>
													</TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
											<p style={{marginTop:"2rem"}}><b>{"Activity: "}</b>{props.analytics_viewer.title_activity}</p>
									<TableContainer >
											
                                            <Table aria-label="simple table">
                                                <TableBody>
													<TableRow
														key={457}
													>
														<TableCell component="th" scope="row" className={classes.completionRowTh} >
															{"Total answers of this activity"}
														</TableCell>
														<TableCell className={classes.completionRow}  style={{width: "80px"}} >
															<FakeButton
																style={{
																	width: "80px"
																}}
																color={ourBlueLight}
																textColor={ourBlue}
																atooltip={true}
																textTooltip={"% of all active users for this module"}
																content={props.analytics_viewer.answerp_activity+"%"} />
														</TableCell>
														<TableCell className={classes.completionRow}  style={{width: "80px"}} >
															<FakeButton
																style={{
																	width: "80px"
																}}
																color={ourBlue}
                        										textColor={"white"}
																content={props.analytics_viewer.answer_activity} />
														</TableCell>
													</TableRow>
													<TableRow
														key={458}
													>
														<TableCell component="th" scope="row" className={classes.completionRowTh} >
															{"Empty submissions"}
														</TableCell>
														<TableCell className={classes.completionRow}  style={{width: "80px"}} >
															<FakeButton
																style={{
																	width: "80px"
																}}
																color={ourBlueLight}
																textColor={ourBlue}
																atooltip={true}
																textTooltip={"% of all active users for this module"}
																content={props.analytics_viewer.emptyp_activity+"%"} />
														</TableCell>
														<TableCell className={classes.completionRow}  style={{width: "80px"}} >
															<FakeButton
																style={{
																	width: "80px"
																}}
																color={ourBlue}
                        										textColor={"white"}
																content={props.analytics_viewer.empty_activity} />
														</TableCell>
													</TableRow>
													<TableRow
														key={459}
													>
														<TableCell component="th" scope="row" className={classes.completionRowTh} >
															{"Non empty submissions"}
														</TableCell>
														<TableCell className={classes.completionRow}  style={{width: "80px"}} >
															
														</TableCell>
														<TableCell className={classes.completionRow}  style={{width: "80px"}} >
															<FakeButton
																style={{
																	width: "80px"
																}}
																color={ourBlue}
                        										textColor={"white"}
																content={parseInt(props.analytics_viewer.answer_activity) - parseInt(props.analytics_viewer.empty_activity)} />
														</TableCell>
													</TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </GridItem>
                                </GridContainer>
                            </CardAnalyticsBody>
                        </CardAnalytics>
                        </GridItem><br></br>
                        </GridContainer>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12} lg={7} spaceNoPadding={true} style={props.isMobile ? { marginBottom: 30 } : { marginBottom: 30, marginLeft: '410px', marginTop: '250px' }} >
                            <CardAnalytics style={{ height: "100%" }}>
                                <CardAnalyticsHeader device={props.isMobile ? 'dashmobile' : ''} title="My goals:" >
                                    <div className={props.isMobile ? classes.Link_color : classes.Link_style} onClick={handleClose}>{"X"}</div>
                                </CardAnalyticsHeader>
                                <CardAnalyticsBody>
                                    <GridContainer>
                                        <GridItem xs={12} sm={12} md={12} space={0}>
                                            <TableContainer >
                                                <Table aria-label="simple table">
                                                    <TableBody>
                                                        <TableRow
                                                            key={"Registered employees"}
                                                        >
                                                            <TableCell component="th" scope="row" className={classes.completionRowTh} >
                                                                {""}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" className={classes.completionRowTh} >
                                                                {""}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" className={classes.completionRowTh} >
                                                                {""}
                                                            </TableCell>
                                                            <TableCell className={classes.completionModuleRow}>
                                                                <p style={{
                                                                    fontWeight: "700",
                                                                }}>Goal</p>

                                                            </TableCell>
                                                            <TableCell className={classes.completionModuleRow}>
                                                                <p style={{
                                                                    fontWeight: "700"
                                                                }}>Status</p>

                                                            </TableCell>

                                                        </TableRow>
                                                        <TableRow
                                                            key={"Registered employees"}
                                                        >

                                                            <TableCell component="th" scope="row" className={classes.completionRowTh} >
                                                                <img src={pencil} alt="pencil icon" />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" className={classes.completionRowTh} >
                                                                {"Registered employees"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow} >
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}
                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1230%"} />
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color="#009df0"
                                                                    textColor="white"
                                                                    content={"1220%"} />
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <ProgressButton color='secondary' />
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <p style={{marginBottom:0}}>01-11-2022</p>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={t("Active employees")}
                                                        >
                                                            <TableCell component="th" scope="row" className={classes.completionRowTh} >
                                                                <img src={pencil} alt="pencil icon" />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" className={classes.completionRowTh}>
                                                                {"Active employees"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1220%"} />
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1220%"} />
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <ProgressButton />
                                                            </TableCell>
                                                            <TableCell className={classes.completionModuleRow}>
                                                                <p style={{marginBottom:0}}>01-11-2022</p>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={t("Completed Employess(all modules)")}
                                                        >
                                                            <TableCell component="th" scope="row" className={classes.completionRowTh} >
                                                                <img src={pencil} alt="pencil icon" />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" className={classes.completionRowTh}>
                                                                {"Completed Employess(all modules):"}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1220%"} />
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1220%"} />
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <ProgressButton />
                                                            </TableCell>
                                                            <TableCell className={classes.completionModuleRow}>
                                                                <p style={{marginBottom:0}} >01-11-2022</p>
                                                            </TableCell>
                                                        </TableRow>
                                                        <TableRow
                                                            key={"Registered employees"}
                                                        >
                                                            <TableCell component="th" scope="row" className={classes.completionRowTh} >
                                                                <img src={pencil} alt="pencil icon" />
                                                            </TableCell>
                                                            <TableCell component="th" scope="row" className={classes.completionRowTh} >
                                                                {t("Ideas and comments")}
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow} >
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}
                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1230%"} />
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <FakeButton
                                                                    style={{
                                                                        width: "100%"
                                                                    }}

                                                                    color={ourBlueLight}
                                                                    textColor={ourBlue}
                                                                    content={"1220%"} />
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <ProgressButton color='secondary' />
                                                            </TableCell>
                                                            <TableCell className={classes.completionRow}>
                                                                <p style={{marginBottom:0}}>01-11-2022</p>
                                                            </TableCell>
                                                        </TableRow>

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <div>
                                                <FakeButton
                                                    color='#4A79B7'
                                                    textColor="white"
                                                    content={"+ add new goals"} /></div>

                                            <div><FakeButton
                                                style={{
                                                    marginLeft: '815px',
                                                    border: '1px solid #4A79B7',

                                                }}
                                                color='white'
                                                textColor='#4A79B7'
                                                content={"see completed goals"} /></div>  </GridItem>
                                        <GridItem xs={12} sm={12} md={12} space={0} style={props.isMobile ? { textAlign: "center" } : {
                                            textAlign: "right",
                                            marginRight: "15px"
                                        }}>

                                        </GridItem>
                                    </GridContainer>
                                </CardAnalyticsBody>
                            </CardAnalytics>
                        </GridItem>
                    </GridContainer>
                </Modal>
            </Grid>

    )

}
export default
    withMyHook(withStyles(
        (theme) => ({
            ...analyticsStyle(theme),
            ...useStyles(theme),
        }),
        { withTheme: true },
    )(Dashboard_manager));

